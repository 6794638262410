import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../../../../../helpers/Constants';
import SuccessAlert from '../../SuccessAlert';
import ErrorAlert from '../../ErrorAlert';

class designation  extends Component {
    constructor(props) {
        super(props)
        const headers = {
            headers: {
            'Content-Type': 'application/json',
            'Authorization':"Bearer "+this.props.auth_user.user_data.original.access_token
            }
            }
        const user_id= this.props.auth_user.user_data.original.user.id;
        console.log(user_id);
        this.state = {
       status:'1',
       headers:headers,
       designations:[],
       designation:'',
       user_id:this.props.auth_user.user_data.original.user.id,
       org_id :this.props.auth_user.user_data.original.user.org_id,
       

         }
        this.inputChange = this.inputChange.bind(this);
        this.handelFormSubmit = this.handelFormSubmit.bind(this);
    }
    inputChange(e) {
        console.log("input type working");
               this.setState({
          [e.target.name]: e.target.value,
          
        });
    }
    handelFormSubmit(event) {
        
         event.preventDefault();
         axios.post(BASE_URL + '/api/designation/addDesignation', {
          designation:this.state.designation,
          user_id:this.props.auth_user.user_data.original.user.id,
          message: this.state.message,
          status: this.state.status,  
          org_id:this.state.org_id,

            },this.state.headers).then(response => {
                console.log(response.data);
                   this.setState({
                    response_status: response.data.status,
                      response_message: response.data.message
                })
                if(response.data.status==true)
                {
                    window.location.reload();
                  this.props.history.push({pathname :'/designation', state :{msg:response.data.message,status:response.data.status}});
                  
                }
               }).catch(err => console.log(err));
          }

          componentDidMount() {
            const id = this.props.match.params.id
            console.log(id);
            axios.get(BASE_URL + `/api/designation/fetch/${id}`,this.state.headers)
            .then(response => {
                this.setState({
                    designations: response.data.data,
                    designation:response.data.data.designation
                    
                })
            }).catch(err => console.log(err));

            }
            
           

    render() {
        return (
          
            <div>
                     
            {this.state.response_status == false ?
                <ErrorAlert message= {this.state.response_message} />:null}
    
                  
    
                    <div class="card">
                        
                            <div class="card-content">
                                <div class="card-header">
                                    <h5 class="card-title" id="examplecardLabel">Edit Designation <a class="btn btn-primary pull-right" href="/designation">Back</a></h5>
                                   
                                </div>
                                <div class="card-body">
                                    <form  onSubmit={this.handelFormSubmit} >
                                    <input type="hidden" name="user_id" class="form-control" id="exampleInputEmail1"  value={this.state.user_id} />
                                    <input type="hidden" name="response_id" class="form-control" id="exampleInputEmail1"  value={this.state.response_id}  />
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Designation</label>
                                            <input type="text" name="designation" onChange={this.inputChange}
                                            value={this.state.designation} class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Designation" />
                                        </div>
    
                                        <div class="card-footer">
                                     {/*   {this.state.response_status == true ?
                                            <SuccessAlert message= {this.state.response_message} /> : null  }
                                            
                                        {this.state.response_status == false ?
                                        <ErrorAlert message= {this.state.response_message} />:null}*/}
                                           
                                            <button type="submit" class="btn btn-primary">Update</button>
                                        </div>
                                    </form>
                                </div>
    
                            </div>
                        
                    </div>
    
      

            </div>
        );
    }
}

export default withRouter (designation);