/* eslint-disable*/
import React, { Component } from 'react'
import SuccessAlert from './SuccessAlert';
import { withRouter, Link } from 'react-router-dom';
import axios from 'axios';
import { FaBeer, FaEye, FaPhoneSlash, FaBackward, FaIdBadge, FaChevronCircleRight, FaPhoneSquare, FaHistory, FaBook } from 'react-icons/fa';
import { BASE_URL } from '../../../helpers/Constants';
import '../admin/page.css';
import Pagination from "react-js-pagination";
import { is } from 'date-fns/locale';






class Tellecalling_Response extends Component {
    constructor(props) {
        super(props)
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + this.props.auth_user.user_data.original.access_token
            }
        }


        this.state = {
            details: [],
            calls_responses: [],
            calls_stage: [],
            calls: [],
            id: '',
            telecaller_lead_id: '',
            first_name: '',
            call_stage_id: '',
            lead_id: [],
            response_id: '',
            responselead_id: '',
            status: '1', comment: '',
            last_name: '',
            mobile: '',
            email: '',
            response_status: '', user_name: '', user_mobile: '', activity_type: '', activity_name: '',
            availiblity_date: '', availibility_time: '', date: '', time: '', skype: '', zoom: '',
            anydesk: '', google: '', org_id: this.props.auth_user.user_data.original.user.org_id,
            updated_by: this.props.auth_user.user_data.original.user.id,
            created_by: this.props.auth_user.user_data.original.user.id,
            user_id: this.props.auth_user.user_data.original.user.id,
            reminder_type: [], reminder_date_time: '', reminder_comment: '',
            headers: headers,
            activePage: 1,
            itemsCountPerPage: 1,
            totalItemsCount: 1,
            errors: {},
            response_idError: "",
            activity_nameError: "",
            call_stage_idError: "",
            activity_typeError: "",
        }
        this.inputChange = this.inputChange.bind(this);
        this.handelFormSubmit = this.handelFormSubmit.bind(this);
        this.handelSubmit = this.handelSubmit.bind(this);
        this.handelReminderModalSubmit = this.handelReminderModalSubmit.bind(this);
        this.handleChecked = this.handleChecked.bind(this);
        this.activityPage = this.activityPage.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.getleadid = this.getleadid.bind(this);


    }

    nextPage(e) {
        console.log(`active page is ${++this.state.activePage}`);
        const pageNumber = `${this.state.activePage}`
        console.log(pageNumber)
        this.setState({ activePage: pageNumber });

        axios.get(BASE_URL + '/api/leads/Fetch/TelecallerDataByOwnerId?page=' + pageNumber, this.state.headers)
            .then(response => {
                this.setState({
                    details: response.data.data,
                    itemsCountPerPage: response.data.per_page,
                    totalItemsCount: response.data.total,
                    activePage: response.data.current_page,

                })
            });

    }

    getleadid(id) {

        console.log(id);
     
      
    }

    inputChange(e) {
        console.log("input type working");

        const now = new Date();
        const dateFormate = now.toLocaleDateString();
        const timeFormate = now.toLocaleTimeString();
        const activity_nameError = "";
        // lead_id: e.target.value,
        this.setState({

            [e.target.name]: e.target.value,
            dateFormate: dateFormate,
            timeFormate: timeFormate,
            activity_nameError: "",
            response_idError: "",
            call_stage_idError: "",
            activity_typeError: "",
            telecaller_lead_id: "",

        });

    }

    handlePageChange() {
        console.log(`active page is ${++this.state.activePage}`);
        const pageNumber = `${this.state.activePage}`
        console.log(pageNumber)
        this.setState({ activePage: pageNumber });

        axios.get(BASE_URL + '/api/leads/Fetch/TelecallerDataByOwnerId?page=' + pageNumber, this.state.headers)
            .then(response => {
                this.setState({
                    details: response.data.data,
                    itemsCountPerPage: response.data.per_page,
                    totalItemsCount: response.data.total,
                    activePage: response.data.current_page,

                })
            });
    }
    activityPage(e) {
        const id = this.props.match.params.id
        event.preventDefault();

        axios.post(BASE_URL + '/api/leads/FetechLeadActivity', {

            lead_id: this.state.lead_id,
        }, this.state.headers).then(response => {
            this.setState({
            })
            this.props.history.push({ pathname: `/view-activity/${id}`, state: { msg: response.data.message, status: response.data.status } });

        }).catch(err => console.log(err));

    }
    validate = () => {
        let activity_nameError = "",
            response_idError = "",
            call_stage_idError = "",
            activity_typeError = "";

        // let passwordError = "";

        if (!this.state.activity_name) {
            activity_nameError = "activity name cannot be blank";
        }
        if (!this.state.call_stage_id) {
            call_stage_idError = "Call Stage cannot be blank";
        }
        if (!this.state.activity_type) {
            activity_typeError = " Activity type cannot be blank";
        }
        if (!this.state.response_id) {
            response_idError = "Call Response cannot be blank";
        }

        // if (!this.state.email.includes("@")) {
        //   emailError = "invalid email";
        // }

        if (activity_nameError) {
            this.setState({ activity_nameError });

        }
        if (call_stage_idError) {
            this.setState({ call_stage_idError });

        }
        if (activity_typeError) {
            this.setState({ activity_typeError });

        }
        if (response_idError) {
            this.setState({ response_idError });
            return false;
        }

        return true;


    }
    

    handelFormSubmit(event) {
        
        event.preventDefault();
        
        // let activity_nameError = "",
        //     response_idError = "",
        //     call_stage_idError = "",
        //     activity_typeError = "";

        // const isValid = this.validate();
        // if (isValid) {
        //     this.setState({ activity_nameError: "", response_idError: "", call_stage_id: "", activity_typeError: "" })
        // } else {
        //     return false;
        // }


        axios.post(BASE_URL + '/api/leads/addAllLeadActivity', {

            call_stage_id: this.state.call_stage_id,
            lead_id:this.state.lead_id,
            activity_type: this.state.activity_type,
            activity_name: this.state.activity_name,
            response_id: this.state.response_id,
            status: this.state.status,
            comment: this.state.comment,
            lead_name: this.state.lead_name,
             lead_id:this.state.lead_id,
            response_status: this.state.response_status,
            org_id: this.state.org_id,
            updated_by: this.state.updated_by,
            created_by: this.state.created_by

        }, this.state.headers).then(response => {
            console.log(response.data);

            this.setState({
                call_stage_id: '',
                activity_type: '',
                activity_name: '',
                response_id: '',

                comment: '',
                response_status: response.data.status,
                response_message: response.data.message
            })

            console.log(`active page is ${++this.state.activePage}`);
            const pageNumber = `${this.state.activePage}`
            console.log(pageNumber)
            this.setState({ activePage: pageNumber });

            axios.get(BASE_URL + '/api/leads/Fetch/TelecallerDataByOwnerId?page=' + pageNumber, this.state.headers)
                .then(response => {
                    this.setState({
                        details: response.data.data,
                        itemsCountPerPage: response.data.per_page,
                        totalItemsCount: response.data.total,
                        activePage: response.data.current_page,

                    })
                });
            if (response.data.status == true) {
                this.props.history.push({ state: { msg: response.data.message, status: response.data.status } });

            }

        }).catch(err => console.log(err));

    }


    handelReminderModalSubmit(event,lead_id) {
        console.log("lead_id" +lead_id);
        event.preventDefault();
        axios.post(BASE_URL + '/api/leads/addLeadsReminder', {
            date: this.state.dateFormate,
            time: this.state.timeFormate,
            reminder_type: this.state.reminder_type,
            reminder_date_time: this.state.dateFormate,
            reminder_before_time: this.state.reminder_before_time,
            reminder_comment: this.state.reminder_comment,
            lead_id: this.state.lead_id,
            user_id: this.state.user_id,
            org_id: this.state.org_id,
            created_by: this.state.created_by,
            updated_by: this.state.updated_by,

        }, this.state.headers).then(response => {
            console.log(response.data);
            this.setState({
                date: '',
                time: '',
                reminder_type: '',
                reminder_date_time: '',
                reminder_before_time: '',
                reminder_comment: '',
                response_status: response.data.status,
                response_message: response.data.message
            })



            // if(response.data.status==true)
            // {
            //     // window.location.reload( {state :{msg:response.data.message,status:response.data.status}});

            //     // this.props.history.push({pathname :'/telecalling', state :{msg:response.data.message,status:response.data.status}});
            // //   this.props.history.push({pathname :'/telecalling', state :{msg:response.data.message,status:response.data.status}});

            // }
        }).catch(err => console.log(err));



    }
    handelSubmit(event) {
        event.preventDefault();

        axios.post(BASE_URL + '/api/Busyresponse', {
            date: this.state.dateFormate,
            time: this.state.timeFormate,
            skype: this.state.skype,
            zoom: this.state.zoom,
            anydesk: this.state.anydesk,
            google: this.state.google,
            user_id: this.state.user_id,
            response_status: this.state.response_status,
            user_name: this.state.user_name,
            user_mobile: this.state.user_mobile,
            availiblity_date: this.state.availiblity_date,
            availibility_time: this.state.availibility_time,


        }, this.state.headers).then(response => {
            this.setState({
                response_status: '',
                alert_message: "success"
            })

        }).catch(err => console.log(err));

    }
    componentDidMount() {

        const id = this.props
        console.log(id);

        console.log("iddddddddddddd" + this.props.telecallinglead_id);
        console.log(this.props.auth_user.user_data.original.user.org_id);
        console.log('callled  view leads calls ');


        axios.get(BASE_URL + `/api/leads/Fetch/TelecallerDataByOwnerId`, this.state.headers)
            .then(response => {

                let errors = {};

                this.setState({
                    details: response.data.data,
                    lead_id: response.data.data.id,
                    itemsCountPerPage: response.data.per_page,
                    totalItemsCount: response.data.total,
                    activePage: response.data.current_page,
                    id: response.data.data.id,
                    first_name: response.data.data.first_name,
                    last_name: response.data.data.last_name,
                    email: response.data.data.email,
                    mobile: response.data.data.mobile,
                })

            }).catch(err => console.log(err));


        axios.get(BASE_URL + `/api/response/${id}`, this.state.headers).then(response => {
            this.setState({
                calls: response.data
            })
        }).catch(err => console.log(err));

        axios.get(BASE_URL + '/api/call_Stage/listCallStage', this.state.headers)
            .then(response => {
                this.setState({
                    calls_stage: response.data.data,
                })
            }).catch(err => console.log(err));

        axios.get(BASE_URL + '/api/call_response/list_call_response', this.state.headers)
            .then(response => {
                this.setState({
                    calls_responses: response.data.data,

                })
            }).catch(err => console.log(err));

    }

    handleChecked(event) {
        let list = this.state.reminder_type;
        const target = event.target;
        var value = target.value;

        if (target.checked) {
            this.setState({
                reminder_type: [...this.state.reminder_type, value]
            })
            // this.state.reminder_type[value] = value;   
        }
        else {
            this.setState({
                reminder_type: []
            })

            // this.state.reminder_type.splice(value, 1);
        }

    }



    render() {



        var now = new Date();
        return (


            <div>
                {this.state.errors.details &&
                    <div className="atert alert-danger"> {this.state.errors.details}</div>
                }

                {this.props.location.state != undefined ?
                    [(this.props.location.state.status == true
                        ? <SuccessAlert message={this.props.location.state.msg} />
                        : <p style={{ color: "white" }}> else </p>
                    ),
                    <p style={{ color: "white" }}>working  </p>
                    ]
                    :

                    null
                }
                <div class="row">
                    <div class="col-sm-12" id="breadcrum" style={{ marginTop: "-23px", marginBottom: "0px", borderBottom: "1px dashed #b9b9b9 " }}>
                        <h4 class="pull-left page-title" style={{ lineHeight: "48px" }}><i class=" mdi mdi-phone-incoming" aria-hidden="true"></i>&nbsp;&nbsp;Response : Put </h4>
                        <ol class="breadcrumb pull-right">

                            <Link to="/telecalling" style={{ color: "black", marginTop: "-5px", fontSize: "1.2em", paddingRight: "10px" }} className="pull-right"><FaBackward /> Back &nbsp;</Link>




                        </ol>
                    </div>
                </div>

                <div className="card border-primary" style={{ marginTop: "60px" }}>
               

                    <form onSubmit={this.handelFormSubmit}>
                        <div className="card-body">
                            <div className="row">


                                <table style={{ marginTop: "-1em", width: "100%" }}>

                                    <tbody>


                                        {this.state.details !== null
                                            ? this.state.details.map(detail => (
                                                <tr>

                                                    <td style={{ fontSize: "18px", fontWeight: "500", color: "#317eeb", width: "30%" }}>{detail.first_name} {" "} {detail.last_name}</td>

                                                    <td style={{ fontSize: "16px", width: "30%" }}> <i className=" mdi mdi-phone">&nbsp;&nbsp;</i>{detail.mobile}</td>
                                                    <td style={{ fontSize: "16px", width: "30%" }} >   <i className=" mdi mdi-gmail">&nbsp;&nbsp;</i>{" "} {detail.email} </td>

                                                    <td style={{ fontSize: "25px", width: "30%" }}><Link to={`/view-activity/${detail.id}`}> <i className=" mdi mdi-eye pull-right" title="View Activity"> &nbsp;&nbsp;</i></Link></td>
                                                    {/*  <td style={{fontSize:"16px"}} > <button type="button" className="btn btn-primary pull-right" data-toggle="modal" data-target="#callHistory"><i className=" mdi mdi-history"></i>&nbsp;History</button></td>*/}

                                                </tr>

                                            )) : null

                                        }

                                        {this.state.details == "" ?
                                            <h4 class="pull-right" style={{ lineHeight: "0px", margin: "0px 0px 0px" }}>Nos. of Leads :<span style={{ color: "red" }}> 0</span></h4>
                                            :
                                            null
                                        }



                                    </tbody>

                                </table>
                            </div>

                        </div>

                        <div class="row">
                            <div class="col-lg-12">
                            {this.state.details !== null
                                ? this.state.details.map(detail => (
                                    <input type="hide" name="lead_id" lead_id={detail.id} value={detail.id}/>
                                    )) : null
            
                                }

                                <input type="hidden" name="org_id" onChange={this.inputChange} value={this.state.org_id} />
                                <input type="hidden" name="updated_by" onChange={this.inputChange} value={this.state.updated_by} />
                                <input type="hidden" name="created_by" onChange={this.inputChange} value={this.state.created_by} />
                                <div class="row">
                                    <div class="col-sm-6 form-group required">
                                        <label className="control-label"> Call Stage <span style={{ color: "red" }}>*</span></label>
                                        <select class="form-control" name="call_stage_id" required="required" onChange={this.inputChange} value={this.state.call_stage_id}>
                                            <option value="">-- Select Call Satge --</option>
                                            {this.state.calls_stage !== null
                                                ? this.state.calls_stage.map(data => (

                                                    <option value={data.id}>{data.call_stage}</option>
                                                ))
                                                :
                                                null
                                            }

                                        </select>
                                        <div style={{ fontSize: 12, color: "red" }}>

                                            {this.state.call_stage_idError}
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group required">
                                        <label> Call Response <span style={{ color: "red" }}>*</span></label>
                                        <select class="form-control" name="response_id" required="required" onChange={this.inputChange} value={this.state.response_id}>
                                            <option> --Select Responses --</option>
                                            {this.state.calls_responses !== null
                                                ? this.state.calls_responses.map(call => (

                                                    <option value={call.id}>{call.response_name}</option>
                                                ))
                                                :
                                                null
                                            }
                                        </select>
                                        <div style={{ fontSize: 12, color: "red" }}>
                                            {this.state.response_idError}
                                        </div>
                                    </div>

                                    <div class="col-sm-6 form-group required" >
                                        <label style={{ margineTop: "10px" }}> Activity Type <span style={{ color: "red" }}>*</span></label>
                                        <select class="form-control" onChange={this.inputChange} required="required" name="activity_type" value={this.state.activity_type}>
                                            <option value="">-- Select Activity Type --</option>
                                            <option value="Emailed">Emailed</option>
                                            <option value="Notification">Notification</option>
                                            <option value="Through Call">Through Call</option>
                                            <option value="Whatsapp">Whatsapp</option>

                                        </select>
                                        <div style={{ fontSize: 12, color: "red" }}>
                                            {this.state.activity_typeError}
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group required">
                                        <label style={{ margineTop: "10px !important" }} >Activity Name <span style={{ color: "red" }}>*</span></label>
                                        <input type="text" name="activity_name" onChange={this.inputChange} required="required" placeholder="Activity Type" value={this.state.activity_name} className="form-control" />
                                        <div style={{ fontSize: 12, color: "red" }}>
                                            {this.state.activity_nameError}
                                        </div>
                                    </div>

                                    <div class="col-md-12 form-group required">
                                        <div class="form-group" style={{ marginTop: "1em" }}>
                                            <label for="">Comments <span style={{ color: "red" }}>*</span></label>
                                            <textarea name="comment" value={this.state.comment} onChange={this.inputChange} required="required" class="form-control" rows="5" id="example-textarea-input"></textarea>
                                        </div>
                                    </div>
                                    {this.state.details == "" &&
                                        <div class="col-md-10">
                                            {/*} <button type="button" class="btn btn-primary waves-effect waves-light" data-toggle="modal" data-target="#con-close-modal" disabled>Set Reminder</button>&nbsp;&nbsp;&nbsp;*/}
                                            <button type="submit" class="btn btn-primary waves-effect waves-light m-b-5" sstyle={{ margineTop: "6px" }} disabled>Submit</button>
                                        </div>
                                    }
                                    {this.state.details !== null
                                        ? this.state.details.map(detail => (
                                            <div class="col-md-8">

                                                {/* <button type="button" data-id={detail.id} class="btn btn-primary waves-effect waves-light" data-toggle="modal" data-target="#con-close-modal">Set Reminder</button>&nbsp;&nbsp;&nbsp;*/}
                                                <button type="submit" class="btn btn-primary waves-effect waves-light m-b-5" style={{ margineTop: "6px" }}>Submit</button>
                                            </div>
                                        )) : null}

                                    <div class="col-md-4">
                                        <div className="pagination justify-content-left">
                                            <Pagination
                                                activePage={this.state.activePage}
                                                itemsCountPerPage={this.state.itemsCountPerPage}
                                                totalItemsCount={this.state.totalItemsCount}
                                                pageRangeDisplayed={this.state.pageRangeDisplayed}
                                                onChange={this.handlePageChange}
                                                itemClass='page-item'
                                                linkClass='page-link'
                                            />
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </form>
                    {/*/  {this.state.details == "" ?
                    <div className="alert alert-danger pull-right" style={{backgroundColor:"rgb(210 72 81)",height:"75px",width:"35%",marginTop:"440px"}}><p style={{fontSize:"15px"}}><i className="mdi mdi-alert"></i> &nbsp;&nbsp;&nbsp; No data are available for response.......<a href="/" style={{color:"white"}}> &nbsp;&nbsp; Back to Dashboard</a></p></div>
                    :
                    null
                } */}



                    <div>
                        <div id="con-close-modal" class="modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style={{ display: "none" }}>
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h4 class="modal-title mt-0"> Reminder</h4>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <form onSubmit={this.handelReminderModalSubmit}>
                                        {this.state.details !== null
                                            ? this.state.details.map(detail => (
                                                <input type="text" name="lead_id" onChange={this.inputChange} value={detail.id} />
                                            )) :
                                            null
                                        }
                                        <input type="hidden" name="org_id" onChange={this.inputChange} value={this.state.org_id} />
                                        <input type="hidden" name="user_id" onChange={this.inputChange} value={this.state.user_id} />

                                        <div class="modal-body">
                                            <div class="row" style={{ marginTop: "-1.5em" }}>
                                                <div class="col-md-12">
                                                    <h5><i class="fa fa-clock-o" aria-hidden="true"></i>&nbsp;Set Schedule</h5>
                                                </div><hr />

                                                <div class="col-md-6">
                                                    <label>Set Date <span style={{ color: "red" }}>*</span></label>

                                                    <div class="input-group">
                                                        <input type="date" class="form-control" required placeholder="dd/mm/yyyy" id="datepicker-multiple" />
                                                        <div class="input-group-append">
                                                            {/* <span class="input-group-text"><i class="mdi mdi-calendar-today"></i></span>*/}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-6">
                                                    <label>Set Time <span style={{ color: "red" }}>*</span></label>
                                                    <div class="input-group m-b-15">

                                                        <input id="timepicker" required type="time" class="form-control" />
                                                        <div class="input-group-append">
                                                            {/*<span class="input-group-text"><i class="mdi mdi-alarm"></i></span>*/}
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="checkbox checkbox-primary">
                                                        <input style={{ marginLeft: "5px" }} required id="checkbox2" type="checkbox" checked="checked" />
                                                        <label for="checkbox2">
                                                            Reminder <span style={{ color: "red" }}>*</span>
                                                        </label>
                                                    </div>
                                                    <select name="reminder_before_time" required onChange={this.inputChange} value={this.state.reminder_before_time} class="form-control">
                                                        <option value=" ">--select--</option>
                                                        <option value=" 05 min"> 05 min</option>
                                                        <option value="10 min">10 min</option>
                                                        <option value="15 min">15 min</option>
                                                        <option value="20 min">20 min</option>
                                                        <option value="25 min">25 min</option>
                                                        <option value="30 min">30 min</option>
                                                    </select>
                                                    <small>Before the task</small>

                                                </div>

                                                <div class="col-md-12">
                                                    <label style={{ marginTop: "10px" }}>Reminder Type : <span style={{ color: "red" }}>*</span> </label>
                                                    <div class="checkbox checkbox-primary">
                                                        <input style={{ marginLeft: "5px" }} required onChange={this.handleChecked} value="Notification" name="reminder_type[]" type="checkbox" />
                                                        <label for="checkbox2" style={{ marginLeft: "10px" }}>
                                                            Notification
                                                       </label>
                                                        <input style={{ marginLeft: "10px" }} required onChange={this.handleChecked} value="Email" name="reminder_type[]" type="checkbox" />
                                                        <label for="checkbox2" style={{ marginLeft: "10px" }}>
                                                            Email
                                                        </label>
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group no-margin">
                                                        <label for="" required class="control-label">Reminder Comment</label>
                                                        <textarea class="form-control" id="" onChange={this.inputChange} name="reminder_comment" value={this.state.reminder_comment} placeholder=""></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary waves-effect" data-dismiss="modal">Close</button>
                                            <button type="submit" class="btn btn-info waves-effect waves-light" data-pageNumber={this.state.activePage} >Submit</button>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal" id="exampleModal">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header" style={{ backgroundColor: "#abc7e4" }}>
                                <h5 className="modal-title" id="exampleModalLabel">Fill Details</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={this.handelSubmit}>
                                    <input type="hidden" value={this.state.response_status} value="Set a Call - Demo"
                                        onChange={this.inputChange} data-toggle="modal" data-target="#exampleModal" name="response_status" style={{ marginRight: "10px", marginRight: "10px" }} />


                                    <div className="form-group">
                                        <label>Name <span style={{ color: "red" }}>*</span></label>
                                        <input defaultValue="Null" value={this.state.user_name}
                                            onChange={this.inputChange} type="text" className="form-control" placeholder="Enter Name" name="user_name" required />
                                    </div>

                                    <input type="hidden" name="lead_id" value={this.state.id} />

                                    <div className="form-group">
                                        <label>Contact No. <span style={{ color: "red" }}>*</span></label>
                                        <input type="text"
                                            value={this.state.user_mobile}
                                            onChange={this.inputChange} defaultValue="Null" className="form-control" placeholder="contact No." name="user_mobile" required />
                                    </div>

                                    <div className="form-group">
                                        <label> Skype ID.</label>
                                        <input type="text"
                                            value={this.state.skype}
                                            onChange={this.inputChange} defaultValue="Null" className="form-control" placeholder="Skype Id." name="skype" />
                                    </div>

                                    <div className="form-group">
                                        <label>Zoom ID</label>
                                        <input type="text"
                                            value={this.state.zoom}
                                            onChange={this.inputChange} defaultValue="Null" className="form-control" placeholder="Zoom Id" name="zoom" />
                                    </div>

                                    <div className="form-group">
                                        <label>Anydesk ID</label>
                                        <input type="text"
                                            value={this.state.anydesk}
                                            onChange={this.inputChange} defaultValue="Null" className="form-control" placeholder="Anydesk no." name="anydesk" />
                                    </div>

                                    <div className="form-group">
                                        <label>Google ID <span style={{ color: "red" }}>*</span></label>
                                        <input type="text"
                                            value={this.state.google}
                                            onChange={this.inputChange} className="form-control" placeholder="Google Id" name="google" required />
                                    </div>


                                    <div className="form-group">
                                        <label>Availibility Date<span style={{ color: "red" }}>*</span></label>
                                        <input type="date" value={this.state.availiblity_date}
                                            onChange={this.inputChange} className="form-control" name="availiblity_date" required />
                                    </div>
                                    <div className="form-group">
                                        <label>Availibility Time <span style={{ color: "red" }}>*</span></label>
                                        <input type="time" value={this.state.availibility_time} onChange={this.inputChange}
                                            className="form-control" name="availibility_time" required />
                                    </div>



                                    <div class="modal-footer" style={{ backgroundColor: "#abc7e4" }}>
                                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                        <button type="submit" class="btn btn-primary">Submit Details</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>


                </div>
                {/* call history modal  */}


                <div class="modal" id="callHistory">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header" style={{ backgroundColor: "#abc7e4" }}>
                                <h5 class="modal-title" id="exampleModalLabel">Call History</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <div class="modal-body">

                                <div>
                                    <strong>History</strong>

                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>Date</th>
                                                <th>Time</th>
                                                <th>Status</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.calls !== null
                                                ? this.state.calls.map(call => (

                                                    <tr key={call.id}>

                                                        <td>{call.date}</td>
                                                        <td>{call.time}</td>
                                                        <td>{call.response_status}</td>

                                                    </tr>
                                                ))
                                                :
                                                null
                                            }


                                        </tbody>
                                    </table>

                                </div>

                            </div>
                            <div class="modal-footer" style={{ backgroundColor: "#abc7e4" }}>
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>

                            </div>
                        </div>
                    </div>
                </div>


            </div>
        )
    }
}
export default withRouter(Tellecalling_Response);