import { FORGOT_PASSWORD_EMAIL, FORGOT_PASSWORD_EMAIL_SUCCESS, FORGOT_PASSWORD_EMAIL_API_ERROR } from './actionTypes';

export const forgotPasswordEmail = data => {
    return {
        type: FORGOT_PASSWORD_EMAIL,
        payload: data
    }
}

export const forgotPasswordEmailSuccess = () => {
    return {
        type: FORGOT_PASSWORD_EMAIL_SUCCESS,
        payload: null
    }
}

export const forgotPasswordEmailApiError = error => {
    return {
        type: FORGOT_PASSWORD_EMAIL_API_ERROR,
        payload: error
    }
}