/* eslint-disable*/
import {Router, browserHistory} from 'react-router';
import Login from '../components/guest/login';
import ForgotPassword from '../components/guest/forgot-password';
import AdminDashboard from '../components/auth/admin/dashboard';
import Customers from '../components/auth/admin/customers';
import Viewcustomer from '../components/auth/admin/View_customers';
import Telecalling from '../components/auth/admin/Tellecalling';
import TellicallingResponse from '../components/auth/admin/Tellicalling_Response';
import AddCustomer from  '../components/auth/admin/Add_customer';
import Editcustomer from '../components/auth/admin/customer_edit';
import EditLead from  '../components/auth/admin/leads_edit';
import Organization from  '../components/auth/admin/organisations';
import Add_Organization from  '../components/auth/admin/add_org';
import Edit_Organization from  '../components/auth/admin/edit_org';
import Teams from  '../components/auth/admin/team';
import Add_user from '../components/auth/admin/add_team_user';
import edit_user from '../components/auth/admin/edit_team_user';
import leads from '../components/auth/admin/leads';
import Addleads from '../components/auth/admin/add_leads';
import Viewlead from '../components/auth/admin/view_leads';
import AddLeadStatus from '../components/auth/admin/MASTER/lead_status_setting';
import EditLeadStatus from '../components/auth/admin/MASTER/edit_lead_status_setting';
import AddcallResponse from '../components/auth/admin/MASTER/CALL_RESPIONSE/add_call_response';
import EditcallResponse from '../components/auth/admin/MASTER/CALL_RESPIONSE/edit_call_response';
import AddcallStage from '../components/auth/admin/MASTER/CALLS_STAGES/add_call_stages';
import EditcallStage from '../components/auth/admin/MASTER/CALLS_STAGES/edit_call_satge';
import ViewActivity from '../components/auth/admin/view_activity';
import ReminderActivity from '../components/auth/admin/View_schedule_reminder';
import EditProfile from '../components/auth/admin/edit_profile';
import Designation from '../components/auth/admin/MASTER/DESIGNATION/add_designation';
import EditDesignation from '../components/auth/admin/MASTER/DESIGNATION/edit_designation';
import ViewOrganisation from '../components/auth/admin/view_organisation';
import TeamMemberList from '../components/auth/admin/Team_member';
import AddTeamMemeber from '../components/auth/admin/add_team_members';
import EditTeamMemeber from '../components/auth/admin/edit_team_members';


const authProtectedRoutes = [
    { path: "/dashboard", exact: true, component: AdminDashboard, permission:"Not_Required" },
    { path: "/customers", exact: true, component: Customers, permission:"Not_Required" },
    { path: "/customers/view/:id",exact: true, component: Viewcustomer , permission:"Not_Required" },
    { path: "/telecalling",exact: true, component: Telecalling , permission:"Not_Required" },
    { path: "/telecalling-response",exact: true, component: TellicallingResponse , permission:"Not_Required" },
    { path: "/add-customer",exact: true, component: AddCustomer , permission:"Not_Required" },
    { path: "/leads/:id/edit",exact: true, component: EditLead , permission:"Not_Required" },
    { path: "/Organization",exact: true, component: Organization , permission:"Not_Required" },
    { path: "/Add-Organisation",exact: true, component: Add_Organization , permission:"Not_Required" },
    { path: "/Organization/:id/edit",exact: true, component: Edit_Organization , permission:"Not_Required" },
    { path: "/team",exact: true, component: Teams , permission:"Not_Required" },
    { path: "/add-user",exact: true, component: Add_user , permission:"Not_Required"},
    { path: "/user/:id/edit",exact: true, component: edit_user , permission:"Not_Required"},
    { path: "/leads",exact: true, component: leads , permission:"Not_Required"},
    { path: "/add-leads",exact: true, component: Addleads , permission:"Not_Required"},
    { path: "/leads/view/:id",exact: true, component: Viewlead , permission:"Not_Required"},
    { path: "/customers/:id/edit",exact: true, component: Editcustomer , permission:"Not_Required"},
    { path: "/add-lead-status",exact: true, component: AddLeadStatus , permission:"Not_Required"},
    { path: "/status-edit/:id/edit",exact: true, component: EditLeadStatus , permission:"Not_Required"},
    { path: "/add-call-response",exact: true, component: AddcallResponse , permission:"Not_Required"},
    { path: "/edit-response/:id/edit",exact: true, component: EditcallResponse , permission:"Not_Required"},
    { path: "/call-stage",exact: true, component: AddcallStage , permission:"Not_Required"},
    { path: "/call-stage/:id/edit",exact: true, component: EditcallStage , permission:"Not_Required"},
    { path: "/view-activity/:id",exact: true, component: ViewActivity , permission:"Not_Required"},
    { path: "/view-schedule/:id",exact: true, component: ReminderActivity , permission:"Not_Required"},
    { path: "/edit-profile",exact: true, component: EditProfile , permission:"Not_Required"},
    { path: "/designation",exact: true, component: Designation, permission:"Not_Required"},
    { path: "/designation/:id/edit",exact: true, component: EditDesignation, permission:"Not_Required"},
    { path: "/Organization/:id/view",exact: true, component: ViewOrganisation, permission:"Not_Required"},
    { path: "/team-members",exact: true, component: TeamMemberList, permission:"Not_Required"},
    { path: "/add-team-members",exact: true, component: AddTeamMemeber, permission:"Not_Required"},
    { path: "/edit-team-members/:id",exact: true, component: EditTeamMemeber, permission:"Not_Required"},
  
    
    
];



const publicRoutes = [
    { path: "/", exact: true, component: Login },
    { path: "/forgot-password", exact: true, component: ForgotPassword }
];

export { authProtectedRoutes, publicRoutes };