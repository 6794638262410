/* eslint-disable*/
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { BASE_URL } from '../../../helpers/Constants';
import './page.css';
import Modal from 'react-bootstrap/Modal'
import add_org from './add_org';
import jwtDecode from 'jwt-decode';
import SuccessAlert from './SuccessAlert';
import { Link } from 'react-router-dom'
import { FaBeer, FaEye, FaChevronCircleRight } from 'react-icons/fa';
import Pagination from "react-js-pagination";

function serachingfor(term) {
  var search;

  return function (x) {
      return(x.name || '').toLowerCase().includes(term.toLowerCase()) || !term
      || (x.email || '').toLowerCase().includes(term.toLowerCase()) || !term
      
      || (x.designation_name || '').toLowerCase().includes(term.toLowerCase()) || !term;
     
   
  }
}

class team extends Component {
  constructor(props) {
    super(props)
    const headers = {
      headers: {
      'Content-Type': 'application/json',
      'Authorization':"Bearer "+this.props.auth_user.user_data.original.access_token
      }
      }
      this.msg = '';
      this.state = {
        users: [],
        advSearchIndex:'',
        designation:'',
       term: '',
       activePage: 1,
       itemsCountPerPage: 1,
      totalItemsCount: 1,
      pageRangeDisplayed: 3,
      headers:headers, 
    }
    this.serachHandeler = this.serachHandeler.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.delete = this.delete.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.modalToggle = this.modalToggle.bind(this);
    this.refresh = this.refresh.bind(this);
    this.handelSearchSubmit=this.handelSearchSubmit.bind(this);
   
  }
  handleClose() {  // modal close to reset input val
    this.setState({
        setShow: this.state.setShow
    });
}
modalToggle() {
    console.log('working modal');
    this.setState({
        showModal: !this.state.showModal
    });
   
}
refresh(){
  window.location.reload();
}
 
  delete(e){
  const id =e.currentTarget.getAttribute('data-id');
  axios.post(BASE_URL + '/api/users/fetch_team_head_delete', {
   id:id,
    },this.state.headers).then(response => {
    this.setState({
        response_status: response.data.status,
        response_message: response.data.message,
        

    })
    if(response.data.status==true)
    {
      window.location.reload();
      this.props.history.push({pathname :'/team', state :{msg:response.data.message,status:response.data.status}});
    }
      
        }).catch(err => console.log(err));

  }

  serachHandeler(event) {
    this.setState({ term: event.target.value })

  }

  handelSearchSubmit(event) {
    console.log("search working")
    event.preventDefault();
    axios.post(BASE_URL + "/api/users/advance_search_for_admin",
    {org_id:this.props.auth_user.user_data.original.user.org_id,
    advSearchIndex: this.state.advSearchIndex ,
    status:1,
    designation: this.state.designation},this.state.headers)
        .then(response => {
            console.log(response.data.data)
            this.setState({
              advSearchIndex: this.state.advSearchIndex ,
              designation: this.state.designation,
              users: response.data.data,
              itemsCountPerPage: response.data.per_page,
              totalItemsCount: response.data.total,
              activePage: response.data.current_page,

              
            })
            this.modalToggle();

        }).catch(err => console.log(err));

   
}

  handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`,this.state.headers);
    this.setState({ activePage: pageNumber });

    axios.post(BASE_URL + '/api/users/advance_search_for_admin?page=' + pageNumber,{org_id:this.props.auth_user.user_data.original.user.org_id},this.state.headers)
      .then(response => {
        this.setState({
          users: response.data.data,
          itemsCountPerPage: response.data.per_page,
          totalItemsCount: response.data.total,
          activePage: response.data.current_page
        })
      });
  }
  inputChange(e) {
    console.log("input type working");
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  



  componentDidMount() {
    console.log('callled orgs ');
    axios.post(BASE_URL+'/api/users/fetch_team_head',{org_id:this.props.auth_user.user_data.original.user.org_id},this.state.headers)
      .then(response => {
        this.setState({
          
          users: response.data.data,
          itemsCountPerPage:response.data.per_page,
          totalItemsCount:response.data.total,
          activePage:response.data.current_page,
          response_status: response.data.status,
                response_message: response.data.message
        })
      }).catch(err => console.log(err));

     }
  
//   componentWillMount() {
//     if (this.props.location.state && this.props.location.state.msg) {
//         this.msg = this.props.location.state.msg;
//         this.props.history.replace({
//             pathname: this.props.location.pathname,
//             state: {}
//         });
//     }
// }

  render(props) {
   
    const {term,users}= this.state;
    return (
      <div style={{ marginTop: "30px" }}>
      {this.props.location.state!=undefined ?
        [(this.props.location.state.status == true
         ? <SuccessAlert message= {this.props.location.state.msg} /> 
         : <p>  </p>
       ),
       <p></p>
    ]
     :
     //    <p> not working</p>
     null
     }
      <add_org/>
      <div class="row">
            <div class="col-sm-12" id="breadcrum" style={{marginTop:"-51px",marginBottom:"0px",borderBottom:"1px dashed #b9b9b9 "}}>
                <h4 class="pull-left page-title" style={{lineHeight:"48px"}}><i class="fa fa-users" aria-hidden="true"></i>&nbsp;&nbsp;Sales People</h4>
                <ol class="breadcrumb pull-right">
                    
                <li><Link to={"/team"} style={{ color: "#007bff", fontSize: "15px", scrollMarginBottom: "10px" }} className="pull-right active" >Sales People </Link></li>
                <li><Link to={"/team"} style={{ fontSize: "15px", scrollMarginBottom: "10px" }} className="pull-right">Home</Link></li>
      
                </ol>
            </div>
        </div>
        <div className="card">
         
         


          <div className="card-body">
          <form onSubmit={this.handelSearchSubmit}>
          <Row  style={{marginTop:"-1em"}}>
               <Col md={12} >
              <form>
              <input type="text" name="" className="form-control pull-left"
                  onChange={this.serachHandeler}
                  placeholder="Search....." value={term} style={{width:"30%"}} />
              </form>
              <Link to="/add-user" className="btn btn-primary pull-right" style={{marginLeft:"5px"}} >Add <i className="mdi mdi-plus-circle-outline"></i> </Link>
              <button type="button"  onClick={this.modalToggle} className="btn btn-primary pull-right" >Search</button>
              <button type="reset" className="btn btn-primary pull-right" onClick={this.refresh} style={{ marginLeft: "5px", marginRight: "5px" }}>Refresh</button>
            </Col>
            

          </Row>
        </form>
        <br/>
            <table class="table table-hover table-striped table-bordered">
              <thead>
                <tr>
                <th style={{ width: "74px",textAlign:"right" }}>Sl No.</th>
                <th>Image</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th style={{textAlign:"right" }}>Ph No.</th>
                  <th style={{textAlign:"right" }}>Create Date</th>
                  <th>Designation</th>
                  <th className="bg-white" style={{ backgroundColor: "white !important", width: "66px" }} ></th>

                  
                </tr>
              </thead>
              <tbody>
                {users !== null
               ? users.filter(serachingfor(term)).map((user,index) => (
                    <tr key={user.id}>
                    <td style={{ textAlign: "right", width: "54px" }}>{++index}.</td>
                    <td><img src={user.profile_img} class="picture-src" style={{ width: "50px",height:"30px" }}
                    id="e_logo" title="" /></td>
                     
                      <td>{user.name}</td>
                  {/* <td>{user.users_role}</td> */}
                      <td>{user.email}</td>
                      <td style={{textAlign:"right" }}>{user.phone}</td>
                      <td style={{textAlign:"right" }}>{user.date_created}</td>   
                      <td>{user.designation_name}</td>                 
                      <td>
                        <div className="row">
                          <div className="col-md-2">
                            <Link to={`/user/${user.id}/edit`} style={{ color: "#007bff", fontSize: "15px", scrollMarginBottom: "10px" }}><i className=" mdi mdi-pencil"></i></Link>
                          </div>
                          {/*<div className="col-md-2">
                            <Link to={`/team/${user.id}/view`} style={{ color: "#007bff", fontSize: "15px", scrollMarginBottom: "10px" }}><i className=" mdi mdi-eye"></i></Link>
                          </div>*/}
                          <div className="col-md-2">
                          <Link style={{ color: "red", fontSize: "15px", scrollMarginBottom: "10px" }}><i className="mdi mdi-delete"  data-id={user.id} onClick={this.delete}></i></Link>
                        </div>

                        </div>
                      </td>
                    </tr>

                  ))
                  :
                  null
                }


              </tbody>
            </table>

            <div className="pagination justify-content-left">
              <Pagination
                activePage={this.state.activePage}
                itemsCountPerPage={this.state.itemsCountPerPage}
                totalItemsCount={this.state.totalItemsCount}
                pageRangeDisplayed={this.state.pageRangeDisplayed}
                onChange={this.handlePageChange}
                itemClass='page-item'
                linkClass='page-link'
              />
            </div>
          </div>
        </div>
        <Modal {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered style={{opacity:"1"}} show={this.state.showModal} onHide={this.modalToggle}>
            <Modal.Header closeButton>
                <Modal.Title>Employee : Search</Modal.Title>
            </Modal.Header>
            <form onSubmit={this.handelSearchSubmit}>
            <Modal.Body >
    
            <div class="form-group">

            <div class="col-md-6 mb-3">
            <label for="field-2" class="control-label">Search:<font color="red">*</font></label>
            <input type="text" name="advSearchIndex" value={this.state.advSearchIndex}
                onChange={this.inputChange}  class="form-control eml" placeholder="Search...." maxlength="10" />
            
          </div>

        
            <div className="row">
            <div className="col-md-6 mb-3">
            <label for="status">Designation:</label>
            <select id="select" className="selectpicker form-control eml" onChange={this.inputChange}
            value={this.state.lead_status} name="designation" value={this.state.designation}>
            <option style={{ width: "100%" }}>--By Designation--</option>
            <option style={{ width: "100%" }}value={3}>Team Head</option>
            <option style={{ width: "100%" }}value={4}>Telecaller</option>
            <option style={{ width: "100%" }}value={5}>Data Miner</option>
            
           </select>
            </div>
          
            </div>
            
            </div>
           
           
 
            </Modal.Body>
            <Modal.Footer>
           
            <button type="submit" class="btn btn-primary pull-right">Submit </button>
            <button type="button"   onClick={this.modalToggle} className="btn btn-danger pull-right" style={{marginLeft:"8px"}} data-dismiss="modal">Close</button>
            </Modal.Footer>
            </form>
        </Modal>
     

      </div>

    )
  }
}
export default withRouter(team);

