import React from "react";//kischij ki access deni hai
import { Route, Redirect } from "react-router-dom";
import Sidebar from "../layouts/sidebar";
import Header from "../layouts/header";
import Footer from "../layouts/footer";

const AppRoute = ({ isAuthProtected, props: Props, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={ () => {
      if (isAuthProtected && Props.auth_user === null)
      {
        return (
          <Redirect to={{ pathname: "/" }} />
        );
      }
      else if(!isAuthProtected && Props.auth_user !== null)
      {
        return (
          // <Redirect to={{ pathname: Props.auth_user.role_name.toLowerCase()+"/dashboard" }} />
          <Redirect to={{ pathname: "/dashboard" }} />
        );
      }
      else
      {
        if(Props.auth_user !== null)
        {
          return (
            <main>
                <div id="wrapper">
                  <Sidebar permissions={Props.auth_user.assigned_permissions} auth_user={Props.auth_user} />
                  <div className="content-page">
                    <div className="content">
                      <Header token={Props.authToken} url={Props.apiUrl} />
                      <Component auth_user={Props.auth_user} permissions={Props.auth_user.assigned_permissions} token={Props.authToken} url={Props.apiUrl}  />
                    </div>
                    <Footer/>
                  </div>
                </div>
            </main>
          );
        }
        else
        {
          return (
            <Component />
          );
        }
      }
    }}
  />
);

export default AppRoute;
