/* eslint-disable*/
import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../../../helpers/Constants';
import SuccessAlert from './SuccessAlert';
import ErrorAlert from './ErrorAlert';

class edit_profile extends Component {
    constructor(props) {

        super(props)
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + this.props.auth_user.user_data.original.access_token
            }
        }
        const user_id = this.props;
        console.log(user_id);
        this.state = {
            users: [],
            countrys: [],
            citys: [], states: [],
            name: '',
            profile_img: '',
            designation: '',
            email: '',
            gender: '', phone: '', address: '', address2: '', city: '', state: '', pincode: '',
            username: '', password: '', country: '', headers: headers,
            file: '', imagePreviewUrl: '',

        }
        this.inputChange = this.inputChange.bind(this);
        this.handelFormSubmit = this.handelFormSubmit.bind(this);
        this.getnewstate = this.getnewstate.bind(this);
        this.getnewcity = this.getnewcity.bind(this);
        this.getcity_id = this.getcity_id.bind(this);
        this.getonloadstate = this.getonloadstate.bind(this);
        this.getonloadcity = this.getonloadcity.bind(this);
        this.imageChange = this.imageChange.bind(this);
    }

   imageChange(e) {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];
       

        reader.onloadend = () => {
            this.setState({
              
                file: file,
                imagePreviewUrl: reader.result,
               
            });
        }

        reader.readAsDataURL(file)
       }
    inputChange(e) {
        e.preventDefault();
  this.setState({
                [e.target.name]: e.target.value,
                
            });
        }

       

    getonloadstate(e) {
        console.log("hello" + this.state.country)
        const getcountry_id = this.state.country;
        axios.get(BASE_URL + `/api/list_state/${getcountry_id}`, this.state.headers)
            .then(response => {
                this.setState({
                    states: response.data,


                })
            }).catch(err => console.log(err));


    }
    getonloadcity(e) {
        console.log("hello city " + this.state.state_province)
        const getstate_id = this.state.state_province;
        axios.get(BASE_URL + `/api/list_cities/${getstate_id}`, this.state.headers)
            .then(response => {
                this.setState({
                    citys: response.data,

                })
            }).catch(err => console.log(err));

    }



    componentDidMount() {
        const id = this.props.auth_user.user_data.original.user.id;
        console.log(id);
        console.log('edit page working');

        axios.get(BASE_URL + `/api/sales_people/fetch/${id}`, this.state.headers).then(response => {
            console.log(response.data);
            this.setState({
                name: response.data.name,
                profile_img: response.data.profile_img,
                designation: response.data.designation,
                email: response.data.email,
                gender: response.data.gender,
                phone: response.data.phone,
                address: response.data.address,
                address2: response.data.address2,
                city: response.data.city,
                state: response.data.state,
                pincode: response.data.pincode,
                username: response.data.username,
                password: response.data.password,
                country: response.data.country,
            })
            this.getonloadstate();
            this.getonloadcity();
        }).catch(err => console.log(err));
        axios.get(BASE_URL + '/api/list_countries', this.state.headers)
            .then(response => {
                this.setState({
                    countrys: response.data,

                })
            }).catch(err => console.log(err));
    }

    getnewstate(e) {
        this.setState({
            country: e.target.value

        })
        const country_id = e.target.value;
        console.log(country_id);
        axios.get(BASE_URL + `/api/list_state/${country_id}`, this.state.headers)
            .then(response => {
                this.setState({
                    states: response.data,
                })
            }).catch(err => console.log(err));

    }
    getnewcity(e) {
        this.setState({
            state: e.target.value,
        })
        const state_id = e.target.value;
        console.log("state" + state_id);
        axios.get(BASE_URL + `/api/list_cities/${state_id}`, this.state.headers)
            .then(response => {
                this.setState({
                    citys: response.data,

                })
            }).catch(err => console.log(err));

    }
    getcity_id(e) {
        this.setState({

            city: e.target.value

        })

    }
    getonloadstate(e) {
        console.log("hello" + this.state.country)
        const getcountry_id = this.state.country;
        axios.get(BASE_URL + `/api/list_state/${getcountry_id}`, this.state.headers)
            .then(response => {
                this.setState({
                    states: response.data,


                })
            }).catch(err => console.log(err));


    }
    getonloadcity(e) {
        console.log("hello city " + this.state.state)
        const getstate_id = this.state.state;
        axios.get(BASE_URL + `/api/list_cities/${getstate_id}`, this.state.headers)
            .then(response => {
                this.setState({
                    citys: response.data,

                })
            }).catch(err => console.log(err));

    }

    handelFormSubmit(event) {
       const user_id =this.props.auth_user.user_data.original.user.id;

       
        event.preventDefault();
        console.log('handle uploading-', this.state.file);
        const users = this.props.auth_user.user_data.original.user.id
        var formData = new FormData();
        var imgFile = document.querySelector('#testFile');
        formData.append("profile_img", imgFile.files[0]);
        formData.append("name", this.state.name);
        formData.append("designation", this.state.designation);
        formData.append("gender", this.state.gender);
        formData.append("phone", this.state.phone);
        formData.append("address", this.state.address);
        formData.append("address2", this.state.address2);
        formData.append("city", this.state.city);
        formData.append("state", this.state.state);
        formData.append("pincode", this.state.pincode);
        formData.append("username", this.state.username);
        formData.append("password", this.state.password);
        formData.append("email", this.state.email);
        formData.append("country", this.state.country);
        axios.post(BASE_URL + `/api/users/updateUserDetails/${users}`,formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }

        }).then(response => {
       
            this.setState({

                response_status: response.data.status,
                response_message: response.data.message
            })
            window.location.reload();
            if(response.data.status==true)
               {
    
                 this.props.history.push({state :{msg:response.data.message,status:response.data.status}});
               }
             }).catch(err => console.log(err));

       

    }






    render() {
        let { imagePreviewUrl } = this.state;
        let $imagePreview = null;
        if (imagePreviewUrl) {
            $imagePreview = (<img style={{ width: "120px", height: "80px", marginLeft: "38px",border:"dashed 2px" }}  src={imagePreviewUrl} />);
        } else {
            $imagePreview = (<div class="form-group col-md-2 mb-3">
            <img src={this.state.profile_img} style={{ width: "80px", height: "80px", marginLeft: "38px" }} />
        </div>);
        }
        return (
            <div>
                {this.props.location.state != undefined ?
                    [(this.props.location.state.status == true
                        ? <SuccessAlert message={this.props.location.state.msg} />
                        : <p style={{ color: "#eceff1" }}>else  </p>
                    ),
                    <p style={{ color: "#eceff1" }}>working</p>
                    ]
                    :
                    //    <p> not working</p>
                    null
                }

                {this.state.response_status == false ?
                    <ErrorAlert message={this.state.response_message} /> : null}



                <div class="row">
                    <div class="col-sm-12" id="breadcrum" style={{ marginTop: "-23px", marginBottom: "0px", borderBottom: "1px dashed #b9b9b9 " }}>
                        <h4 class="pull-left page-title" style={{ lineHeight: "48px" }}><i class="fa fa-users" aria-hidden="true"></i>&nbsp;&nbsp;Profile : Edit</h4>
                        <ol class="breadcrumb pull-right">


                            <li><Link to={"/"} style={{ fontSize: "15px", scrollMarginBottom: "10px" }} className="pull-right">Home</Link></li>

                        </ol>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12">

                                        <div class="dsfdsf"></div>
                                        <form onSubmit={this.handelFormSubmit} enctype="multipart/form-data">

                                            <div class="form-row">
                                                <div class="form-group col-md-6 mb-3">
                                                    <label for="validationServer013">Name</label>
                                                    <input type="text" class="form-control eml" id="name"
                                                        value={this.state.name}
                                                        onChange={this.inputChange} name="name" required maxlength="30" placeholder="Name" />
                                                </div>
                                                <div class="form-group col-md-2 mb-3">
                                                        {$imagePreview}
                                                    </div>
                                             
                                                <div class="form-group col-md-4 mb-3">
                                                    <label for="validationServer013"> Update Profile image</label>

                                                    <input id="testFile" type="file" class="form-control" 
                                                        onChange={this.imageChange} accept="image/*" name="profile_img" placeholder="Name" />

                                                    <p id="profile_error_msg" style={{ color: "red" }}> </p>
                                                </div>

                                                <div class="form-group col-md-6 mb-3">
                                                    <label for="validationServer013">Designation</label>
                                                    <select class="select2 form-control " name="designation" value={this.state.designation}
                                                        onChange={this.inputChange} id="designation" required >
                                                        <option >Select Designation</option>

                                                        <option value="Manager">Manager</option>
                                                        <option value="RM">Reporting Manager</option>

                                                        <option value="RM">Reporting Manager</option>

                                                        <option value="Employee">Employee</option>
                                                    </select>
                                                </div>

                                                <div class="form-group col-md-6 mb-3">
                                                    <label for="validationServer013">Email</label>
                                                    <input type="text" class="form-control eml" value={this.state.email}
                                                        onChange={this.inputChange} placeholder="" name="email" id="email" required />
                                                </div>

                                                <div class="form-group col-md-6 mb-3">
                                                    <label for="validationServer013">Gender</label>
                                                    <div class="form-group" style={{ marginLeft: "10px" }}>
                                                        {this.state.gender == "male" ?
                                                            <div class="radio radio-info form-check-inline">
                                                                <input value={this.state.gender}
                                                                    onChange={this.inputChange} type="radio" id="male" value="male" name="gender" checked />
                                                                <label for="inlineRadio1">Male</label>
                                                            </div>
                                                            :
                                                            <div class="radio radio-info form-check-inline">
                                                                <input value={this.state.gender}
                                                                    onChange={this.inputChange} type="radio" id="male" value="male" name="gender" />
                                                                <label for="inlineRadio1">Male</label>
                                                            </div>
                                                        }
                                                        {this.state.gender == "female" ?
                                                            <div class="radio radio-info form-check-inline">
                                                                <input value={this.state.gender}
                                                                    onChange={this.inputChange} type="radio" id="female" value="female" name="gender" checked />
                                                                <label for="inlineRadio1">Female</label>
                                                            </div>
                                                            :
                                                            <div class="radio radio-info form-check-inline">
                                                                <input value={this.state.gender}
                                                                    onChange={this.inputChange} type="radio" id="female" value="female" name="gender" />
                                                                <label for="inlineRadio1">Female</label>
                                                            </div>
                                                        }

                                                    </div>
                                                </div>

                                                <div class="form-group col-md-6 mb-3">
                                                    <label for="validationServer013">Phone</label>
                                                    <input type="text" value={this.state.phone}
                                                        onChange={this.inputChange} class="form-control eml" name="phone" id="phone" placeholder="+91" maxlength="10" />
                                                </div>

                                                <div class="form-group col-md-6 mb-3">
                                                    <label for="validationServer013">Address Line 1</label>
                                                    <input type="text" value={this.state.address}
                                                        onChange={this.inputChange} class="form-control eml" name="address" id="address" required placeholder="" maxlength="100" />
                                                </div>

                                                <div class="form-group col-md-6 mb-3">
                                                    <label for="validationServer013">Address Line 2</label>
                                                    <input type="text" value={this.state.address2}
                                                        onChange={this.inputChange} class="form-control eml" id="address2" name="address2" placeholder="" maxlength="100" />
                                                </div>

                                                <div class="col-md-6 mb-3">
                                                    <div class="form-group">
                                                        <label for="field-2" class="control-label">Country <font color="red">*</font></label>
                                                        <select class="select2 form-control" onChange={this.getnewstate}
                                                            name="country" required="" aria-required="true" value={this.state.country}
                                                            data-placeholder="Choose a Country...">

                                                            {this.state.countrys !== null
                                                                ? this.state.countrys.map(country => (

                                                                    <option value={country.country_id} selected={country.country_id == this.state.country}>{country.country_name}</option>
                                                                ))
                                                                :
                                                                null
                                                            }

                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 mb-3">
                                                    <div class="form-group">
                                                        <label for="field-2" class="control-label">State <font color="red">*</font></label>
                                                        <select class="select2 form-control"
                                                            name="state" value={this.state.state} onLoad={this.getonloadstate} onChange={this.getnewcity}
                                                            data-placeholder="Choose a State...">
                                                            <option value=" " >--Select--</option>
                                                            {this.state.states !== null
                                                                ? this.state.states.map(state => (
                                                                    <option value={state.state_id} selected={state.state_id == this.state.state}>{state.state_name}</option>
                                                                ))
                                                                :
                                                                null
                                                            }


                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 mb-3">
                                                    <div class="form-group">
                                                        <label for="field-2" class="control-label">City <font color="red">*</font></label>
                                                        <select class="select2 form-control"
                                                            name="city" value={this.state.city} aria-required="true" onLoad={this.getonloadcity} onChange={this.getcity_id}
                                                            data-placeholder="Choose a City...">
                                                            <option>--Select--</option>
                                                            {this.state.citys !== null
                                                                ? this.state.citys.map(cit => (
                                                                    <option value={cit.city_id} selected={cit.city_id == this.state.city} >{cit.city_name}</option>
                                                                ))
                                                                :
                                                                null
                                                            }


                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 mb-3">
                                                    <label for="field-2" class="control-label">Pincode <font color="red">*</font></label>
                                                    <input type="text" name="pincode" value={this.state.pincode}
                                                        onChange={this.inputChange} id="pincode_check" class="form-control eml" placeholder="Pincode" maxlength="10" />
                                                    <p id="pincode_error_msg" style={{ color: "red" }}> </p>
                                                </div>



                                            </div>



                                            <div class="form-group col-md-12" style={{ textAlign: "right" }}>
                                                <button type="submit" id="btnsubmit" class="btn btn-success btn-lg">Update</button>
                                            </div>
                                            <br />

                                        </form>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default withRouter(edit_profile);