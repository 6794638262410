import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga'
import logger from 'redux-logger';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';
import SecureLocalStorage from '../helpers/SecureLocalStorage';

// Stores store data in LocalStorage
const saveToLocalStorage = state => {
    try 
    {
        const serializedState = JSON.stringify(state);
        SecureLocalStorage.setItem('state', serializedState);
    }
    catch(e) 
    {
        console.log(e);
    }
};

// Gets store data from LocalStorage
const loadFromLocalStorage = () => {
    try {
        const serializedState = SecureLocalStorage.getItem('state');

        if(serializedState === null) 
        {
            return undefined;
        }
        else 
        {
            return JSON.parse(serializedState);
        }
    }
    catch(e) 
    {
        console.log(e);
        return undefined;
    }
};

const persistedState = loadFromLocalStorage();


const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, persistedState, composeEnhancers(applyMiddleware(sagaMiddleware, logger)));
sagaMiddleware.run(rootSaga);

// Everytime the store changes, it will update the local storage
store.subscribe(() => saveToLocalStorage(store.getState()));

export default store;