/* eslint-disable*/
import React from 'react';
import { withRouter, Router, browserHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import $ from 'jquery';
import '../../src/components/auth/admin/page.css';
class sidebar extends React.PureComponent {
    state = {
        activeMenu: null,
    };

    setActiveTab = (activeMenu, event) => {
        this.setState({
            activeMenu: activeMenu
        });
    }

    componentDidMount() {
        var pageUrl = window.location.pathname.split(/[?#]/)[0];
        this.setActiveTab(pageUrl.split("/")[2]);

        $('.toggle-search').on('click', function () {
            var targetId = $(this).data('target');
            var $searchBar;
            if (targetId) {
                $searchBar = $(targetId);
                $searchBar.toggleClass('open');
            }
        });

        $('.button-menu-mobile').on('click', function (event) {
            event.preventDefault();
            $("body").toggleClass("enlarged");
        });

        $('li.has_sub li').on('click', function (event) {
            $("body").toggleClass("enlarged");
        });
    }

    componentDidUpdate() {
        console.log(this.props.auth_user.user_data.original.user.users_role);
        var pageUrl = window.location.pathname.split(/[?#]/)[0];
        let topbarHeader = pageUrl.split("/")[1].replace("-", " ");
        $('#now_routing').text(topbarHeader);
    }

    render() {
        const userrole = 'this.props.auth_user.user_data.original.user.users_role';
        return (

            <div className="left side-menu">
                <div className="text-center">
                    <Link to={"/dashboard"} className="logo"><img src="https://baba.software/ats/public/login/img/baba%20logo.png" height="100" alt="asterro" /></Link>
                    {/* <Link to="/dashboard" className="logo"><img src="/assets/images/logo-while.svg" height="40" alt="asterro" /></Link> */}
                </div>
                <div className="sidebar-inner slimscrollleft" >
                    <PerfectScrollbar>
                        <div id="sidebar-menu">



                            <ul>

                                {this.props.auth_user.user_data.original.user.users_role == 1 || this.props.auth_user.user_data.original.user.users_role == 5 || this.props.auth_user.user_data.original.user.users_role == 2 || this.props.auth_user.user_data.original.user.users_role == 3 || this.props.auth_user.user_data.original.user.users_role == 4 ?
                                    <li>
                                        <Link to={"/dashboard"} permissions={this.props.auth_user.user_data.original.user.users_role == 1 || this.props.auth_user.user_data.original.user.users_role == 2} className={this.state.activeMenu === 'dashboard' ? 'waves-effect active-menu' : 'waves-effect'} onClick={this.setActiveTab.bind(this, 'dashboard', '', '')}><i className="mdi mdi-view-dashboard"></i><span> Dashboard </span></Link>
                                    </li>
                                    : ""}

                                {this.props.auth_user.user_data.original.user.users_role == 1 || this.props.auth_user.user_data.original.user.users_role == 2 || this.props.auth_user.user_data.original.user.users_role == 3 ?
                                    <li>
                                        <Link to={"/leads"} permissions={this.props.auth_user.user_data.original.user.users_role == 1} className={this.state.activeMenu === 'leads' ? 'waves-effect active-menu' : 'waves-effect'} onClick={this.setActiveTab.bind(this, 'leads', '', '')}><i className="mdi  mdi-nature-people"></i><span>Leads</span></Link>
                                    </li>
                                    : ""}
                                {this.props.auth_user.user_data.original.user.users_role == 1 || this.props.auth_user.user_data.original.user.users_role == 2 || this.props.auth_user.user_data.original.user.users_role == 3 ||  this.props.auth_user.user_data.original.user.users_role == 5?
                                    <li>
                                        <Link to={"/customers"} permissions={this.props.auth_user.user_data.original.user.users_role == 1 && this.props.auth_user.user_data.original.user.users_role == 2} className={this.state.activeMenu === 'customers' ? 'waves-effect active-menu' : 'waves-effect'} onClick={this.setActiveTab.bind(this, 'customers', '', '')}><i className="mdi mdi-hand-pointing-right"></i><span> Contact </span></Link>
                                    </li>
                                    : ""}
                                  

                                    
                                {this.props.auth_user.user_data.original.user.users_role == 1 || this.props.auth_user.user_data.original.user.users_role == 2 || this.props.auth_user.user_data.original.user.users_role == 3 ?
                                   <li>
                                   <Link to={"/teams"} className={this.state.activeMenu === 'teams' ? 'waves-effect active-menu' : 'waves-effect'} data-toggle="collapse" data-target="#teams" onClick={this.setActiveTab.bind(this, 'teams', '', '')}><i className="mdi mdi-settings"></i><span> Teams/Groups </span><span class="pull-right"><i class="mdi mdi-plus"></i></span></Link>
                                   <div id="teams" class="collapse">
                                   <li>
                                   {this.props.auth_user.user_data.original.user.users_role == 1 || this.props.auth_user.user_data.original.user.users_role == 2?
                                  <Link to={"/team"} style={{ padding: "20px" }} className={this.state.activeMenu === 'team' ? 'waves-effect active-menu' : 'waves-effect'} onClick={this.setActiveTab.bind(this, 'team', '', '')}><i className="mdi mdi-account-multiple-plus"></i><span> Sales People</span></Link>
                               :null}
                                  <Link to={"/team-members"} style={{ padding: "20px" }} className={this.state.activeMenu === 'team-members' ? 'waves-effect active-menu' : 'waves-effect'} onClick={this.setActiveTab.bind(this, 'team-members', '', '')}><i className="mdi mdi-account-multiple-plus"></i><span>Team Members</span></Link>
                                 
                                    {/*<Link to={"/team-members"} permissions={this.props.auth_user.user_data.original.user.users_role == 1 || this.props.auth_user.user_data.original.user.users_role == 2} className={this.state.activeMenu === 'team' ? 'waves-effect active-menu' : 'waves-effect'} onClick={this.setActiveTab.bind(this, 'team', '', '')}><i className="mdi mdi-account-multiple-plus"></i><span>Team/Groups</span></Link>*/}
                                   </li>
                                  </div> 
                                   </li>
                                    
                                    : ""}
                                  
                                {this.props.auth_user.user_data.original.user.users_role != 1 && this.props.auth_user.user_data.original.user.users_role != 5 ?
                                    <li>
                                        <Link to={"/telecalling"} className={this.state.activeMenu === 'telecalling' ? 'waves-effect active-menu' : 'waves-effect'} onClick={this.setActiveTab.bind(this, 'telecalling', '', '')}><i className="mdi mdi-headphones-box"></i><span> Telecalling </span><span class="pull-right"></span></Link>

                                        <div id="demo" class="collapse">
                                            <li>
                                                <Link to={"/telecalling-response"} style={{ padding: "20px" }} className={this.state.activeMenu === 'telecalling-response' ? 'waves-effect active-menu' : 'waves-effect'} onClick={this.setActiveTab.bind(this, 'telecalling-response', '', '')}><i className="mdi mdi-phone"></i><span> Telecalling zz </span></Link>
                                            </li>
                                            {/*  <li>   
                                    <Link to={"/tellicalling"} style={{padding:"20px"}} className={this.state.activeMenu === 'tellicalling' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'tellicalling','','')}><i className="mdi mdi-phone-in-talk"></i><span> Set a Demo Call </span></Link>
                                </li> 
                                <li>
                                    <Link to={"/tellicalling"} style={{padding:"20px"}} className={this.state.activeMenu === 'tellicalling' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'tellicalling','','')}><i className="mdi mdi-folder-multiple"></i><span> Report </span></Link>
                                </li>   */}
                                        </div>
                                    </li>
                                    : ""}
                                {this.props.auth_user.user_data.original.user.users_role == 1 || this.props.auth_user.user_data.original.user.users_role == 2 ?
                                    <li>
                                        <Link to={"/setting"} className={this.state.activeMenu === 'setting' ? 'waves-effect active-menu' : 'waves-effect'} data-toggle="collapse" data-target="#setting" onClick={this.setActiveTab.bind(this, 'setting', '', '')}><i className="mdi mdi-settings"></i><span> Settings </span><span class="pull-right"><i class="mdi mdi-plus"></i></span></Link>

                                        <div id="setting" class="collapse">
                                            <li>
                                                <Link to={"/add-lead-status"} style={{ padding: "20px" }} className={this.state.activeMenu === 'add-lead-status' ? 'waves-effect active-menu' : 'waves-effect'} onClick={this.setActiveTab.bind(this, 'add-lead-status', '', '')}><i className="mdi mdi-phone" ></i><span> Lead Status </span></Link>
                                            </li>
                                            <li>
                                                <Link to={"/add-call-response"} style={{ padding: "20px" }} className={this.state.activeMenu === 'add-call-response' ? 'waves-effect active-menu' : 'waves-effect'} onClick={this.setActiveTab.bind(this, 'add-call-response', '', '')}><i className="mdi mdi-phone-settings" ></i><span> Call Response </span></Link>
                                            </li>
                                            <li>
                                                <Link to={"/call-stage"} style={{ padding: "20px" }} className={this.state.activeMenu === 'call-stage' ? 'waves-effect active-menu' : 'waves-effect'} onClick={this.setActiveTab.bind(this, 'call-stage', '', '')}><i className="mdi mdi-phone-hangup" ></i><span> Call Stage </span></Link>
                                            </li>
                                            <li>
                                                <Link to={"/designation"} style={{ padding: "20px" }} className={this.state.activeMenu === 'designation' ? 'waves-effect active-menu' : 'waves-effect'} onClick={this.setActiveTab.bind(this, 'designation', '', '')}><i className="mdi mdi-account-location" ></i><span> Designation </span></Link>
                                            </li>
                                            {this.props.auth_user.user_data.original.user.users_role == 1 ? <li>
                                                <Link to={"/Organization"} style={{ padding: "20px" }} className={this.state.activeMenu === 'Organization' ? 'waves-effect active-menu' : 'waves-effect'} onClick={this.setActiveTab.bind(this, 'Organization', '', '')}><i className="mdi mdi-bank"></i><span>Organization </span></Link>
                                            </li>
                                                : ""}
                                        </div>
                                    </li>
                                    : ""}
                            </ul>

                        </div>
                        <div className="clearfix"></div>
                    </PerfectScrollbar>
                </div>
            </div>
        );
    }
}

export default withRouter(sidebar);