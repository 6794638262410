/* eslint-disable*/
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { login } from '../../../store/rootAction';
import '../../auth/admin/login.css';

class Login extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            errors: {}
        };
    }
     

    componentDidMount() {
        document.title = "Login :: babasales";
    }

    handleUserInput = event => {
        this.setState({[event.target.name]: event.target.value});
    };
    
    handleLoginForm = event => {
        event.preventDefault();

        let errors = {};

        if(this.state.username === '')
            errors.username = "Username can't be empty";

        if(this.state.password === '')
            errors.password = "Password can't be empty";

        this.setState({errors});

        if(Object.keys(errors).length === 0)
            this.props.dispatchLogin({
                username: this.state.username,
                password: this.state.password,
                history: this.props.history
                
            });
           
    };

  
        


    render() {
        return (
            <React.Fragment>
            <div id="particles-js">
            <div id="particle"></div>
            <div id="overlay">
                <div className="container">
                    <div className="center-block">
                        <div className="col-lg-4 col-lg-offset-1 col-md-4 col-md-offset-1 col-sm-12 col-xs-12 no-padding" style={{zIndex:"1"}}>
                            <div className="mlt-content">
                                <div className="imgcontainer">
                                    <a href="http://baba.software/index.php"><img src="https://baba.software/ats/public/login/img/baba%20logo.png" alt="logo" /></a>
                                </div>

                                <h5 class="allign:center text-primary" style={{marginTop:"-16px"}}><u><strong>Login</strong></u></h5>

                                <div id="myTabContent" className="tab-content">

                                    <div className="col-md-12">


                                        <center>

                                        <form onSubmit={this.handleLoginForm}>
                                            
                                                <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label form-group has-feedback" >
                                              
                                                <input class="mdl-textfield__input" type="text" className={(this.state.errors.username) ? "form-control is-invalid" : "form-control"} id="username" name="username" placeholder="Enter Username" onChange={this.handleUserInput} />
                                                {this.state.errors.username &&
                                                    <div className="invalid-feedback">{this.state.errors.username}</div> 
                                                }
                                                
                                        
                                                
                                                </div>
                                     
                                           
                                                <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label form-group has-feedback">
                                             
                                                <input class="mdl-textfield__input" id="text" type="password" className={(this.state.errors.password) ? "form-control is-invalid" : "form-control"} id="password" name="password" placeholder="Enter password" autoComplete="on" onChange={this.handleUserInput} />
                                                {this.state.errors.password &&
                                                    <div className="invalid-feedback">{this.state.errors.password}</div> 
                                                }
                                                </div>
                                            

                                            <div className="col-lg-12 col-lg-offset-1 col-lg-offset-right-1 col-md-12 col-md-offset-1 col-md-offset-right-1 col-sm-12 col-xs-12 pull-right ">
                                                <button className="btn btn-primary" id="login" onClick={this.login} style={{ width: "30%", borderRadius: "18px" }}> Login <i className="icon-right "></i></button>
                                            </div><br />





                                            <div className="col-md-12 mt-2">
                                                <a href="http://baba.software/privacy.php" style={{ color: "rgb(12, 11, 11)" }}>Privacy Policy</a> | <a href="http://baba.software/terms-condition.php" style={{ color: "rgb(12, 11, 11)" }}>Terms of Use</a><br />
                                            </div>
                                            </form>

                                        </center>


                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-lg-offset-right-1 col-md-6 col-md-offset-right-1 col-sm-12 col-xs-12 no-padding">
                            <div className="mlt-carousel">
                                <div id="myCarousel" className="carousel slide carousel-fade" data-ride="carousel">
                                    <div className="carousel-inner" role="listbox">
                                        <div className="carousel-item active">
                                            <img className="img-responsive center-block" src="login2/img/payroll.png" alt="step1" />

                                        </div>
                                        <div className="carousel-item">
                                            <img className="img-responsive center-block" src="login2/img/crm.png" alt="step2" />

                                        </div>
                                        <div className="carousel-item">
                                            <img className="img-responsive center-block" src="login2/img/recruitment.png" alt="step3" />

                                        </div>
                                        <div className="carousel-item">
                                            <img className="img-responsive center-block" src="login2/img/lms.png" alt="step4" />

                                        </div>
                                        <div className="carousel-item">
                                            <img className="img-responsive center-block" src="login2/img/ar.png" alt="step5" />

                                        </div>
                                        <div className="carousel-item">
                                            <img className="img-responsive center-block" src="login2/img/sales.png" alt="step6" />

                                        </div>
                                    </div>

                                    <ol className="carousel-indicators carousel-btns">
                                        <li data-target="#myCarousel" data-slide-to="0" className="active"></li>
                                        <li data-target="#myCarousel" data-slide-to="1"></li>
                                        <li data-target="#myCarousel" data-slide-to="2"></li>
                                        <li data-target="#myCarousel" data-slide-to="3"></li>
                                        <li data-target="#myCarousel" data-slide-to="4"></li>
                                        <li data-target="#myCarousel" data-slide-to="5"></li>


                                    </ol>
                                </div>

                            </div>
                        </div>


                        <div className="col-md-12 text-center" style={{ color: "rgb(250, 248, 248)", paddingTop: "40px" }}>
                             Baba Sales © 2020 - 2021 BABA Software. All rights reserved.
                </div>
                    </div>



                </div>
            </div>
        </div>


        
            </React.Fragment>
        );
    }
};

const mapStateToProps = state => {
    return {
        getError: state.authReducer.error
    };
};

const mapDispatchToProps = dispatch => {
    return {
        dispatchLogin: data => dispatch(login(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));