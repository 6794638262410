/* eslint-disable*/
import React, { Component } from 'react'
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import SuccessAlert from './SuccessAlert';
import { withRouter,Link } from 'react-router-dom';
import { BASE_URL } from '../../../helpers/Constants';
import axios from 'axios';





class Add_customer extends Component {
    constructor(props) {

        super(props)
        const user_id = this.props;
        console.log(user_id);
        this.state = {
            created_by: '',
            org_id: '',
            countrys: [],
            citys: [], states: [],
            sales_people: [],
            region: '',
            mobile: '',
            lead_status: '',
            lead_owner:'', title: '', first_name: '', last_name: '',owner_id:'',
            address1: '', company_name: '', website: '', head_quater: '',
            apperance_in_country: '', email: '', fax: '', city_distt: '',
            state_province: '', pincode: '', country: '', n_o_employee: ''
            , lead_source: '', description: '', annual_revenue: '', rating: '', phone: '', industry: '', status: '2', org_id: '', alert_message: '',
        }
        this.inputChange = this.inputChange.bind(this);
        this.handelFormSubmit = this.handelFormSubmit.bind(this);
        this.getnewstate = this.getnewstate.bind(this);
        this.getnewcity = this.getnewcity.bind(this);
        this.getcity_id = this.getcity_id.bind(this);
    }

    inputChange(e) {
        console.log("input type working");
        this.setState({
            [e.target.name]: e.target.value,

        });

    }
    handelFormSubmit(event) {
        const headers = {
            headers: {
            'Content-Type': 'application/json',
            'Authorization':"Bearer "+this.props.auth_user.user_data.original.access_token
            }
            }
        console.log(this.props)
        console.log("data" + this.props.auth_user.user_data.original.user.org_id)
        event.preventDefault();
        axios.post(BASE_URL + '/api/leads', {
            created_by: this.props.auth_user.id,
            lead_status: this.state.lead_status,
            lead_owner: this.state.lead_owner,
            owner_id: this.state.owner_id,

            title: this.state.title, first_name: this.state.first_name,
            last_name: this.state.last_name,
            address1: this.state.address1,
            company_name: this.state.company_name,
            website: this.state.website,
            fax: this.state.fax,
            city_distt: this.state.city_distt,
            state_province: this.state.state_province,
            // is_customer:this.state.is_customer,
            pincode: this.state.pincode,
            country: this.state.country, n_o_employee: this.state.n_o_employee,
            lead_source: this.state.lead_source, description: this.state.description,
            annual_revenue: this.state.annual_revenue, rating: this.state.rating,
            email: this.state.email,
            mobile: this.state.mobile,
            phone: this.state.phone,
            industry: this.state.industry,
            head_quater: this.state.head_quater,
            apperance_in_country: this.state.apperance_in_country,
            status: this.state.status,
            org_id: this.props.auth_user.user_data.original.user.org_id,
            message: this.state.message,
            status: this.state.status,
            user_id:this.props.auth_user.user_data.original.user.id,
            team_head_id:this.props.auth_user.user_data.original.user.team_head_id,
            users_role :this.props.auth_user.user_data.original.user.team_head_id,


        },headers).then(response => {
            this.setState({
                response_status: response.data.status,
                response_message: response.data.message,
                

            })
            if(response.data.status==true)
            {
              
              this.props.history.push({pathname :'/leads', state :{msg:response.data.message,status:response.data.status}});
            }
              
                }).catch(err => console.log(err));


    }


    componentDidMount() {
        var token = this.props.token;
        const headers = {
            headers: {
            'Content-Type': 'application/json',
            'Authorization':"Bearer "+this.props.auth_user.user_data.original.access_token
            }
            }
        console.log("data" + this.props.auth_user.user_data.original.user.org_id)
        axios.post(BASE_URL + '/api/users/fetch_team_member_by_team_head',
        {org_id:this.props.auth_user.user_data.original.user.org_id,
            team_head_id:this.props.auth_user.user_data.original.user.id,
            name:this.props.auth_user.user_data.original.user.username},headers)
            .then(response => {
                this.setState({
                    sales_people: response.data
                })
            }).catch(err => console.log(err));

        axios.get(BASE_URL + '/api/list_countries',headers)
            .then(response => {
                this.setState({
                    countrys: response.data,

                })
            }).catch(err => console.log(err));

        axios.get(BASE_URL + '/api/orgByuser',headers
            )
            .then(response => {
                console.log(response.data);
            }).catch(err => console.log(err));
    }

    selectCountry(val) {
        this.setState({ country: val });
    }

    getnewstate(e) {
        const headers = {
            headers: {
            'Content-Type': 'application/json',
            'Authorization':"Bearer "+this.props.auth_user.user_data.original.access_token
            }
            }
        this.setState({
            country: e.target.value

        })
        const country = e.target.value;
        console.log(country);
        axios.get(BASE_URL + `/api/list_state/${country}`,headers)
            .then(response => {
                this.setState({
                    states: response.data,


                })
            }).catch(err => console.log(err));

    }
    getnewcity(e) {
        const headers = {
            headers: {
            'Content-Type': 'application/json',
            'Authorization':"Bearer "+this.props.auth_user.user_data.original.access_token
            }
            }
        this.setState({
            state_province: e.target.value,

        })
        const state_province = e.target.value;
        console.log("state" + state_province);
        axios.get(BASE_URL + `/api/list_cities/${state_province}`,headers)
            .then(response => {
                this.setState({
                    citys: response.data,

                })
            }).catch(err => console.log(err));

    }
    getcity_id(e) {
        this.setState({

            city_distt: e.target.value

        })
    }





    render() {

        const { country, region } = this.state;
        return (
            <div>
                
                {this.state.response_status == false ?
                    <ErrorAlert message={this.state.response_message} /> : null}
                    <div class="row">
                    <div class="col-sm-12" id="breadcrum" style={{marginTop:"-20px",marginBottom:"0px",borderBottom:"1px dashed #b9b9b9 "}}>
                        <h4 class="pull-left page-title" style={{lineHeight:"48px"}}><i class="fa fa-users" aria-hidden="true"></i>&nbsp;&nbsp;Add Leads</h4>
                        <ol class="breadcrumb pull-right">
                            
                        <li><Link to={`/leads`} style={{ color: "#007bff", fontSize: "15px", scrollMarginBottom: "10px" }} className="pull-right active" >Lead</Link></li>
                        <li><Link to={"/add-leads"} style={{ fontSize: "15px", scrollMarginBottom: "10px",color: "#007bff" }} className="pull-right">Create Leads</Link></li>
                        <li><Link to={"/leads"} style={{ fontSize: "15px", scrollMarginBottom: "10px" }} className="pull-right">Home</Link></li>
              
                        </ol>
                    </div>
                </div>

                
                <Card>
                
                    <Card.Body style={{ marginTop: "-2em" }}>

                        <Form onSubmit={this.handelFormSubmit}>


                            <input type="hidden"
                                className="form-control" id="email"
                                name="status"

                                value={this.state.status}
                                onChange={this.inputChange} />

                            <div className="form-group">

                                <input type="hidden"
                                    className="form-control" id="email"
                                    name="org_id"
                                    value={this.props.auth_user.user_data.original.user.org_id}

                                    onChange={this.inputChange} />
                            </div>


                            <div className="form-group">

                                <input type="hidden"
                                    className="form-control" id="email"
                                    name="cust_id"
                                    value={this.props.auth_user.id}
                                    onChange={this.inputChange} />
                            </div>

                            <label style={{ marginTop: "5px" }}>Lead Status<span style={{color:"red"}}>*</span></label><br />
                            <label className="radio-inline" style={{ marginRight: "10px" }}>
                                <input type="radio" required
                                    name="lead_status" style={{ marginRight: "10px" }}
                                    value={this.state.lead_status}
                                    onChange={this.inputChange}
                                    label="Unqualified"
                                    value="Unqualified" />Unqualified
                               </label>
                            <label className="radio-inline" style={{ marginRight: "10px" }}>
                                <input type="radio"
                                    name="lead_status" style={{ marginRight: "10px" }}
                                    required
                                    value={this.state.lead_status}
                                    onChange={this.inputChange}
                                    label="New"
                                    value="new" required/>New
                                     </label>
                            <label className="radio-inline" style={{ marginRight: "10px" }}>
                                <input type="radio"
                                    name="lead_status" style={{ marginRight: "10px" }}
                                    required
                                    value={this.state.lead_status}
                                    onChange={this.inputChange}
                                    label="Working"
                                    value="Working" required/>Working
                                         </label>
                            <label className="radio-inline" style={{ marginRight: "10px" }}>
                                <input type="radio"
                                    name="lead_status" style={{ marginRight: "10px" }}
                                    type="radio"
                                    required
                                    value={this.state.lead_status}
                                    onChange={this.inputChange}
                                    label="Nurturing"
                                    value={this.state.nurturing} required/>Nurturing
                                       </label>
                            <label className="radio-inline" style={{ marginRight: "10px" }}>
                                <input type="radio"
                                    name="lead_status"
                                    style={{ marginRight: "10px" }}
                                    required
                                    value={this.state.lead_status}
                                    onChange={this.inputChange}
                                    label="Qualified"
                                    value="qualified" required/>Qualified
                           </label>
{this.props.auth_user.user_data.original.user.designation == 4 || this.props.auth_user.user_data.original.user.designation == 5 &&
                           <input type="hidden"
                                    name="owner_id"
                                    style={{ marginRight: "10px" }}
                                    required
                                    value={this.props.auth_user.user_data.original.user.team_head_id}
                                    onLoad={this.inputChange}
                                  required/>
}
{this.props.auth_user.user_data.original.user.designation == 1 || this.props.auth_user.user_data.original.user.designation == 2 || this.props.auth_user.user_data.original.user.designation == 3 &&
                                <input type="hidden"
                                    name="owner_id"
                                    style={{ marginRight: "10px" }}
                                    required
                                    value={this.props.auth_user.user_data.original.user.id}
                                    onLoad={this.inputChange}
                                    required/>
}
                           
                            <Form.Row>
                                <Form.Group as={Col} md="6" controlId="validationFormik03">
                                    <Form.Label>First Name <span style={{color:"red"}}>*</span></Form.Label>
                                    <Form.Control
                                    
                                        onChange={this.inputChange}
                                        value={this.state.first_name}
                                        type="text"
                                        placeholder="First Name"
                                        name="first_name"
                                         required
                                />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationFormik03">
                                    <Form.Label>Last Name <span style={{color:"red"}}>*</span></Form.Label>
                                    <Form.Control
                                    
                                        onChange={this.inputChange}
                                        type="text"
                                        placeholder="Last Name"
                                        name="last_name"
                                        value={this.state.last_name}
                                       
                                    // value={values.city}
                                    // onChange={handleChange}
                                    // isInvalid={!!errors.city}
                                />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group

                                    as={Col} md="12" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control
                                    
                                        onChange={this.inputChange}
                                        value={this.state.address1}
                                        as="textarea" rows="3"
                                        name="address1"/>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group

                                    as={Col} md="3" controlId="validationFormik03">
                                    <Form.Label>Company</Form.Label>
                                    <Form.Control
                                    
                                        onChange={this.inputChange}
                                        type="text"
                                        placeholder="Company Name"
                                        value={this.state.company_name}
                                        name="company_name"
                                    // value={values.city}
                                    // onChange={handleChange}
                                    // isInvalid={!!errors.city}
                                />
                                </Form.Group>
                                <Form.Group

                                    as={Col} md="3" controlId="validationFormik03">
                                    <Form.Label>Website</Form.Label>
                                    <Form.Control
                                        type="text"
                                    
                                        onChange={this.inputChange}
                                        placeholder="www.google.com"
                                        value={this.state.website}
                                        name="website"
                                    // value={values.city}
                                    // onChange={handleChange}
                                    // isInvalid={!!errors.city}
                                />
                                </Form.Group>
                                <Form.Group

                                    as={Col} md="3" controlId="validationFormik03">
                                    <Form.Label>Headquarter</Form.Label>
                                    <Form.Control
                                        type="text"
                                    
                                        onChange={this.inputChange}
                                        value={this.state.head_quater}
                                        placeholder="Headquarter"
                                        name="head_quater"
                                    // value={values.city}
                                    // onChange={handleChange}
                                    // isInvalid={!!errors.city}
                                />
                                </Form.Group>
                                <Form.Group

                                    as={Col} md="3" controlId="validationFormik03">
                                    <Form.Label>Appearance in country</Form.Label>
                                    <Form.Control
                                        type="text"
                                    
                                        onChange={this.inputChange}
                                        placeholder="Appearance in country"
                                        name="apperance_in_country"
                                        value={this.state.apperance_in_country}
                                    // value={values.city}
                                    // onChange={handleChange}
                                    // isInvalid={!!errors.city}
                                />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group

                                    as={Col} md="3" controlId="validationFormik03">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" title="Please enter valid Email id."
                                        onChange={this.inputChange}
                                        value={this.state.email}
                                        type="email"
                                        placeholder="abc@gmail.com"
                                        name="email"
                                    // value={values.city}
                                    // onChange={handleChange}
                                    // isInvalid={!!errors.city}
                                />
                                </Form.Group>

                                <Form.Group

                                    as={Col} md="3" controlId="validationFormik03">
                                    <Form.Label>Fax</Form.Label>
                                    <Form.Control
                                    
                                        onChange={this.inputChange}
                                        value={this.state.fax}
                                        type="text"
                                        placeholder="Fax No."
                                        name="fax"
                                    // value={values.city}
                                    // onChange={handleChange}
                                    // isInvalid={!!errors.city}
                                  />
                                </Form.Group>

                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="field-2" class="control-label">Country <font color="red">*</font></label>
                                        <select class="select2 form-control" onChange={this.getnewstate}
                                            name="country"  required
                                            data-placeholder="Choose a Country...">
                                            <option value="">--Select--</option>
                                            {this.state.countrys !== null
                                                ? this.state.countrys.map(country => (

                                                    <option key={country.country_id} value={country.country_id}>{country.country_name}</option>
                                                ))
                                                :
                                                null
                                            }

                                        </select>
                                    </div>
                                </div>

                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="field-2" class="control-label">State <font color="red">*</font></label>
                                        <select class="select2 form-control" 
                                            name="state_province" value={this.props.state_province} onChange={this.getnewcity}
                                            data-placeholder="Choose a State..."  required>
                                            <option value=" " >--Select--</option>
                                            {this.state.states !== null
                                                ? this.state.states.map(state => (
                                                    <option value={state.state_id}>{state.state_name}</option>
                                                ))
                                                :
                                                null
                                            }


                                        </select>
                                    </div>
                                </div>

                            </Form.Row>
                            <Form.Row>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="field-2" class="control-label">City <font color="red">*</font></label>
                                        <select class="select2 form-control" 
                                            name="city_distt" id="city_distt"  onChange={this.getcity_id}
                                            data-placeholder="Choose a City..." required >
                                            <option>--Select--</option>
                                            {this.state.citys !== null
                                                ? this.state.citys.map(cit => (
                                                    <option value={cit.city_id}>{cit.city_name}</option>
                                                ))
                                                :
                                                null
                                            }


                                        </select>
                                    </div>
                                </div>


                                <Form.Group

                                    as={Col} md="3" controlId="validationFormik03">
                                    <Form.Label>Phone Number <font color="red">*</font></Form.Label>
                                    <Form.Control
                                    maxlength="10" pattern="\d*" title="Please enter 10 digit number only"
                                         onChange={this.inputChange}
                                        value={this.state.phone}
                                        type="text"
                                        placeholder="
                                        +91"
                                        name="phone"
                                        required
                                    // value={values.city}
                                    // onChange={handleChange}
                                    // isInvalid={!!errors.city}
                                    />
                                                            </Form.Group>

                                <Form.Group
                                    as={Col} md="3" controlId="validationFormik03">
                                    <Form.Label>Pin Code</Form.Label>
                                    <Form.Control
                                          value={this.state.pincode}
                                        onChange={this.inputChange}
                                        type="text"
                                        maxlength="6" pattern="[0-9]+\d*" 
                                        title="Please  enter 6 digit  number only"
                                        placeholder="Pincode."
                                        name="pincode"
                                    // value={values.city}
                                    // onChange={handleChange}
                                    // isInvalid={!!errors.city}
                                  />

                                </Form.Group>

                                <Form.Group

                                    as={Col} md="3" controlId="validationFormik03">
                                    <Form.Label>Mobile Number</Form.Label>
                                    <Form.Control
                                    maxlength="15" pattern="[0-9]+\d*" title="Please enter number only"
                                        onChange={this.inputChange}
                                        value={this.state.mobile}
                                        type="text"
                                        placeholder="Mobile Number"
                                        name="mobile"
                                        
                                    // value={values.city}
                                    // onChange={handleChange}
                                    // isInvalid={!!errors.city}
                                />
                                </Form.Group>


                            </Form.Row>

                            <Form.Row>
                                <Form.Group

                                    as={Col} md="3" controlId="validationFormik03">
                                    <Form.Label>No. of Employee</Form.Label>
                                    <Form.Control
                                        
                                        onChange={this.inputChange}
                                        value={this.state.n_o_employee}
                                        type="number"
                                        placeholder="NOP"
                                        name="n_o_employee"
                                    // value={values.city}
                                    // onChange={handleChange}
                                    // isInvalid={!!errors.city}
                                    />
                                </Form.Group>

                                <Form.Group

                                    as={Col} md="3" controlId="validationFormik03">
                                    <Form.Label>Lead Source</Form.Label>
                                    <Form.Control 
                                        onChange={this.inputChange}
                                        value={this.state.lead_source} name="lead_source" as="select" custom>
                                        <option value="">Choose</option>
                                        <option value="LinkedIn Id">LinkedIn Id</option>
                                        <option value="Google adword">Google adword</option>
                                        <option value="Partner">Partner</option>
                                        <option value="Employee">Employee</option>
                                        <option value="other">other</option>
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group

                                    as={Col} md="6" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control 
                                        onChange={this.inputChange}
                                        value={this.state.description} name="description" as="textarea" rows="3" placeholder="Description" />
                                </Form.Group>

                            </Form.Row>
                            <div className="row">
                                <Form.Group

                                    as={Col} md="3" controlId="validationFormik03">
                                    <Form.Label>Industry / Domain</Form.Label>
                                    <Form.Control 
                                        onChange={this.inputChange}
                                        value={this.state.industry} name="industry" as="select" custom>
                                        <option value="">Choose</option>
                                        <option value="LinkedIn Id">Education</option>
                                        <option value="Google adword">Retail</option>
                                        <option value="Partner">Health care</option>
                                        <option value="Employee">Banking</option>
                                        <option value="other">Steel</option>
                                        <option value="other">Finance</option>
                                        <option value="other">Telecommunication</option>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group

                                    as={Col} md="3" controlId="validationFormik03">
                                    <Form.Label>Annual Revenue</Form.Label>
                                    <Form.Control
                                        
                                        onChange={this.inputChange}
                                        value={this.state.annual_revenue}
                                        type="text"
                                        
                                        name="annual_revenue"
                                    // value={values.city}
                                    // onChange={handleChange}
                                    // isInvalid={!!errors.city}
                                    />
                                </Form.Group>
                                <div className="col-md-6">


                                    <label>Rating<span style={{color:"red"}}>*</span></label><br />
                                    <label className="radio-inline" style={{ marginRight: "10px" }}>
                                        <input type="radio"
                                            style={{ marginRight: "10px" }}
                                            
                                            onChange={this.inputChange}
                                            value={this.state.rating}
                                            type="radio"
                                            required
                                            name="rating"
                                            value="Cold" />Cold
                             </label>
                                    <label className="radio-inline" style={{ marginRight: "10px" }}>
                                        <input type="radio"
                                            style={{ marginRight: "10px" }}
                                            required
                                            onChange={this.inputChange}
                                            value={this.state.rating}
                                            type="radio"

                                            name="rating"
                                            value="Hot" />Hot
                              </label>
                                    <label className="radio-inline" style={{ marginRight: "10px" }}>
                                        <input type="radio"
                                            style={{ marginRight: "10px" }}
                                            required
                                            value={this.state.rating}
                                            onChange={this.inputChange}
                                            type="radio"

                                            name="rating"
                                            value="warm" />Warm
                                 </label>

                                </div></div>


                            {/*<Form.Group controlId="formBasicCheckbox">
                        <label class="checkbox-inline"><input type="checkbox" value=""/>Save User Info</label>
                            
                                        </Form.Group>*/}
                            <hr />
                            <Form.Row>
                                <Col md="10" >

                                </Col>
                                <Col md="12">
                                    <Button variant="primary" 
                                        type="submit">
                                        Submit
                                   </Button>
                                   <Button variant="danger" type="reset" style={{marginLeft:"5px"}}>
                                   Reset
                                </Button>
                                </Col>
                                
                                  
                               
                            </Form.Row>

                        </Form>


                    </Card.Body>
                </Card>

            </div>
        )
    }
}
export default withRouter(Add_customer);
