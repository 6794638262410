import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem  } from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars';  
import { logout } from '../store/rootAction';
import axios from 'axios';
import { BASE_URL } from '../helpers/Constants';

class header extends React.PureComponent {
    constructor(props) {
      
        super(props);
        
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization':"Bearer  "+this.props.token
               }
           }
        this.state = {
            dropdownOpen: false,
            dropdownOpen1: false,
            dropdownOpenprofile: false,
            dropdownOpenbadge: false,
            now_route : "",
            headers : headers,
            notifications:[],
            profile_img:'',
        };
        // this.props.history.push({ status:''})
        this.toggle = this.toggle.bind(this);
        this.toggle1 = this.toggle1.bind(this);
        this.toggleprofile = this.toggleprofile.bind(this);
        this.togglebadge = this.togglebadge.bind(this);
        this.EditProfile = this.EditProfile.bind(this);
        this.getNotifcation = this.getNotifcation.bind(this);
    }
    EditProfile(){
        this.props.history.push('/edit-profile');
    }


    toggle() {
        this.setState(prevState => ({
          dropdownOpen: !prevState.dropdownOpen
        }));
    }
    
    toggle1() {
        this.setState(prevState => ({
            dropdownOpen1: !prevState.dropdownOpen1
        }));
    }

    toggleprofile() {
        console.log(this.props);
        this.setState(prevState => ({
            dropdownOpenprofile: !prevState.dropdownOpenprofile
        }));
    }

    togglebadge() {
        this.setState(prevState => ({
            dropdownOpenbadge: !prevState.dropdownOpenbadge
        }));
    }
    
    togglescreen(e)
    {
        if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement) 
        {
            if (document.documentElement.requestFullscreen) 
            {
                document.documentElement.requestFullscreen();
            } 
            else if (document.documentElement.mozRequestFullScreen) 
            {
                document.documentElement.mozRequestFullScreen();
            } 
            else if (document.documentElement.webkitRequestFullscreen) 
            {
                document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        } 
        else 
        {
            if (document.cancelFullScreen) 
            {
                document.cancelFullScreen();
            } 
            else if (document.mozCancelFullScreen) 
            {
                document.mozCancelFullScreen();
            } 
            else if (document.webkitCancelFullScreen) 
            {
                document.webkitCancelFullScreen();
            }
        }
    }
    getNotifcation(){
        axios.get(BASE_URL + `/api/leads/notification/view`,this.state.headers)
                .then(response => {
                    console.log(response.data);
                    this.setState({
          
                        notifications: response.data.data,
                     
                      })
                   
                }).catch(err =>{ console.log(err)
                    
                
            });
    }

    componentDidMount()
    {
         this.setState({now_route :this.props.location.pathname ,
       headers:this.state.headers });
       console.log(this.state.headers);
       axios.get(BASE_URL + `/api/getUserDetail`,this.state.headers)
                .then(response => {
                   
                    this.setState({
                        profile_img:response.data.data.profile_img,
                        name:response.data.data.name,
                    

                     
    
                    })
                    console.log("headersss data "  +this.state.name)
                }).catch(err =>{ console.log(err)
           
          });   
 
    //  setInterval(function(){ 
    //         this.getNotifcation();
    //         }, 5000);       
        
             
            
     }



    handleLogout = event => {
        event.preventDefault();
        this.props.logout({
            auth_token: this.props.token,
            api_url: this.props.url,
            history: this.props.history
        });
    };

    render() {
        
        return (
            <div className="topbar">
                <nav className="navbar-custom" style={{backgroundColor:"#317eeb"}}>
                    <div className="search-wrap" id="search-wrap">
                        <div className="search-bar">
                            <input className="search-input" type="search" placeholder="Search" />
                            <a href={() => null} className="close-search toggle-search" data-target="#search-wrap">
                                <i className="mdi mdi-close-circle"></i>
                            </a>
                        </div>
                    </div>
                    <ul className="list-inline float-right mb-0">
                        {/* 
                        <li className="list-inline-item dropdown notification-list">
                            <Link  className="nav-link waves-effect toggle-search" to="#"  data-target="#search-wrap">
                                <i className="mdi mdi-magnify noti-icon"></i>
                            </Link>
                        </li> 
                        */}
                        <li className="list-inline-item dropdown notification-list hidden-xs-down">
                            <Link  onClick={() => this.togglescreen()}  className="nav-link waves-effect" to="#" id="btn-fullscreen">
                                <i className="mdi mdi-fullscreen noti-icon"></i>
                            </Link>
                        </li>
                        <li className="list-inline-item dropdown notification-list">
                            <Dropdown isOpen={this.state.dropdownOpenbadge}  toggle={this.togglebadge}>
                                <DropdownToggle className="nav-link dropdown-toggle droptest arrow-none waves-effect" tag="a">
                                    <i className="ion-ios7-bell noti-icon"></i>
                                    <span className="badge badge-pill badge-danger noti-icon-badge">3</span>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu dropdown-menu-right dropdown-menu-lg">
                                    <h6 className="dropdown-item-text">
                                        Notifications (258)
                                    </h6>
                                    <DropdownItem >
                                        <Scrollbars style={{ height: "200px" }} className="notification-item-list" >
                                            <Link id="ex" to="#" className="dropdown-item notify-item active">
                                                <div className="notify-icon bg-success"><i className="mdi mdi-cart-outline"></i></div>
                                                <p className="notify-details">Your order is placed<span className="text-muted">Dummy text of the printing and typesetting industry.</span></p>
                                            </Link>
                                        </Scrollbars>
                                        <Link id="ex" to="#" className="dropdown-item text-center text-primary">
                                            View all <i className="fi-arrow-right"></i>
                                        </Link>
                                    </DropdownItem>
                                </DropdownMenu>                                
                            </Dropdown>
                        </li>
                        <li className="list-inline-item dropdown notification-list">
                            <Dropdown isOpen={this.state.dropdownOpenprofile}  toggle={this.toggleprofile}>
                                <DropdownToggle className="nav-link dropdown-toggle droptest arrow-none waves-effect nav-user" tag="a">
                                {this.state.profile_img =="" ?
                                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSV_cjdoo3iekygnX4NNdWLVwNoHiB_T02EjQ&usqp=CAU" alt="user" className="rounded-circle" />
                              :
                            <img  src={this.state.profile_img} alt="user" className="rounded-circle" />
                              
                                }
                                    </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem><i className="mdi mdi-account-circle m-r-5"></i><Link to={`/edit-profile`}> Profile</Link></DropdownItem>
                         {/*  <DropdownItem><i className="mdi mdi-wallet m-r-5"></i> My Wallet</DropdownItem>
                                    <DropdownItem><span className="badge badge-success float-right">11</span><i className="mdi mdi-settings m-r-5"></i> Settings</DropdownItem>
                                    <DropdownItem><i className="mdi mdi-lock-open-outline m-r-5"></i> Lock screen</DropdownItem> */} 
                                    <DropdownItem onClick={this.handleLogout}><i className="mdi mdi-power text-danger"></i> Logout</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </li>
                    </ul>
                    <ul className="list-inline menu-left mb-0">
                        <li className="list-inline-item">
                            <button type="button" className="button-menu-mobile open-left waves-effect">
                                <i className="ion-navicon"></i>
                            </button>
                        </li>
                        {/*<li className="hide-phone list-inline-item app-search">
                            <h3 className="page-title" id="now_routing">Skill360</h3>
                                </li>*/}
                    </ul>
                    <div className="clearfix"></div>
                </nav>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        logout: data => dispatch(logout(data))
    };
};

export default connect(null, mapDispatchToProps)(withRouter(header));