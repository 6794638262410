/* eslint-disable*/
import React, { Component } from 'react'
import { withRouter, History } from 'react-router-dom';
import ReactDOM from 'react-dom';
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { BASE_URL } from '../../../helpers/Constants';
import './page.css';
import SuccessAlert from './SuccessAlert';
import jwtDecode from 'jwt-decode';
import { Link } from 'react-router-dom'
import { FaBeer, FaEye, FaChevronCircleRight, FaExclamationTriangle } from 'react-icons/fa';
import Modal from 'react-bootstrap/Modal'
import Pagination from "react-js-pagination";
import { post } from 'jquery';
import excel from 'xlsx';


function serachingfor(term) {
    var search;

    return function (x) {
        return (x.first_name || '').toLowerCase().includes(term.toLowerCase()) || !term
            || (x.mobile || '').toLowerCase().includes(term.toLowerCase()) || !term
            || (x.website || '').toLowerCase().includes(term.toLowerCase()) || !term
            || (x.email || '').toLowerCase().includes(term.toLowerCase()) || !term
            || (x.state_province || '').toLowerCase().includes(term.toLowerCase()) || !term
            || (x.lead_status || '').toLowerCase().includes(term.toLowerCase()) || !term
            || (x.lead_owner || '').toLowerCase().includes(term.toLowerCase()) || !term
            || (x.date_created || '').toLowerCase().includes(term.toLowerCase()) || !term;
    }
}

class leads extends Component {
    constructor(props) {
        
        super(props)
      
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + this.props.auth_user.user_data.original.access_token
            }
        }
        this.msg = '';
        const initialState = {
            isOpen: false, 
            value: "defaultvalue"
       };
        this.state = {

            
            display: false,
            sales_people_id: '',
            lead_id: '',
            assigne_job_person: [],
            all_leads: [],
            lead_array: [],
            leads: [],
            lead_status: '',
            assigne: [],
            isChecked: false,
            lead_owner: '',
            term: '',
            activePage: 1,
            itemsCountPerPage: 1,
            totalItemsCount: 1,
            pageRangeDisplayed: 3,
            status: 2,
            headers: headers,
            uploadFile: '',
            org_id: this.props.auth_user.user_data.original.user.org_id,
            user_id: this.props.auth_user.user_data.original.user.id,
            file: null,
            
            setShow:false,
            showModal: false

        }

        this.serachHandeler = this.serachHandeler.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.inputChange = this.inputChange.bind(this);
        this.handelSearchSubmit = this.handelSearchSubmit.bind(this);
        this.refresh = this.refresh.bind(this);
        this.handleChecked = this.handleChecked.bind(this);
        this.handelmodalSubmit = this.handelmodalSubmit.bind(this);
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
        this.toggleHandler = this.toggleHandler.bind(this);
        this.handelImportSubmit = this.handelImportSubmit.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.modalToggle = this.modalToggle.bind(this);

    }
    
    handleClose() {  // modal close to reset input val
        this.setState({
            setShow: this.state.setShow
        });
    }
    modalToggle() {
        console.log('working modal');
        this.setState({
            showModal: !this.state.showModal
        });
        // this.setState({
        //     setShow: !this.state.setShow
        // });

    }
    handelImportSubmit(e) {
        e.preventDefault();
        console.log("this is handelimportsubmit");

        var formData = new FormData();
        var excelfile = document.querySelector('#testFile');
        formData.append("uploadFile", excelfile.files[0]);
        formData.append("org_id", this.state.org_id);
        formData.append("created_by", this.state.user_id);
        formData.append("status", this.state.status);


        axios.post(BASE_URL + '/api/leads/importleads', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }

        }).then(response => {

            this.setState({

                response_status: response.data.status,
                response_message: response.data.message
            })
            //  window.location.reload();
            if (typeof (response.data.status) != 'undefined' && response.data.status == true) {

                this.props.history.push({ state: { msg: response.data.message, status: response.data.status } });

            }

        }).catch(err => console.log(err));

    }
    toggleHandler = () => {
        let checkboxes = document.getElementsByClassName('testcheckbox')
        this.setState({
            display: false
        })
        for (var i = 0; i < checkboxes.length; i++) {
            if (checkboxes[i].checked) {
                this.setState({
                    display: true
                })
                break;
            }
        }
        // const currentStatus=this.state.display;
        // this.setState({
        //   display: !currentStatus
        // })
    }
    /* for assigne job  through modal*/
    handleDropdownChange(e) {
        this.setState({ sales_people_id: e.target.value });
    }

    handelmodalSubmit(e) {
       
    //     let modalshow =document.getElementById('#job')
    //       this.setState({
    //       modal:fasle
    //   })
      e.preventDefault();
      
        axios.post(BASE_URL + '/api/leads/AssignLeadToSalePerson', {
            user_id: this.props.auth_user.user_data.original.user.id,
            sales_people_id: this.state.sales_people_id,
            lead_id: this.state.assigne,
            message: this.state.message,
            status: this.state.status,
        }, this.state.headers).then(response => {
            this.setState({
                response_status: response.data.status,
                response_message: response.data.message
            })
             window.location.reload();
            if (typeof (response.data.status) != 'undefined' && response.data.status == true) {

                this.props.history.push({ pathname: '/leads', state: { msg: response.data.message, status: response.data.status } });

            }

        }).catch(err => console.log(err));

    }

    // const lead_id_array="";

    handleChecked(event) {
        let list = this.state.assigne;
        const target = event.target;
        var value = target.value;

        if (target.checked) {
            this.setState({
                assigne: [...this.state.assigne, value]
            })
            // this.state.assigne[value] = value;   
        }
        else {
            this.setState({
                assigne: []
            })

            // this.state.assigne.splice(value, 1);
        }

    }
    submit() {

        console.warn(this.state.assigne);

    }

    refresh() {
      
       window.location.reload();

    }

    handelSearchSubmit(event) {
        console.log("search working")
        event.preventDefault();
        axios.post(BASE_URL + '/api/all_leads', {
            lead_status:this.state.lead_status,
            user_id:this.state.user_id,
            advSearchIndex:this.state.advSearchIndex,}
             , this.state.headers)
            .then(response => {
                console.log(response.data.data)
                this.setState({
                  
                    leads: response.data.data,
                    itemsCountPerPage: response.data.per_page,
                    totalItemsCount: response.data.total,
                    activePage: response.data.current_page,
                  
                })
                this.modalToggle();

            }).catch(err => console.log(err));

       
    }

    serachHandeler(event) {
        this.setState({ term: event.target.value })
    }

    handlePageChange(pageNumber) {
        console.log(`active page is ${pageNumber}`);
        this.setState({ activePage: pageNumber });
        
        axios.post(BASE_URL + '/api/all_leads?page=' + pageNumber,{user_id:this.state.user_id}, this.state.headers)
            .then(response => {
                this.setState({
                    leads: response.data.data,
                    itemsCountPerPage: response.data.per_page,
                    totalItemsCount: response.data.total,
                    activePage: response.data.current_page
                })
            });
    }

    inputChange(e) {
        console.log("input type working");

        this.setState({
            [e.target.name]: e.target.value,
            // file: e.target.files[0],
            // loaded: 0,

        });
        // console.warn(e.target.files[0].name);
        // console.warn("path" + e.target.value);

    }


    componentDidMount() {

        console.log('callled leads ');
        console.log(this.props.location.state);
        if (this.props.location.state != undefined) {
            console.log('Status  Found');

        } else {

            console.log('Status Not Found');
        }
        axios.post(BASE_URL + '/api/all_leads',
        {user_id:this.props.auth_user.user_data.original.user.id,
            org_id:this.props.auth_user.user_data.original.user.org_id,
        users_role:this.props.auth_user.user_data.original.user.designation,
        team_head_id:this.props.auth_user.user_data.original.user.team_head_id}, this.state.headers)
            .then(response => {
                this.setState({
                    leads_id: response.data.data.id,
                    leads: response.data.data,
                    itemsCountPerPage: response.data.per_page,
                    totalItemsCount: response.data.total,
                    activePage: response.data.current_page,

                })
            }).catch(err => console.log(err));
            
        axios.post(BASE_URL + '/api/users/fetch_team_member_for_telecalling_assign',
        {org_id:this.props.auth_user.user_data.original.user.org_id,
            user_id:this.props.auth_user.user_data.original.user.id,
            team_head_id:this.props.auth_user.user_data.original.user.id,
            name:this.props.auth_user.user_data.original.user.username}, this.state.headers)
            .then(response => {
                this.setState({
                    assigne_job_person: response.data
                })
            }).catch(err => console.log(err));

    }

    // componentWillMount() {
    //     if (this.props.location.state && this.props.location.state.msg) {
    //         this.msg = this.props.location.state.msg;
    //         this.props.history.replace({
    //             pathname: this.props.location.pathname,
    //             state: {}
    //         });
    //     }
    // }

    render(props) {
     
        
        let content = null;
        const { term, leads } = this.state;
        if (this.state.display) {
            content = <button data-toggle="modal" data-target="#job" className="btn btn-primary" style={{ marginLeft: "8px" }} > Assign </button>
        }

        return (

            <div>

                {this.props.location.state != undefined ?
                    [(this.props.location.state.status == true
                        ? <SuccessAlert message={this.props.location.state.msg} />
                        : <p style={{ color: "#eceff1" }}> working </p>
                    ),
                    <p style={{ color: "#eceff1" }}> working</p>
                    ]

                    :
                    //    <p> not working</p>
                    null
                }

                <div class="row">
                    <div class="col-sm-12" id="breadcrum" style={{ marginTop: "-23px", marginBottom: "0px", borderBottom: "1px dashed #b9b9b9 " }}>
                      
                        <h4 class="pull-left page-title" style={{ lineHeight: "48px" }}><i class="fa fa-users" aria-hidden="true"></i>&nbsp;&nbsp;Leads</h4>
                        <ol class="breadcrumb pull-right">

                            <li><Link to={"/leads"} style={{ color: "#007bff", fontSize: "15px", scrollMarginBottom: "10px" }} className="pull-right active" >Lead</Link></li>

                            <li><Link to={"/leads"} style={{ fontSize: "15px", scrollMarginBottom: "10px" }} className="pull-right">Home</Link></li>

                        </ol>
                    </div>
                </div>

                <div className="card">
                    <div className="card-header">
                    <Row style={{ backgroundColor: "#eceff1", padding: "8px" }}>
                    <Col md={3} style={{ paddingLeft: "5px", paddingRight: "5px" }}>
                    <form>
                    <input type="text" name="" className="form-control"
                    onChange={this.serachHandeler}
                    placeholder="search..." value={term} />
                     </form>                     
                    </Col>
                    <Col md={6}>
                    <button type="button"  onClick={this.modalToggle} className="btn btn-primary pull-right" >Search</button>
                    <button type="reset" className="btn btn-primary pull-right" onClick={this.refresh} style={{ marginLeft: "5px", marginRight: "5px" }}>Refresh</button>

                    <Link to="/add-leads" className="btn btn-primary pull-right" style={{ marginLeft: "5px" }}>Add</Link>
                    </Col>
                    <Col md={3}>
                    {this.state.display == true ?
                        <button data-toggle="modal" data-target="#job" className="btn btn-primary pull-right" style={{ marginLeft: "8px" }}> Assign </button>
                        :
                        null
                    }
                    <button data-toggle="modal" data-target="#Modalimport" className="btn btn-primary pull-right" style={{ marginLeft: "8px" }}> Import </button>
                    </Col>

                    </Row>

                    </div>

                    <div className="card-body">
                        <table class="table table-hover table-striped table-bordered">
                            <thead>
                                <tr>
                                {this.props.auth_user.user_data.original.user.designation == 1 || this.props.auth_user.user_data.original.user.designation == 2 || this.props.auth_user.user_data.original.user.designation == 3 || this.props.auth_user.user_data.original.user.designation == 4 ?

                                    <th style={{ width: "72px"}}>Select</th>
                                    : <th  style={{ width: "72px"}}>Sl No.</th>
                                  }
                                   
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th style={{ width: "30px",textAlign:"right"}}>Mobile</th>
                                    <th>Website</th>
                                    <th>State</th>
                                    <th>City</th>
                                    <th>Status</th>
                                    <th>Assign</th>
                                    <th>Owner</th>
                                    <th style={{ width: "30px",textAlign:"right"}}>Create Date</th>
                                    <th style={{ width: "72px" }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {leads !== null
                                    ? leads.filter(serachingfor(term)).map((lead,index) => (
                                        <tr key={lead.id}>
                                        {this.props.auth_user.user_data.original.user.designation == 1 || this.props.auth_user.user_data.original.user.designation == 2 || this.props.auth_user.user_data.original.user.designation == 3 || this.props.auth_user.user_data.original.user.designation == 4 ?
                                            <input type="checkbox" className="testcheckbox" onClick={this.toggleHandler} value={lead.id} style={{marginLeft:"16px"}} name="assign" onChange={this.handleChecked} />
                                         :<td>{++index}.</td>
                                          }
                                       
                                            <td style={{ color: '#007bff' }}><Link to={`/leads/view/${lead.id}`} style={{ color: "#007bff",fontSize:"15px", scrollMarginBottom: "10px" }}>{lead.first_name}</Link></td>
                                            <td style={{fontSize:"15px"}}>{lead.email}</td>
                                            <td style={{fontSize:"15px",textAlign:"right"}}>{lead.mobile}</td>
                                            <td style={{fontSize:"15px"}}>{lead.website}</td>
                                            <td style={{fontSize:"15px"}}>{lead.state_province}</td>
                                            <td style={{fontSize:"15px"}}>{lead.city_distt}</td>
                                            <td style={{ color: '#007bff' }} style={{fontSize:"15px"}}>{lead.lead_status}</td>
                                            {lead.lead_owner == null ?
                                           <td style={{fontSize:"15px",color:"Red"}}>Pending</td>
                                           :
                                           <td style={{fontSize:"15px", color:"green"}}> {lead.lead_owner}</td>
                                           }
                                           
                                           <td style={{fontSize:"15px"}}> {lead.owner_id}</td>
                                          
                                            
                                          
                                            <td style={{fontSize:"15px",textAlign:"right"}}>
                                                {lead.date_created}</td>
                                            <td>
                                                <div className="row">
                                                <div className="col-md-12">

                                                <Link to={`/leads/view/${lead.id}`} style={{ color: "#007bff", fontSize: "15px", scrollMarginBottom: "10px" }}><FaEye /></Link>&nbsp;&nbsp;&nbsp;
                                                        <Link to="" style={{ color: "red", fontSize: "15px", scrollMarginBottom: "10px" }}><i className=" mdi mdi-delete"></i></Link>

                                                </div>
                                                 </div>
                                            </td>
                                        </tr>

                                    ))
                                    :
                                    <p className="text-center">Data not Available</p>
                                }

                            </tbody>
                        </table>

                        <div className="pagination justify-content-left">
                            <Pagination
                                activePage={this.state.activePage}
                                itemsCountPerPage={this.state.itemsCountPerPage}
                                totalItemsCount={this.state.totalItemsCount}
                                pageRangeDisplayed={this.state.pageRangeDisplayed}
                                onChange={this.handlePageChange}
                                itemClass='page-item'
                                linkClass='page-link'
                            />
                        </div>
                    </div>
                </div>
                <div className="modal" id="job">
                    <div className="modal-dialog">
                        <div className="modal-content" style={{marginTop:"-4px"}}>

                            <div className="modal-header" style={{ backgroundColor: "#abc7e4", color: "black" }}>
                                <h5 className="modal-title">Assign lead</h5>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>

                            <div className="modal-body">
                                <form onSubmit={this.handelmodalSubmit}>
                                    <div class="form-group">
                                        <input type="hidden" name="lead_id" value={this.state.assigne} />
                                    </div>

                                    <div class="form-group">
                                        <label for="exampleFormControlSelect1">Select to assign Job.</label>
                                        <select class="form-control" id="exampleFormControlSelect1" onChange={this.handleDropdownChange} name="sales_people_id">
                                            <option>--Select--</option>

                                            {Array.isArray(this.state.assigne_job_person) && this.state.assigne_job_person.map(person => (
                                                <option value={person.id}>{person.name}</option>

                                            ))

                                            }
                                        </select>
                                    </div>


                                    <div className="modal-footer">
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                        <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal" id="Modalimport">
                    <div class=".modal .modal-lg">
                        <div className="modal-content" style={{marginTop:"20px"}}>

                            <div className="modal-header" style={{ backgroundColor: "rgb(200 210 220)", color: "black" }}>
                                <h5 className="modal-title">Leads :Import Data</h5>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            {this.props.location.state != undefined ?
                                [(this.props.location.state.status == true
                                    ? <SuccessAlert message={this.props.location.state.msg} />
                                    : <p style={{ color: "#eceff1" }}> working </p>
                                ),
                                <p style={{ color: "#eceff1" }}> working</p>
                                ]

                                :
                                //    <p> not working</p>
                                null
                            }
                            <div className="modal-body">
                                <form onSubmit={this.handelImportSubmit}>
                                    <div class="form-group">
                                        <input type="hidden" name="lead_id" value={this.state.assigne} />
                                        <input type="hidden" name="created_by" value={this.state.user_id} />
                                        <input type="hidden" name="org_id" value={this.state.org_id} />
                                    </div>
                                    <div class="row">

                                        <div class="col-lg-12">
                                            <div class="card">
                                                <div class="card-body">
                                                    <h4>Instruction</h4>
                                                    <p style={{ color: "#000" }}>1. Excel sheet format for Leads data can be downloaded from here   --   <span> <a href="http://localhost/sales_baba_api/public/export/sample_page.xlsx"> <span style={{ color: "#1828e2" }}>LeadsData - ImportFile.xlsx </span></a></span><br /><br /></p>
                                                    <p style={{ color: "#000" }}>2. Please prepare your data according to the excel sheet, and return here for importing Leads data. <br /><br /></p>
                                                    <p style={{ color: "#000" }}>3. Name, Company Name, email and Phone No. are minimum requirements and mandatory data for each Lead. Import program will not proceed if any Leads has been already exist. <br /><br /></p>
                                                    <form>
                                                        <div class="form-group">
                                                            <label for="">Data File to Import:</label>
                                                            <div style={{ display: "flex" }}>
                                                                <input type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" class="form-control" name="uploadFile" id="testFile" onChange={this.inputChange} placeholder="" style={{ width: "80%" }} required/>

                                                                <button type="submit" class="btn btn-primary" style={{ marginLeft: "1em" }}>Import</button>&nbsp;&nbsp;
                                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>

                                                            </div>
                                                            <br /> <p style={{ color: "red" }}>Only .xlsx* format is accepeted. </p>
                                                        </div>
                                                    </form>
                                                    {this.props.location.state != undefined ?
                                                        [(this.props.location.state.status == true
                                                            ? <a href="http://localhost/sales_baba_api/public/format/error/contact-errorLog.txt" title="Download Error Log file" ><i className="mdi mdi-arrow-down-bold-circle pull-left" style={{ fontSize: "30px", backgroundColor: "#35733f", color: "#fff" }}></i></a>
                                                            : <p style={{ color: "#eceff1" }}> working </p>
                                                        ),
                                                        <p style={{ color: "#eceff1" }}> working</p>
                                                        ]

                                                        :
                                                        //    <p> not working</p>
                                                        null
                                                    }
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </form>

                            </div>

                           

                        </div>
                    </div>
                </div>
                
               

                {/*<Modal show={this.state.isOpen}
                onClose={this.toggleModalClose}>
               <h1>hello</h1>
                </Modal>*/}

                <Modal {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered style={{opacity:"1"}} show={this.state.showModal} onHide={this.modalToggle}>
                    <Modal.Header closeButton>
                        <Modal.Title>Leads : Search</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={this.handelSearchSubmit}>
                    <Modal.Body >
            
                   
                    <div class="form-group">
                
                    <div className="row">
                    <div className="col-md-6">
                    <label for="status">Lead Status:</label>
                    <select id="select" className="selectpicker form-control" onChange={this.inputChange}
                    value={this.state.lead_status} name="lead_status">
                    <option style={{ width: "100%" }}>Lead Status</option>
                    <option style={{ width: "100%" }}>Unqualified</option>
                    <option style={{ width: "100%" }}>new</option>
                    <option style={{ width: "100%" }}>working</option>
                    <option style={{ width: "100%" }}>Nurturing</option>
                    <option style={{ width: "100%" }}>qualified</option>
                   </select>
                    </div>
                    <div className="col-md-6">
                   
                    <label for="status">Search:</label>
                    <input type="text" name="advSearchIndex" value={this.state.advSearchIndex}
                    onChange={this.inputChange} class="form-control eml" placeholder="Search...." />
                    
                    </div>
                    </div>
                    
                    </div>
                   
                   
         
                   
                   
         
                    </Modal.Body>
                    <Modal.Footer>
                   
                    <button type="submit" class="btn btn-primary pull-right">Submit </button>
                    <button type="button"   onClick={this.modalToggle} className="btn btn-danger pull-right" style={{marginLeft:"8px"}} data-dismiss="modal">Close</button>
                    </Modal.Footer>
                    </form>
                </Modal>
             

            </div>

        )
    }
}
export default withRouter(leads);

