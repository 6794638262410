/* eslint-disable*/      
 import React, { Component } from 'react';
import { withRouter,Link } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../../../helpers/Constants';
import SuccessAlert from './SuccessAlert';     
import ErrorAlert from './ErrorAlert';

class add_team_member extends Component {
  
        constructor(props) {
            super(props)
            const user_id = this.props;
            console.log(user_id);
            this.state = {
               
                name: '',
                profile_img: '',
                designation: '',

                email: '',
                gender: '', phone: '', address: '', country:'', address2: '', city: '', state: '', pincode: '',
                username: '', password: '',designations:[],users_role:'',team_head_id:this.props.auth_user.user_data.original.user.id,

            }
            this.inputChange = this.inputChange.bind(this);
            this.handelFormSubmit = this.handelFormSubmit.bind(this);
            this. getnewstate= this.getnewstate.bind(this);
            this.getnewcity= this.getnewcity.bind(this);
            this.getcity_id = this.getcity_id.bind(this);
            this.imageChange = this.imageChange.bind(this);
        }

        imageChange(e) {
            e.preventDefault();
    
            let reader = new FileReader();
            let file = e.target.files[0];
           
    
            reader.onloadend = () => {
                this.setState({
                  
                    file: file,
                    imagePreviewUrl: reader.result,
                   
                });
            }
    
            reader.readAsDataURL(file)
           }

        inputChange(e) {
            console.log("input type working");

            this.setState({
                [e.target.name]: e.target.value,


            });
            console.log("country" + this.setState.country);

        }

        handelFormSubmit(event) {
            const users = this.props.match.params.id
            
            const headers = {
                headers: {
                'Content-Type': 'application/json',
                'Authorization':"Bearer "+this.props.auth_user.user_data.original.access_token
                }
                }
                
                console.log(this.state.user_id)
                event.preventDefault();
                var formData = new FormData();
                var imgFile = document.querySelector('#testFile');
                formData.append("profile_img", imgFile.files[0]);
                formData.append("team_head_id", this.props.auth_user.user_data.original.user.id);
                 formData.append("users_role", this.state.designation);
                 formData.append("created_by",this.props.auth_user.user_data.original.user.id);
                 formData.append("name", this.state.name);
                 formData.append("designation", this.state.designation);
                 formData.append("email", this.state.email);
                 formData.append("gender", this.state.gender);
                 formData.append("phone", this.state.phone);
                 formData.append("address", this.state.address);
                 formData.append("address2", this.state.address2);
                 formData.append("city", this.state.city);
                 formData.append("state", this.state.state);
                 formData.append("pincode", this.state.pincode);
                 formData.append("username", this.state.username);
                 formData.append("password", this.state.password);
                 formData.append("country", this.state.country);
                 formData.append("org_id", this.props.auth_user.user_data.original.user.org_id);

                axios.post(BASE_URL + `/api/sales_people/add/${users}`,formData,headers).then(response => {
                    this.setState({
                        response_status: response.data.status,
                        response_message: response.data.message

                    })
                    if(response.data.status==true)
                {

                    this.props.history.push({pathname :'/team-members', state :{msg:response.data.message,status:response.data.status}});
                }

            }).catch(err => console.log(err));

        }


        componentDidMount() {
            const id = this.props.match.params.id
            const headers = {
                headers: {
                'Content-Type': 'application/json',
                'Authorization':"Bearer "+this.props.auth_user.user_data.original.access_token
                }
                }
                axios.get(BASE_URL + `/api/sales_people/fetch/${id}`,this.state.headers).then(response => {
                    console.log(response.data);
                        this.setState({
                            name: response.data.name,
                            profile_img: response.data.profile_img,
                            designation: response.data.designation,
                            email: response.data.email,
                            gender: response.data.gender,
                            phone: response.data.phone,
                            status:response.data.status
                           
                        })
                        this.getonloadstate();
                        this.getonloadcity();
                    }).catch(err => console.log(err));


                axios.post(BASE_URL + '/api/listDesignationForDataMiner_Telecaller', {org_id:this.props.auth_user.user_data.original.user.org_id},headers)
                .then(response => {
                    this.setState({
                        designations: response.data,
                        org_id:this.props.auth_user.user_data.original.user.org_id,
                    })
                }).catch(err => console.log(err));
        
        }

        getnewstate(e){
            const headers = {
                headers: {
                'Content-Type': 'application/json',
                'Authorization':"Bearer "+this.props.auth_user.user_data.original.access_token
                }
                }
            this.setState({
            country:e.target.value
                        
        })
            const country_id= e.target.value;
            console.log(country_id);
            axios.get(BASE_URL + `/api/list_state/${country_id}`,headers)
            .then(response => {
                this.setState({
                    states: response.data,
                })
            }).catch(err => console.log(err));

        }
        getnewcity(e){
            const headers = {
                headers: {
                'Content-Type': 'application/json',
                'Authorization':"Bearer "+this.props.auth_user.user_data.original.access_token
                }
                }
            this.setState({
            state:e.target.value,               
        })

        const state_id =e.target.value;
        console.log("state" + state_id);
        axios.get(BASE_URL +`/api/list_cities/${state_id}`,headers)
        .then(response => {
            this.setState({
                citys: response.data,
                
            })
        }).catch(err => console.log(err));
        
    }
    getcity_id(e){
    this.setState({
    
        city:e.target.value
                
    })

    }

    render() {
        let { imagePreviewUrl } = this.state;
        let $imagePreview = null;
        if (imagePreviewUrl) {
            $imagePreview = (<img style={{ width: "120px", height: "80px", marginLeft: "38px",border:"dashed 2px" }}  src={imagePreviewUrl} />);
        } else {
            $imagePreview = (<div class="form-group col-md-2 mb-3">
            <img src={this.state.profile_img} style={{ width: "80px", height: "80px", marginLeft: "38px" }} />
        </div>);
        }
        return (
            <div>

            {this.state.response_status == false ?
                <ErrorAlert message= {this.state.response_message} />:null}
                

                <div class="row">
                    <div class="col-sm-12" id="breadcrum" style={{marginTop:"-23px",marginBottom:"0px",borderBottom:"1px dashed #b9b9b9 "}}>
                <h4 class="pull-left page-title" style={{lineHeight:"48px"}}><i class="fa fa-users" aria-hidden="true"></i>&nbsp;&nbsp;Team Members: Edit</h4>
                <ol class="breadcrumb pull-right">
                    
                <li><Link to={"/team-members"} style={{ color: "#007bff", fontSize: "15px", scrollMarginBottom: "10px" }} className="pull-right active" >Team Member </Link></li>
                <li><Link to={"/"} style={{  color: "#007bff",fontSize: "15px", scrollMarginBottom: "10px" }} className="pull-right">Edit</Link></li>
                <li><Link to={"/"} style={{ fontSize: "15px", scrollMarginBottom: "10px" }} className="pull-right">Home</Link></li>
    
                </ol>
            </div>
        </div>
        <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">

                            <div class="dsfdsf"></div>
                            <form onSubmit={this.handelFormSubmit}>
                        <input type="hidden" name="org_id" value={this.state.org_id}/>
                        <input type ="hidden" name="users_role" value={this.state.designation}
                        onChange={this.inputChange}/>
                        <input type="hidden" name="team_head_id" value={this.state.team_head_id}/>
                                <div class="form-row">
                                    <div class="col-md-6 mb-3">
                                        <label for="validationServer013">Name <span style={{color:"red"}}>*</span></label>
                                        <input required type="text" class="form-control eml" id="name" 
                                        value={this.state.name}
                                        onChange={this.inputChange} name="name" required pattern="[a-zA-Z\s]+"   maxlength="30" placeholder="Name" />
                                    </div>

                                    <div class="form-group col-md-2 mb-3">
                                    {$imagePreview}
                                </div>

                                <div class="form-group col-md-4 mb-3">
                                    <label for="validationServer013"> Upload Profile image</label>

                                    <input id="testFile" type="file" class="form-control"
                                        onChange={this.imageChange} required accept="image/*" name="profile_img" placeholder="Name" />

                                    <p id="profile_error_msg" style={{ color: "red" }}> </p>
                                </div>
                                             <div class="form-group col-md-6 mb-3">
                                                    <label for="validationServer013">Designation <span style={{ color: "red" }}>*</span></label>
                                                    {this.state.designation == "4" ?
                                                        <select class="select2 form-control" title="Designation can't be empty" required="" aria-required="true" name="designation" value={this.state.designation}
                                                            onChange={this.inputChange} id="designation" required >

                                                            <option value={4}>Telecaller</option>
                                                            <option value={5}>Data Miner</option>

                                                          
                                                            </select>
                                                            : null }

                                                            {this.state.designation == "5" ?
                                                                <select class="select2 form-control" title="Designation can't be empty" required="" aria-required="true" name="designation" value={this.state.designation}
                                                                    onChange={this.inputChange} id="designation" required >

                                                                    <option value={5}>Data Miner</option>
                                                                    <option value={4}>Telecaller</option>
                                                               
                                                              
                                                                </select>
                                                                : null }
                                                    
                                                 
                                                </div>

            {/*}   //  <div class="col-md-6 mb-3">
                                //         <label for="validationServer013">Designation<span style={{color:"red"}}>*</span></label>
                                //         <select class="select2 form-control" required name="designation" value={this.state.designation}
                                //         onChange={this.inputChange}  id="designation" title="Designation can't be empty" required=""  aria-required="true">
                                //         <option></option>
                                //         <option></option>
                                //        {/*} {this.state.designations !==null ?
                                //             this.state.designations.map(desig=>(
                                                
                                //                     <option value={desig.id} selected={desig.id == this.state.designation}>{desig.designation}</option>
                                               
                                //             ))
                                //             :
                                //             null
                                //             }*/}
                                            
                                            
                           {/*     //       </select>
                           //         </div>*/}
                                   

                                    <div class="col-md-6 mb-3">
                                        <label for="validationServer013">Email <span style={{color:"red"}}>*</span></label>
                                        <input required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" title="Invalid Email" type="text" class="form-control eml" value={this.state.email}
                                        onChange={this.inputChange}  placeholder="abc@gmail.com" name="email" id="email"  required="" required-area="true" />
                                    </div>

                                    <div class="col-md-6 mb-3">
                                        <label for="validationServer013">Gender <span style={{color:"red"}}>*</span></label>
                                        <div class="form-group inline" style={{ marginLeft: "10px" }}>
                                        {this.state.gender == "male" ?
                                            <div class="radio radio-info form-check-inline">
                                                <input required value={this.state.gender}
                                                onChange={this.inputChange} type="radio" id="male" value="male" name="gender" checked />
                                                <label for="inlineRadio1">Male</label>
                                            </div>
                                            :
                                    <div class="radio radio-info form-check-inline">
                                        <input required value={this.state.gender}
                                        onChange={this.inputChange} type="radio" id="male" value="male" name="gender" />
                                        <label for="inlineRadio1">Male</label>
                                    </div>
                                           
                                    }
                                    {this.state.gender == "female" ?
                                    <div class="radio radio-info form-check-inline">
                                    <input required  value={this.state.gender}
                                    onChange={this.inputChange} type="radio" id="female" value="female" name="gender" checked />
                                    <label for="inlineRadio1">Female</label>
                                </div>
                                    :
                                    <div class="radio radio-info form-check-inline">
                                        <input required  value={this.state.gender}
                                        onChange={this.inputChange} type="radio" id="female" value="female" name="gender" />
                                        <label for="inlineRadio1">Female</label>
                                    </div>
                            }

                                        </div>
                                    </div>

                                    <div class="col-md-6 mb-3">
                                        <label for="validationServer013">Phone <span style={{color:"red"}}>*</span></label>
                                        <input maxlength="15" pattern="[0-9]+\d*" title="Please enter number only" required="required" type="text"  value={this.state.phone}
                                        onChange={this.inputChange} class="form-control" name="phone" id="phone" placeholder="+91" maxlength="10" />
                                    </div>
                                     
                                    <div class="col-md-4">
                                    <div class="form-group">
                                      <p class="control-label"><b>Login Status</b>
                                        <font color="red">*</font>
                                      </p>
                                      {this.state.status == "1" ?
                                      <div class="radio radio-info form-check-inline">
                                      <input required type="radio" value={this.state.status} value="1"
                                             onChange={this.inputChange} name="status"
                                             style={{ marginRight: "10px", marginRight: "10px" }}  checked/>Active
                                        
                                      </div>
                                      :
                                      <div class="radio radio-info form-check-inline">
                                      <input required type="radio" value={this.state.status} value="1"
                                             onChange={this.inputChange} name="status"
                                             style={{ marginRight: "10px", marginRight: "10px" }} />Active
                                        
                                      </div>
                        }
                        {this.state.status == "0" ?
                        <div class="radio radio-info form-check-inline">
                        <input required type="radio" value={this.state.status} value="0"
                        onChange={this.inputChange} name="status"
                        style={{ marginRight: "10px", marginRight: "10px" }} checked/>Inactive
                          
                        </div>
                        :
                        <div class="radio radio-info form-check-inline">
                        <input required type="radio" value={this.state.status} value="0"
                        onChange={this.inputChange} name="status"
                        style={{ marginRight: "10px", marginRight: "10px" }} />Inactive
                          
                        </div>
          }
               
                                    </div>
                                  </div>

                                </div>


                           <hr/>
                                <div class="col-md-12" style={{ textAlign: "right" }}>
                                    <button type="submit" id="btnsubmit" class="btn btn-success btn-lg">Submit</button>
                                </div>
                                <br />

                            </form>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

);
}
}

export default withRouter(add_team_member);       

