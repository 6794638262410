import React, { Component } from 'react'
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container';
import { withRouter, Link } from 'react-router-dom';
import { FaBeer, FaEye, FaChevronCircleRight, FaBackward, FaPhoneSquare, FaHistory, FaBook } from 'react-icons/fa';
import { BASE_URL } from '../../../helpers/Constants';
import Pagination from "react-js-pagination";
import SuccessAlert from "../admin/SuccessAlert";

function serachingfor(term) {
    var search;

    return function (x) {
        return (x.first_name || '').toLowerCase().includes(term.toLowerCase()) || !term
            || (x.mobile || '').toLowerCase().includes(term.toLowerCase()) || !term
            || (x.website || '').toLowerCase().includes(term.toLowerCase()) || !term
            || (x.email || '').toLowerCase().includes(term.toLowerCase()) || !term
            || (x.state_province || '').toLowerCase().includes(term.toLowerCase()) || !term
            || (x.lead_status || '').toLowerCase().includes(term.toLowerCase()) || !term
            || (x.lead_owner || '').toLowerCase().includes(term.toLowerCase()) || !term
            || (x.created_at || '').toLowerCase().includes(term.toLowerCase()) || !term;
    }
}


class Tellecalling extends Component {
    constructor(props) {
        super(props)
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + this.props.auth_user.user_data.original.access_token
            }
        }
        this.msg = '';
        this.status = '';
        this.state = {
            term: '',
            leads: [],
            calls_stage: [],
            calls_responses: [],
            calls: [],
            activePage: 1,
            itemsCountPerPage: 1,
            totalItemsCount: 1,
            pageRangeDisplayed: 3,
            headers: headers,states:[],
            call_stage_id: '', response_id: '', reminder_before_time: '', comment: '', reminder_date: '', reminder_time: '',
            advSearchIndex:'',state_province:'',

        }
        this.serachHandeler = this.serachHandeler.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.modalToggle = this.modalToggle.bind(this);
        this.handelReminderSubmit = this.handelReminderSubmit.bind(this);
        this.inputChange = this.inputChange.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.searchToggle = this.searchToggle.bind(this);
        this.handelSearchSubmit = this.handelSearchSubmit.bind(this);
        
    }
    
    serachHandeler(event) {
        this.setState({ term: event.target.value })
    }
    handelSearchSubmit(event) {
        console.log("search working")
        event.preventDefault();
       
        axios.post(BASE_URL + "/api/all_leads",
        {
            advSearchIndex: this.state.advSearchIndex,
            state_province :this.state.state_province,
            
        }, this.state.headers)
        
            .then(response => {
                console.log(response.data.data)
                this.setState({
                    lead_status: this.state.lead_status,
                    leads: response.data.data,
                    itemsCountPerPage: response.data.per_page,
                    totalItemsCount: response.data.total,
                    activePage: response.data.current_page,
                  
                })
                this.searchToggle();

            }).catch(err => console.log(err));

       
    }
    
    refresh() {
              window.location.reload();
      }

     handlePageChange(pageNumber) {
        console.log(`active page is ${pageNumber}`);
        this.setState({ activePage: pageNumber });
         axios.get(BASE_URL + '/api/all_leads?page='+ pageNumber, this.state.headers)
            .then(response => {
                this.setState({
                    leads: response.data.data,
                    itemsCountPerPage: response.data.per_page,
                    totalItemsCount: response.data.total,
                    activePage: response.data.current_page
                })
            });
    }


    inputChange(e) {
        console.log("input type working");
        this.setState({
            [e.target.name]: e.target.value,

        });

    }
    handelReminderSubmit(event) {
        event.preventDefault();
        console.log("data" + this.props.auth_user.user_data.original.user.org_id)
        axios.post(BASE_URL + "/api/leads/addLeadsReminder",
            {
                org_id: this.props.auth_user.user_data.original.user.org_id,
                user_id: this.props.auth_user.user_data.original.user.id,
                call_stage_id: this.state.call_stage_id,
                response_id: this.state.response_id, reminder_before_time: this.state.reminder_before_time,
                comment: this.state.comment,
                reminder_date: this.state.reminder_date,
                reminder_time: this.state.reminder_time,
                lead_id: this.state.pid,

            }, this.state.headers)
          
            .then(response => {
                this.modalToggle();
                this.setState({
                    response_status: response.data.status,
                    response_message: response.data.message,


                })

                if (response.data.status == true) {

                    this.props.history.push({ pathname: '/telecalling', state: { msg: response.data.message, status: response.data.status } });
                }

            }).catch(err => console.log(err));


    }

    handleClose() {  // modal close to reset input val
        this.setState({
            setShow: this.state.setShow
        });
    }
    searchToggle(event){
        
        this.setState({
            showSearchModal: !this.state.showSearchModal,
            
        });

    }
  
    modalToggle(id,first) {

        console.log(id);
        console.log(first);
        const pid = id;
        const pname = first;


        console.log('working modal' + pname);
        this.setState({
            showModal: !this.state.showModal,
            pid: pid,
            pname: pname,

        });

    }
    
    componentDidMount() {
        console.log('callled leads ');
        axios.post(BASE_URL + '/api/all_leads',{user_id:this.props.auth_user.user_data.original.user.id}, this.state.headers)
            .then(response => {
                this.setState({

                    leads: response.data.data,
                    itemsCountPerPage: response.data.per_page,
                    totalItemsCount: response.data.total,
                    activePage: response.data.current_page,
                    id: response.data,
                })
            }).catch(err => console.log(err));

        axios.get(BASE_URL + '/api/call_Stage/listCallStage', this.state.headers)
            .then(response => {
                this.setState({
                    calls_stage: response.data.data,
                })
            }).catch(err => console.log(err));

        axios.get(BASE_URL + '/api/call_response/list_call_response', this.state.headers)
            .then(response => {
                this.setState({
                    calls_responses: response.data.data,

                })
            }).catch(err => console.log(err));

            axios.get(BASE_URL + `/api/list_state/100`,this.state.headers)
            .then(response => {
                this.setState({
                    states: response.data,


                })
            }).catch(err => console.log(err));

    }

    


    render(props) {
        const { term, leads } = this.state;
        return (

            <div style={{ marginTop: "0em" }}>

                {this.props.location.state != undefined ?
                    [(this.props.location.state.status == true
                        ? <SuccessAlert message={this.props.location.state.msg} />
                        : <p style={{ color: "#eceff1" }}> working </p>
                    ),
                    <p style={{ color: "#eceff1" }}> working</p>
                    ]

                    :
                    //    <p> not working</p>
                    null
                }
                <div class="row">
                    <div class="col-sm-12" id="breadcrum" style={{ marginTop: "-20px", marginBottom: "0px", borderBottom: "1px dashed #b9b9b9 " }}>
                        <h4 class="pull-left page-title" style={{ lineHeight: "48px" }}><i class="mdi mdi-phone-log" style={{ fontSize: "30px" }} aria-hidden="true"></i>&nbsp;&nbsp;Leads Call Details</h4>
                        <ol class="breadcrumb pull-right">

                            <li><Link to={`/telecalling`} style={{ color: "#007bff", fontSize: "15px", scrollMarginBottom: "10px" }} className="pull-right active" >Call Details</Link></li>

                            <li><Link to={"/telecalling"} style={{ fontSize: "15px", scrollMarginBottom: "10px" }} className="pull-right">Home</Link></li>

                        </ol>
                    </div>
                </div>


                <div className="card">
                    <div className="card-header">
                        <Row style={{ backgroundColor: "#eceff1", padding: "8px" }}>
                            <Col md={8} style={{ paddingLeft: "5px", paddingRight: "5px" }}>
                                <form>
                                    <input type="text" name="" className="form-control"
                                        onChange={this.serachHandeler}
                                        placeholder="search..." value={term} />
                                </form>
                            </Col>
                            <Col md={4}>
                                <button type="button" onClick={this.searchToggle}  className="btn btn-primary pull-right" >Search</button>
                                <button type="reset" className="btn btn-primary pull-right" onClick={this.refresh} style={{ marginLeft: "5px", marginRight: "5px" }}>Refresh</button>


                            </Col>


                        </Row>

                    </div>

                    <div className="card-body">
                        <table class="table table-hover table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Sl No.</th>
                                    <th>Lead Name</th>
                                    <th>Contact No.</th>
                                    <th>Email</th>
                                    <th>Area</th>


                                    <th>Action</th>

                                </tr>
                            </thead>
                            <tbody>
                                {leads !== null
                                    ? leads.filter(serachingfor(term)).map((lead, index) => (
                                        <tr key={lead.id}>
                                            <td>{++index}</td>
                                            <td style={{ color: '#007bff' }}><Link to={`/leads/view/${lead.id}`} style={{ color: "#007bff", scrollMarginBottom: "10px" }}>{lead.first_name}</Link></td>
                                            <td>{lead.phone}</td>
                                            <td>{lead.email}</td>
                                            <td>{lead.state_province}</td>


                                            <td>
                                                <Link to={`/leads/view/${lead.id}`} class="btn btn-default" title="View Leads Deatils" style={{ color: "#007bff", fontSize: "15px", scrollMarginBottom: "10px", marginRight: "5px" }}><FaEye /></Link>
                                                <button type="button" onClick={() => this.modalToggle(lead.id,lead.first_name)}  style={{ marginRight: "5px", color:"#fff" }} title="Create Schedule" data-id={lead.id} data-name={lead.first_name} class="btn btn-success"><i class="mdi mdi-watch-import"></i></button>
                                                <a href="/telecalling-response"  title="Put Response" style={{marginRight:"5px"}} class="btn btn-warning"><i class=" mdi mdi-phone-forward" telecallinglead_id={lead.id}></i></a>
                                                <Link to={`/view-schedule/${lead.id}`} class="btn btn-info" title="View Schedule Deatils" style={{ color: "#fff", fontSize: "15px", scrollMarginBottom: "10px", marginRight: "5px" }}><i class="mdi mdi-file-document"></i></Link>
                                              
                                            </td>
                                        </tr>

                                    ))
                                    :
                                    null
                                }
                                {leads == null?
                                <tr>
                                     <td colspan="6">No Data Found.</td>
                                      </tr>
                                      :null}

                            </tbody>
                        </table>

                        <div className="pagination justify-content-left">
                            <Pagination
                                activePage={this.state.activePage}
                                itemsCountPerPage={this.state.itemsCountPerPage}
                                totalItemsCount={this.state.totalItemsCount}
                                pageRangeDisplayed={this.state.pageRangeDisplayed}
                                onChange={this.handlePageChange}
                                itemClass='page-item'
                                linkClass='page-link'
                            />
                        </div>
                    </div>
                </div>
                <Modal {...props}
                    size="lg"

                    aria-labelledby="contained-modal-title-vcenter"
                    centered style={{ opacity: "1" }} show={this.state.showModal} onHide={this.modalToggle}>
                    <Modal.Header closeButton>
                        <Modal.Title>Call : Schedule</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={this.handelReminderSubmit}>
                        <Modal.Body >
                            <input type="hidden" name="lead_id" value={this.state.pid} />
                            <input type="hidden" value={this.state.org_id} />

                            <div class="form-group">


                                <div class="col-md-12 mb-3">
                                    <label for="field-2" class="control-label">Lead Name:<font color="red">*</font></label>
                                    <input type="text" value={this.state.pname}
                                        onChange={this.inputChange} class="form-control eml" maxlength="10" />

                                </div>


                                {/*<div class="col-sm-6 form-group required">
                                    <label className="control-label"> Call Stage <span style={{ color: "red" }}>*</span></label>
                                    <select class="form-control" name="call_stage_id" required="required" onChange={this.inputChange} value={this.state.call_stage_id}>
                                        <option value="">-- Select Call Satge --</option>
                                        {this.state.calls_stage !== null
                                            ? this.state.calls_stage.map(data => (

                                                <option value={data.id}>{data.call_stage}</option>
                                            ))
                                            :
                                            null
                                        }

                                    </select>
                                    <div style={{ fontSize: 12, color: "red" }}>

                                        {this.state.call_stage_idError}
                                    </div>
                                </div>
                                <div class="col-sm-6 form-group required">
                                    <label> Call Response <span style={{ color: "red" }}>*</span></label>
                                    <select class="form-control" name="response_id" required="required" onChange={this.inputChange} value={this.state.response_id}>
                                        <option> --Select Responses --</option>
                                        {this.state.calls_responses !== null
                                            ? this.state.calls_responses.map(call => (

                                                <option value={call.id}>{call.response_name}</option>
                                            ))
                                            :
                                            null
                                        }
                                    </select>
                                    <div style={{ fontSize: 12, color: "red" }}>
                                        {this.state.response_idError}
                                    </div>
                                    </div>*/}
                                <div class="col-md-6 mb-3">
                                    <label for="field-2" class="control-label">Prefer Date.:<font color="red">*</font></label>
                                    <input type="date" name="reminder_date" value={this.state.reminder_date}
                                        onChange={this.inputChange} class="form-control eml" placeholder="Search...." maxlength="10" />

                                </div>
                                <div class="col-md-6 mb-3">
                                    <label for="field-2" class="control-label">Time.:<font color="red">*</font></label>
                                    <input type="time" name="reminder_time" value={this.state.reminder_time}
                                        onChange={this.inputChange} class="form-control eml" placeholder="Search...." maxlength="10" />

                                </div>
                                <div class="col-md-12">
                                    <div class="checkbox checkbox-primary">
                                        <input style={{ marginLeft: "5px" }} required id="checkbox2" type="checkbox" checked="checked" />
                                        <label for="checkbox2">
                                            Reminder <span style={{ color: "red" }}>*</span>
                                        </label>
                                    </div>
                                    <select name="reminder_before_time" required onChange={this.inputChange} value={this.state.reminder_before_time} class="form-control">
                                        <option value=" ">--Select Minutes--</option>
                                        <option value="5">05 min</option>
                                        <option value="10">10 min</option>
                                        <option value="15">15 min</option>
                                        <option value="20">20 min</option>
                                        <option value="25">25 min</option>
                                        <option value="30">30 min</option>
                                    </select>
                                    <small style={{ color: "green" }}>Before the task.</small>

                                </div>


                                <div class="col-md-12 form-group required">
                                    <div class="form-group" style={{ marginTop: "1em" }}>
                                        <label for="">Comments <span style={{ color: "red" }}>*</span></label>
                                        <textarea name="comment" value={this.state.comment} onChange={this.inputChange} required="required" class="form-control" rows="5" id="example-textarea-input"></textarea>
                                    </div>
                                </div>

                            </div>


                        </Modal.Body>
                        <Modal.Footer>

                            <button type="submit" class="btn btn-primary pull-right">Submit </button>
                            <button type="button" onClick={this.modalToggle} className="btn btn-danger pull-right" style={{ marginLeft: "8px" }} data-dismiss="modal">Close</button>
                        </Modal.Footer>
                    </form>
                </Modal>


                
                <Modal {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered style={{opacity:"1"}} show={this.state.showSearchModal} onHide={this.searchToggle}>
                    <Modal.Header closeButton>
                        <Modal.Title>Leads : Search</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={this.handelSearchSubmit}>
                    <Modal.Body >
            
                    <div class="form-group">
                
                    <div className="row">
                    <div className="col-md-6">
                    <label for="status">Search:</label>
                    
                    <input type="text" name="advSearchIndex" value={this.state.advSearchIndex}
                    onChange={this.inputChange} class="form-control eml" placeholder="Search...." />
                    </div>
                    <div className="col-md-6">
                   
                                        <label for="field-2" class="control-label">By State <font color="red">*</font></label>
                                        <select class="select2 form-control" 
                                            name="state_province" value={this.state.state_province} onChange={this.inputChange}
                                            data-placeholder="Choose a State..."  required>
                                            <option value=" " >--Select--</option>
                                            {this.state.states !== null
                                                ? this.state.states.map(state => (
                                                    <option value={state.state_id}>{state.state_name}</option>
                                                ))
                                                :
                                                null
                                            }


                                        </select>
                                        </div>
                   
                    </div>
                    
                    </div>
                   
                   
         
                    </Modal.Body>
                    <Modal.Footer>
                   
                    <button type="submit" class="btn btn-primary pull-right">Submit </button>
                    <button type="button"   onClick={this.searchToggle} className="btn btn-danger pull-right" style={{marginLeft:"8px"}} data-dismiss="modal">Close</button>
                    </Modal.Footer>
                    </form>
                </Modal>
             
            </div>

        )
    }
}
export default withRouter(Tellecalling);