/* eslint-disable*/
import jwtDecode from 'jwt-decode';
import { BASE_URL } from '../../../helpers/Constants';
import { encrypt, /*decrypt8*/ } from '../../../helpers/DataEncoder';
import { takeEvery, fork, put, all } from 'redux-saga/effects';
import { LOGIN, LOGOUT } from './actionTypes';
import { loginSuccess, logoutSuccess, authApiError } from './actions';

function* login(data) {
    const response = yield fetch(BASE_URL+"/api/login", {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        },
        body: JSON.stringify({
            username: data.payload.username,
            password: data.payload.password
        //      email: encrypt(data.payload.email),
        //   password: encrypt(data.payload.password)
        })
    })
    .then(res => res.json())
    .catch(error => ({
        status: "error", 
        errors: { message: "Server not responding" } 
    }));
    
    if(response.status === "success")
    {
        //var decodedToken = jwtDecode(response.auth_user_record.token); // jwtDecode(decrypt(response.auth_user_record.token));

        // let permissions = [];
        // decodedToken.user.role.role_with_assigned_permissions.map(permission => {
        //    return permissions.push(permission.alloted_permissions);
        // });
        

        // this.props.history.push('/dashboard');
        yield put(loginSuccess({
            user_data:response.data,
            token: response.data.original.access_token,
            // id:response.data.original.user.id,
            // name:response.data.original.user.name,
            // email:response.data.original.user.email,
            // mobile:response.data.original.user.mobile,
            // role_name:response.data.original.user.role,
            // user_group_name: decodedToken.user.role.user_group.name,
            // account_status: decodedToken.user.account_status,
            // assigned_permissions: permissions
        }));
        data.payload.history.push('/dashboard');
    }
    else if(response.status === "error")
    {
        
        yield put(authApiError(response.errors.message));                               
    }
}

function* logout(data) {
    try 
    {
        const response = yield fetch(BASE_URL+"/api/logout", {
            method: 'POST',
            headers: {
                "Authorization": "Bearer "+data.payload.auth_token,
               
            }
        })
        .then(res => res.json())
        .catch(error => ({ 
            status: "error", 
            errors: { message: "Server not responding" } 
        }));

        if(response.status === "success")
        {
            yield put(logoutSuccess());
            data.payload.history.push('/');
        }
        else if(response.status === "error")
        {    yield put(logoutSuccess());
            data.payload.history.push('/');
            yield put(authApiError(response.errors.message));
        }
    } 
    catch (error) 
    {
        yield put(logoutSuccess());
            data.payload.history.push('/');
        yield put(authApiError(error));
    }
}

export function* watchLogin() {
    yield takeEvery(LOGIN, login)
}

export function* watchLogout() {
    yield takeEvery(LOGOUT, logout)
}

function* authSaga() {
    yield all([
        fork(watchLogin),
        fork(watchLogout),
    ]);
}

export default authSaga;