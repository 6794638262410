import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
class ErrorAlert extends Component {
    componentWillMount() {
        this.timer = window.setInterval(()=>{
          this.props.history.replace({
          state:{}
          })
        },5000)
      }
    render() {
        
        return (
            <div class="row col-md-12 container-fluid" style={{width:"0%"}}>
                <div class="col-md-9"></div>

                <div class="col-md-3 pull-right">
               
                            <div class="alert alert-success" role="alert" style={{backgroundColor:"#e86565", color:"white", width:"auto",height:"auto",zIndex:"+99"
                            ,display:"inline-block",position:"fixed",marginLeft:"1148px"}}>
                     <i className="mdi mdi-alert-octagon" style={{ fontSize: "30px" }} ></i> &nbsp;&nbsp;&nbsp; {this.props.message}
                     <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true" style={{ fontSize: "20px", color: "golden" }}>×</span>
                     </button>    <strong style={{ color: "#dbf3ff" }}>&nbsp;&nbsp; </strong><span style={{ color: "white" }}> </span>


                </div>
       
           
            </div> </div>


            
        );
    }
}

export default withRouter(ErrorAlert);