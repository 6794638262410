/* eslint-disable*/
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { BASE_URL } from '../../../helpers/Constants';
import './page.css';
import add_org from './add_org';
import jwtDecode from 'jwt-decode';
import SuccessAlert from './SuccessAlert';
import { Link } from 'react-router-dom'
import { FaBeer, FaEye, FaChevronCircleRight } from 'react-icons/fa';
import Pagination from "react-js-pagination";


function serachingfor(term) {
  var search;

  return function (x) {
      return (x.org_name || '').toLowerCase().includes(term.toLowerCase()) || !term
      || (x.org_code || '').toLowerCase().includes(term.toLowerCase()) || !term 
      || (x.contact_no || '').toLowerCase().includes(term.toLowerCase()) || !term
      || (x.email || '').toLowerCase().includes(term.toLowerCase()) || !term
      || (x.website || '').toLowerCase().includes(term.toLowerCase()) || !term;
    
  }
}




class organisations extends Component {
  constructor(props) {
    super(props)
    const headers = {
      headers: {
      'Content-Type': 'application/json',
      'Authorization':"Bearer "+this.props.auth_user.user_data.original.access_token
      }
      }
    this.state = {
      orgs: [],
      term: '',
      activePage: 1,
      itemsCountPerPage: 1,
      totalItemsCount: 1,
      pageRangeDisplayed: 3,
      headers:headers
    }
    this.serachHandeler = this.serachHandeler.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.inputChange = this.inputChange.bind(this);
   
  }
 

 

  serachHandeler(event) {
    this.setState({ term: event.target.value })

  }

  handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    this.setState({ activePage: pageNumber });

    axios.get(BASE_URL + '/api/orglist?page=' + pageNumber,this.state.headers)
      .then(response => {
        this.setState({
          orgs: response.data.data,
          itemsCountPerPage: response.data.per_page,
          totalItemsCount: response.data.total,
          activePage: response.data.current_page
        })
      });
  }
  inputChange(e) {
    console.log("input type working");
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  



  componentDidMount() {
    console.log('callled orgs ');
    axios.get(BASE_URL+'/api/orglist',this.state.headers)
      .then(response => {
        this.setState({
          orgs: response.data.data,
          itemsCountPerPage:response.data.per_page,
          totalItemsCount:response.data.total,
          activePage:response.data.current_page
        })
      }).catch(err => console.log(err));
  }
  
  render() {
   
    const {term,orgs}= this.state;
    return (
      <div>
      {this.props.location.state!=undefined ?
        [(this.props.location.state.status == true
         ? <SuccessAlert message= {this.props.location.state.msg} /> 
         : <p style={{ color: "#eceff1" }}> working</p>
       ),
       <p style={{ color: "#eceff1" }}> working</p>
    ]
             
         
        :
     //    <p> not working</p>
     null
     }
       
     <div class="row">
     <div class="col-sm-12" id="breadcrum" style={{marginTop:"-23px",marginBottom:"0px",borderBottom:"1px dashed #b9b9b9 "}}>
         <h4 class="pull-left page-title" style={{lineHeight:"48px"}}><i class="fa fa-users" aria-hidden="true"></i>&nbsp;&nbsp;Organizations</h4>
         <ol class="breadcrumb pull-right">
             
         <li><Link to={"/Organization"} style={{ color: "#007bff", fontSize: "15px", scrollMarginBottom: "10px" }} className="pull-right active" >Organization Info</Link></li>

         <li><Link to={"/Organization"} style={{ fontSize: "15px", scrollMarginBottom: "10px" }} className="pull-right">Home</Link></li>

         </ol>
            </div>
            </div> 
        <div className="card">
         
          <form onSubmit={this.handelSearchSubmit}>
              <Row>
                <Col md={2}>
              <Link to="/Add-Organisation"  id="left" className="btn btn-primary" style={{ marginLeft: "8px" }}>Add <i className="mdi mdi-plus-circle-outline"></i> </Link>
            </Col>
           <add_org/>
                <Col md={8}>
                  
                  </Col>
                  <Col md={2}>
                  <form>
                  <input type="text" name="" className="form-control"
                      onChange={this.serachHandeler}
                      placeholder="Search....." value={term} />
                  </form>
                </Col>
                

              </Row>
            </form>


          <div className="card-body">
            <table class="table table-hover table-striped table-bordered">
              <thead>
                <tr>
                <th style={{width:"54px",textAlign:"right"}} >Sl no.</th>
                  <th>Organization Name</th>
                  <th>Organization Code</th>
                  <th style={{width:"54px",textAlign:"right"}}>Phone No.</th>
                  <th>Email</th>
                  <th>Website</th>
                  <th style={{width:"65px"}}></th>
                 
                </tr>
              </thead>
              <tbody>
                {orgs !== null
                  ? orgs.filter(serachingfor(term)).map((org,index) => (
                    <tr key={org.org_id}>
                    {/*<td><img src={org.photo} class="picture-src" style={{ width: "100px" }}
                  id="e_logo" title="" /></td>*/}
                  <td style={{width:"54px",textAlign:"right"}}>{++index}.</td>
                      <td style={{ color: '#007bff',fontSize:"15px" }}><Link to={`/Organization/${org.id}/view`} style={{ color: "#007bff", scrollMarginBottom: "15px" }}>{org.org_name}</Link></td>
                      <td  style={{ fontSize:"15px"}}>{org.org_code}</td>
                      <td  style={{ fontSize:"15px",textAlign:"right"  }}>{org.contact_no}</td>
                      <td  style={{ fontSize:"15px" }}>{org.email}</td>
                      <td  style={{ fontSize:"15px" }}>{org.website}</td>
                                           
                      <td>
                        <div className="row">
                        <div className="col">
                          
                            <Link to={`/Organization/${org.id}/edit`} style={{ color: "#007bff", fontSize: "15px", scrollMarginBottom: "15px" }}><i className=" mdi mdi-pencil"></i></Link>
                          
                          
                            <Link to={`/Organization/${org.id}/view`} style={{ color: "#007bff", fontSize: "15px", scrollMarginBottom: "15px" }}><i className=" mdi mdi-eye"></i></Link>
                         
                          
                          <Link to="" style={{ color: "red", fontSize: "15px", scrollMarginBottom: "15px" }}><i className=" mdi mdi-delete"></i></Link>
                          </div>

                        </div>
                      </td>
                    </tr>

                  ))
                  :
                  null
                }


              </tbody>
            </table>

            <div className="pagination justify-content-left">
              <Pagination
                activePage={this.state.activePage}
                itemsCountPerPage={this.state.itemsCountPerPage}
                totalItemsCount={this.state.totalItemsCount}
                pageRangeDisplayed={this.state.pageRangeDisplayed}
                onChange={this.handlePageChange}
                itemClass='page-item'
                linkClass='page-link'
              />
            </div>
          </div>
        </div>
      </div>

    )
  }
}
export default withRouter(organisations);

