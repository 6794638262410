import { LOGIN, LOGIN_SUCCESS, LOGOUT, LOGOUT_SUCCESS, AUTH_API_ERROR } from './actionTypes';

export const login = data => {
    return {
        type: LOGIN,
        payload: data
    }
}

export const loginSuccess = data => {
    return {
        type: LOGIN_SUCCESS,
        payload: data
    }
}

export const logout = data => {
    return {
        type: LOGOUT,
        payload: data
    }
}

export const logoutSuccess = () => {
    return {
        type: LOGOUT_SUCCESS,
        payload: null
    }
}

export const authApiError = error => {
    return {
        type: AUTH_API_ERROR,
        payload: error
    }
}