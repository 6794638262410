import { combineReducers } from  'redux';
import forgotPasswordEmailReducer from './data/forgot-password/reducer';
import authReducer from './data/auth/reducer';


const rootReducer = combineReducers({
  forgotPasswordEmailReducer,
  authReducer
});
  
export default rootReducer;