import React from 'react';
import axios from 'axios';
import { BASE_URL } from '../../../../helpers/Constants';

class Dashboard extends React.PureComponent {
    constructor(props) {
      
        super(props)
       
        const headers = {
            headers: {
            'Content-Type': 'application/json',
            'Authorization':"Bearer "+this.props.auth_user.user_data.original.access_token
            }
            }
           
        this.state = {
              dashboard_data:[],
                TotalLead: '',
                TotalContact:'',
                TotalDemo:'',
                 Closure:'',
                 org_id: this.props.auth_user.user_data.original.user.org_id,
        }
    }
    componentDidMount() {
        document.title = "Dashboard :: BABA SALSE";
        console.log(this.props.auth_user.user_data.original.user.org_id)
        axios.post(BASE_URL + '/api/dashboard/GetAllDashboarData',{
        org_id:this.state.org_id,
        },this.state.headers)
        .then(response => {
               this.setState({
                TotalLead: response.data.data.TotalLead,
                TotalContact:response.data.data.TotalContact,
                TotalDemo:response.data.data.TotalDemo,
                Closure:response.data.data.Closure,
              
            })
        }).catch(err => console.log(err));
    }

    render() {
        return (
            <div>
           
            <div className="page-content-wrapper">
                <div className="container-fluid">
                    <div className="row">
                    <div className="col-lg-12" id="breadcrum" style={{marginTop: "-4px"}}>
                    
                      <img src="https://image.flaticon.com/icons/svg/1035/1035850.svg" className="pull-left" style={{height:"40px"}}/>
                      <h4  className="pull-left" style={{marginLeft:"10px",lineHeight:"0px"}}>Dashboard</h4>
                     </div>
                     
                        <div className="col-md-6 col-xl-3" style={{paddingLeft:"4px",paddingRight:"5px"}}>
                            <div className="mini-stat clearfix bg-white">
                                <span className="mini-stat-icon bg-blue-grey  mr-0 float-right"><i className=" mdi mdi-account-multiple"></i></span>
                                <div className="mini-stat-info">
                                    <span className="counter text-purple">{this.state.TotalLead}</span>
                                    Total Leads
                                </div>
                               
                                
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3" style={{paddingLeft:"4px",paddingRight:"5px"}}>
                            <div className="mini-stat clearfix bg-white">
                                <span className="mini-stat-icon bg-blue-grey mr-0 float-right"><i className="mdi mdi-account-box "></i></span>
                                <div className="mini-stat-info">
                                    <span className="counter text-blue-grey">{this.state.TotalContact}</span>
                                   Total Contacts
                                </div>
                               
                               
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3" style={{paddingLeft:"4px",paddingRight:"5px"}}>
                            <div className="mini-stat clearfix bg-white">
                                <span className="mini-stat-icon bg-blue-grey  mr-0 float-right"><i className="mdi mdi-phone-log"></i></span>
                                <div className="mini-stat-info">
                                    <span className="counter text-brown">{this.state.TotalDemo}</span>
                                    Total Demo
                                </div>
                               
                               
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3" style={{paddingLeft:"4px",paddingRight:"5px"}}>
                            <div className="mini-stat clearfix bg-white">
                                <span className="mini-stat-icon bg-blue-grey   mr-0 float-right"><i className="mdi mdi-thumb-up"></i></span>
                                <div className="mini-stat-info">
                                    <span className="counter text-teal">{this.state.Closure}</span>
                                   Total Closure
                                </div>
                               
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        );
    }
};

export default Dashboard;