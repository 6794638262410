/* eslint-disable*/
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { BASE_URL } from '../../../helpers/Constants';
import './page.css';
import add_org from './add_org';
import jwtDecode from 'jwt-decode';
import SuccessAlert from './SuccessAlert';
import { Link } from 'react-router-dom'
import { FaBeer, FaEye, FaChevronCircleRight } from 'react-icons/fa';
import Pagination from "react-js-pagination";

function serachingfor(term) {
  var search;

  return function (x) {
    return (x.name || '').toLowerCase().includes(term.toLowerCase()) || !term
      || (x.email || '').toLowerCase().includes(term.toLowerCase()) || !term


  }
}

class teamMember extends Component {
  constructor(props) {
    super(props)
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.props.auth_user.user_data.original.access_token
      }
    }
    this.msg = '';
    this.state = {
      users: [],
      term: '',
      activePage: 1,
      itemsCountPerPage: 1,
      totalItemsCount: 1,
      pageRangeDisplayed: 3,
      headers: headers,
    }
    this.serachHandeler = this.serachHandeler.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.inputChange = this.inputChange.bind(this);

  }



  serachHandeler(event) {
    this.setState({ term: event.target.value })

  }

  handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`, this.state.headers);
    this.setState({ activePage: pageNumber });

    axios.get(BASE_URL + '/api/sales_people?page=' + pageNumber, this.state.headers)
      .then(response => {
        this.setState({
          users: response.data.data,
          itemsCountPerPage: response.data.per_page,
          totalItemsCount: response.data.total,
          activePage: response.data.current_page
        })
      });
  }
  inputChange(e) {
    console.log("input type working");
    this.setState({
      [e.target.name]: e.target.value
    });
  }




  componentDidMount() {
    console.log('callled orgs ');

    const headers = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.props.auth_user.user_data.original.access_token
      }
    }
    // axios.get(BASE_URL + '/api/leadsByStatus?lead_status=' + this.state.lead_status + '&lead_owner=' + this.state.lead_owner + '&page=' + pageNumber, this.state.headers)
    axios.post(BASE_URL + '/api/users/fetch_team_member_by_team_head',
      {
        org_id: this.props.auth_user.user_data.original.user.org_id,
        user_id: this.props.auth_user.user_data.original.user.id, name: this.props.auth_user.user_data.original.user.username
      }, headers)
      .then(response => {
        this.setState({
          users: response.data,
          itemsCountPerPage: response.data.per_page,
          totalItemsCount: response.data.total,
          activePage: response.data.current_page,
          response_status: response.data.status,
          response_message: response.data.message
        })
      }).catch(err => console.log(err));
  }

  //   componentWillMount() {
  //     if (this.props.location.state && this.props.location.state.msg) {
  //         this.msg = this.props.location.state.msg;
  //         this.props.history.replace({
  //             pathname: this.props.location.pathname,
  //             state: {}
  //         });
  //     }
  // }

  render() {

    const { term, users } = this.state;
    return (
      <div style={{ marginTop: "30px" }}>
        {this.props.location.state != undefined ?
          [(this.props.location.state.status == true
            ? <SuccessAlert message={this.props.location.state.msg} />
            : <p style={{ color: "#eceff1" }}> working </p>
          ),
          <p style={{ color: "#eceff1" }}> working </p>
          ]
          :
          //    <p> not working</p>
          null
        }

        <add_org />
        <div class="row">
          <div class="col-sm-12" id="breadcrum" style={{ marginTop: "-51px", marginBottom: "0px", borderBottom: "1px dashed #b9b9b9 " }}>
            <h4 class="pull-left page-title" style={{ lineHeight: "48px" }}><i class="fa fa-users" aria-hidden="true"></i>&nbsp;&nbsp;Teams</h4>
            <ol class="breadcrumb pull-right">

              <li><Link to={"/team-members"} style={{ color: "#007bff", fontSize: "15px", scrollMarginBottom: "10px" }} className="pull-right active" >Teams </Link></li>
              <li><Link to={"/"} style={{ fontSize: "15px", scrollMarginBottom: "10px" }} className="pull-right">Home</Link></li>

            </ol>
          </div>
        </div>
        <div className="card">




          <div className="card-body">
            <form onSubmit={this.handelSearchSubmit}>
              <Row style={{ marginTop: "-1em" }}>
                <Col md={6}></Col>
                <Col md={6}>

                  <Link to="/add-team-members" className="btn btn-primary pull-right" style={{ marginLeft: "5px" }} >Add <i className="mdi mdi-plus-circle-outline"></i> </Link>
                  <form>
                    <input type="text" name="" className="form-control pull-right"
                      onChange={this.serachHandeler}
                      placeholder="Search....." value={term} style={{ width: "30%" }} />
                  </form>
                </Col>


              </Row>
            </form>
            <br />
            <table class="table table-hover table-striped table-bordered">
              <thead>
                <tr>
                <th style={{ width: "74px",textAlign:"right" }}>Sl No.</th>
                  <th>Image</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Designation</th>
                  <th style={{ width: "35px" }}>Ph No.</th>
                  <th style={{ width: "30px" }}>Create Date</th>
                  <th className="bg-white" style={{ backgroundColor: "white !important", width: "66px" }} ></th>

                </tr>
              </thead>
              <tbody>
                {users !== null
                  ? users.filter(serachingfor(term)).map((user, index) => (
                    <tr key={user.id}>
                      <td style={{ textAlign: "right", width: "54px" }}>{++index}.</td>
                      <td><img src={user.profile_img} class="picture-src" style={{ width: "50px", height: "30px" }}
                        id="e_logo" title="" /></td>

                      <td>{user.name}</td>
                      {/* <td>{user.users_role}</td> */}
                      <td>{user.email}</td>
                      <td>{user.designation_name}</td>
                      
                      <td style={{ textAlign: "right" }}>{user.phone}</td>
                      <td style={{ textAlign: "right" }}>{user.date_created}</td>
                      <td>
                        <div className="row">
                          <div className="col-md-2">
                            <Link to={`/edit-team-members/${user.id}`} style={{ color: "#007bff", fontSize: "15px", scrollMarginBottom: "10px" }}><i className=" mdi mdi-pencil"></i></Link>
                          </div>
                          {/*<div className="col-md-2">
                            <Link to={`/team/${user.id}/view`} style={{ color: "#007bff", fontSize: "15px", scrollMarginBottom: "10px" }}><i className=" mdi mdi-eye"></i></Link>
                          </div>
                          <div className="col-md-2">
                          <Link to={`/team/${user.id}/delete`} style={{ color: "#007bff", fontSize: "15px", scrollMarginBottom: "10px" }}><i className=" mdi mdi-delete"></i></Link>
                        </div>*/}

                        </div>
                      </td>
                    </tr>

                  ))
                  :
                  null
                }


              </tbody>
            </table>

            <div className="pagination justify-content-left">
              <Pagination
                activePage={this.state.activePage}
                itemsCountPerPage={this.state.itemsCountPerPage}
                totalItemsCount={this.state.totalItemsCount}
                pageRangeDisplayed={this.state.pageRangeDisplayed}
                onChange={this.handlePageChange}
                itemClass='page-item'
                linkClass='page-link'
              />
            </div>
          </div>
        </div>
      </div>

    )
  }
}
export default withRouter(teamMember);

