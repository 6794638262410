/* eslint-disable*/
import React, { Component } from 'react'
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import { Link, withRouter } from 'react-router-dom'
import SuccessAlert from './SuccessAlert';
import { BASE_URL } from '../../../helpers/Constants';
import axios from 'axios';

import ErrorAlert from '../../auth/admin/ErrorAlert';


class Leadsedit extends Component {
    constructor(props) {
        super(props)

        this.state = {
            leads: [],
            countrys: [],
            users: [],
            citys: [], states: [],
            region: '',
            mobile: '',
            lead_status: '',
            lead_owner: '', title: '', first_name: '', last_name: '',
            address1: '', company_name: '', website: '', head_quater: '',
            apperance_in_country: '', email: '', fax: '', city_distt: '',
            state_province: '', pincode: '', country: '', n_o_employee: ''
            , lead_source: '', description: '', annual_revenue: '', rating: '', phone: '', industry: ''
        }
        this.inputChange = this.inputChange.bind(this);
        this.handelFormSubmit = this.handelFormSubmit.bind(this);
        this.getnewstate = this.getnewstate.bind(this);
        this.getnewcity = this.getnewcity.bind(this);
        this.getcity_id = this.getcity_id.bind(this);
        this.getonloadstate = this.getonloadstate.bind(this);
        this.getonloadcity = this.getonloadcity.bind(this);
    }

    inputChange(e) {
        console.log("input type working");
        this.setState({
            [e.target.name]: e.target.value
        });

    }
    getonloadstate(e) {
        const headers = {
            headers: {
            'Content-Type': 'application/json',
            'Authorization':"Bearer "+this.props.auth_user.user_data.original.access_token
            }
            }
        console.log("hello" + this.state.country)
        const getcountry_id = this.state.country;
        axios.get(BASE_URL + `/api/list_state/${getcountry_id}`,headers)
            .then(response => {
                this.setState({
                    states: response.data,


                })
            }).catch(err => console.log(err));


    }
    getonloadcity(e) {
        const headers = {
            headers: {
            'Content-Type': 'application/json',
            'Authorization':"Bearer "+this.props.auth_user.user_data.original.access_token
            }
            }
        console.log("hello city " + this.state.state_province)
        const getstate_id = this.state.state_province;
        axios.get(BASE_URL + `/api/list_cities/${getstate_id}`,headers)
            .then(response => {
                this.setState({
                    citys: response.data,

                })
            }).catch(err => console.log(err));

    }


    componentDidMount() {
        const id = this.props.match.params.id
        console.log(id);
        console.log('edit page working');
        const headers = {
            headers: {
            'Content-Type': 'application/json',
            'Authorization':"Bearer "+this.props.auth_user.user_data.original.access_token
            }
            }
        axios.get(BASE_URL + `/api/leads/${id}`,headers).then(response => {
            this.setState({
                first_name: response.data.first_name,
                last_name: response.data.last_name,
                email: response.data.email,
                mobile: response.data.mobile,
                lead_status: response.data.lead_status,
                phone: response.data.phone,
                website: response.data.website,
                company_name: response.data.company_name,
                n_o_employee: response.data.n_o_employee,
                fax: response.data.fax,
                lead_status: response.data.lead_status,
                lead_owner: response.data.lead_owner,
                annual_revenue: response.data.annual_revenue,
                rating: response.data.rating,
                lead_source: response.data.lead_source,
                industry: response.data.industry,
                linkedIn_id: response.data.linkedIn_id,
                linkedIn_url: response.data.linkedIn_url,
                address1: response.data.address1,
                city_distt: response.data.city_distt,
                head_quater: response.data.head_quater,
                apperance_in_country: response.data.apperance_in_country,
                state_province: response.data.state_province,
                pincode: response.data.pincode,
                country: response.data.country,
                description: response.data.description,
                title: response.data.title,
            })
            this.getonloadstate();
            this.getonloadcity();
        }).catch(err => console.log(err));

        axios.get(BASE_URL + '/api/users/list_sale_people_in_for_assign',headers)
            .then(response => {
                this.setState({
                    users: response.data
                })
            }).catch(err => console.log(err));

        axios.get(BASE_URL + '/api/list_countries',headers)
            .then(response => {
                this.setState({
                    countrys: response.data,

                })
            }).catch(err => console.log(err));


    }
    getnewstate(e) {
        const headers = {
            headers: {
            'Content-Type': 'application/json',
            'Authorization':"Bearer "+this.props.auth_user.user_data.original.access_token
            }
            }
        this.setState({
            country: e.target.value

        })
        const country = e.target.value;
        console.log(country);
        axios.get(BASE_URL + `/api/list_state/${country}`,headers)
            .then(response => {
                this.setState({
                    states: response.data,


                })
            }).catch(err => console.log(err));

    }
    getnewcity(e) {
        const headers = {
            headers: {
            'Content-Type': 'application/json',
            'Authorization':"Bearer "+this.props.auth_user.user_data.original.access_token
            }
            }
        this.setState({
            state_province: e.target.value,

        })
        const state_province = e.target.value;
        console.log("state" + state_province);
        axios.get(BASE_URL + `/api/list_cities/${state_province}`,headers)
            .then(response => {
                this.setState({
                    citys: response.data,

                })
            }).catch(err => console.log(err));

    }
    getcity_id(e) {
        this.setState({

            city_distt: e.target.value

        })
    }


    handelFormSubmit(event) {
        const headers = {
            headers: {
            'Content-Type': 'application/json',
            'Authorization':"Bearer "+this.props.auth_user.user_data.original.access_token
            }
            }
        event.preventDefault();
        const leads = this.props.match.params.id
        console.log(leads);
        axios.put(BASE_URL + `/api/leads/${leads}`, {
            // name: this.state.name,
            // tel: this.state.tel
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            email: this.state.email,
            mobile: this.state.mobile,
            lead_status: this.state.lead_status,
            phone: this.state.phone,
            website: this.state.website,
            company_name: this.state.company_name,
            n_o_employee: this.state.n_o_employee,
            fax: this.state.fax,
            lead_status: this.state.lead_status,
            lead_owner: this.state.lead_owner,
            annual_revenue: this.state.annual_revenue,
            rating: this.state.rating,
            lead_source: this.state.lead_source,
            industry: this.state.industry,
            linkedIn_id: this.state.linkedIn_id,
            linkedIn_url: this.state.linkedIn_url,
            address1: this.state.address1,
            city_distt: this.state.city_distt,
            head_quater: this.state.head_quater,
            apperance_in_country: this.state.apperance_in_country,
            state_province: this.state.state_province,
            pincode: this.state.pincode,
            country: this.state.country,
            description: this.state.description,
            title: this.state.title,
        },headers).then(response => {

            this.setState({
                response_status: response.data.status,
                response_message: response.data.message

            })
            if (response.data.status == true) {

                this.props.history.push({ pathname: '/customers', state: { msg: response.data.message, status: response.data.status } });
            }
        }).catch(err => console.log(err));
    }

    render() {
        const { country, region } = this.state;
        return (
            <div>

                {this.state.response_status == false ?
                    <ErrorAlert message={this.state.response_message} /> : null}
                    <div class="row">
                    <div class="col-sm-12" id="breadcrum" style={{marginTop:"-20px",marginBottom:"0px",borderBottom:"1px dashed #b9b9b9 "}}>
                        <h4 class="pull-left page-title" style={{lineHeight:"48px"}}><i class="fa fa-users" aria-hidden="true"></i>&nbsp;&nbsp;Contacts : Edit</h4>
                        <ol class="breadcrumb pull-right">
                            
                        <li><Link to={`/customers`} style={{ color: "#007bff", fontSize: "15px", scrollMarginBottom: "10px" }} className="pull-right active" >Contact</Link></li>
                        <li><Link to={"/customers"} style={{ fontSize: "15px", scrollMarginBottom: "10px",color: "#007bff" }} className="pull-right">Edit</Link></li>
                        <li><Link to={"/customers"} style={{ fontSize: "15px", scrollMarginBottom: "10px" }} className="pull-right">Home</Link></li>
              
                        </ol>
                    </div>
                </div>
               
                
                {this.state.alert_message == "success" ? <SuccessAlert message={"Lead data Updated Successfully"} /> : null}
                <Card>
                  
                    <Card.Body style={{ marginTop: "-2em" }}>
                        <br />
                        <Form onSubmit={this.handelFormSubmit}>

                            <label style={{ marginTop: "5px" }}>Customer Status</label><br />
                            {this.state.lead_status == "Unqualified" ?
                                <label className="radio-inline" style={{ marginRight: "10px" }}>
                                    <input required type="radio" required
                                        name="lead_status" style={{ marginRight: "10px" }}
                                        value={this.state.lead_status}
                                        onChange={this.inputChange}
                                        label="Unqualified"
                                        value="Unqualified" checked />Unqualified
                           </label>
                                :
                                <label className="radio-inline" style={{ marginRight: "10px" }}>
                                    <input required type="radio" required
                                        name="lead_status" style={{ marginRight: "10px" }}
                                        value={this.state.lead_status}
                                        onChange={this.inputChange}
                                        label="Unqualified"
                                        value="Unqualified" />Unqualified
                          </label>
                            }

                            {this.state.lead_status == "new" ?
                                <label className="radio-inline" style={{ marginRight: "10px" }}>
                                    <input required type="radio"
                                        name="lead_status" style={{ marginRight: "10px" }}
                                        required
                                        value={this.state.lead_status}
                                        onChange={this.inputChange}
                                        label="New"
                                        value="new" checked />New
                                 </label>
                                :
                                <label className="radio-inline" style={{ marginRight: "10px" }}>
                                    <input required type="radio"
                                        name="lead_status" style={{ marginRight: "10px" }}
                                        required
                                        value={this.state.lead_status}
                                        onChange={this.inputChange}
                                        label="New"
                                        value="new" />New
                                      </label>
                            }
                            {this.state.lead_status == "Working" ?
                                <label className="radio-inline" style={{ marginRight: "10px" }}>
                                    <input required type="radio"
                                        name="lead_status" style={{ marginRight: "10px" }}
                                        required
                                        value={this.state.lead_status}
                                        onChange={this.inputChange}
                                        label="Working"
                                        value="Working" checked />Working
                                     </label>
                                :
                                <label className="radio-inline" style={{ marginRight: "10px" }}>
                                    <input required type="radio"
                                        name="lead_status" style={{ marginRight: "10px" }}
                                        required
                                        value={this.state.lead_status}
                                        onChange={this.inputChange}
                                        label="Working"
                                        value="Working" />Working
                          </label>
                            }

                            {this.state.lead_status == "Nurturing" ?
                                <label className="radio-inline" style={{ marginRight: "10px" }}>
                                    <input required type="radio"
                                        name="lead_status" style={{ marginRight: "10px" }}
                                        type="radio"
                                        required
                                        value={this.state.lead_status}
                                        onChange={this.inputChange}
                                        label="Nurturing"
                                        value="Nurturing" checked />Nurturing
                        </label>
                                :
                                <label className="radio-inline" style={{ marginRight: "10px" }}>
                                    <input required type="radio"
                                        name="lead_status" style={{ marginRight: "10px" }}
                                        type="radio"
                                        required
                                        value={this.state.lead_status}
                                        onChange={this.inputChange}
                                        label="Nurturing"
                                        value="Nurturing" />Nurturing
                               </label>
                            }
                            {this.state.lead_status == "qualified" ?
                                <label className="radio-inline" style={{ marginRight: "10px" }}>
                                    <input required type="radio"
                                        name="lead_status"
                                        style={{ marginRight: "10px" }}
                                        required
                                        value={this.state.lead_status}
                                        onChange={this.inputChange}
                                        label="Qualified"
                                        value="qualified" checked />Qualified
                       </label>
                                :
                                <label className="radio-inline" style={{ marginRight: "10px" }}>
                                    <input required type="radio"
                                        name="lead_status"
                                        style={{ marginRight: "10px" }}
                                        required
                                        value={this.state.lead_status}
                                        onChange={this.inputChange}
                                        label="Qualified"
                                        value="qualified" />Qualified
                  </label>
                            }


                            <Row>
                                <Col>
                                    <Form.Group controlId="exampleForm.SelectCustom">
                                        <Form.Label>Assign To*</Form.Label>

                                        <Form.Control
                                            required
                                            onChange={this.inputChange}
                                            value={this.state.lead_owner}
                                            as="select"
                                            name="lead_owner"

                                            custom>

                                            <option value=" " >--Select--</option>
                                            {this.state.users !== null
                                                ? this.state.users.map(user => (
                                                    <option value={user.id} selected={user.id == this.state.lead_owner}>{user.name}</option>
                                                ))
                                                :
                                                null
                                            }
                                        </Form.Control>

                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="exampleForm.SelectCustom">
                                        <Form.Label>Customer Title</Form.Label>
                                        <Form.Control
                                            required
                                            onChange={this.inputChange}
                                            value={this.state.title}
                                            name="title" as="select" custom>
                                            <option value=" " >-Select-</option>
                                            <option value="CEO" >CEO</option>
                                            <option value="VP">VP</option>
                                            <option value="Manager">Manager</option>
                                            <option value="Sales Manager" >Sales Manager</option>
                                            <option value="Principle">Principle</option>
                                            <option value="Teacher">Teacher</option>
                                            <option value="clerk">clerk</option>
                                            <option value="Receptionist">Receptionist</option>
                                            <option value="Managing Director">Managing Director</option>
                                            <option value="Founder">Founder</option>
                                        </Form.Control>
                                    </Form.Group></Col>
                            </Row>
                            <br />
                            <Form.Row>
                                <Form.Group as={Col} md="3" controlId="validationFormik03">
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control

                                        onChange={this.inputChange}
                                        value={this.state.first_name}
                                        type="text"
                                        placeholder="First Name"
                                        name="first_name"

                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="3" controlId="validationFormik03">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control

                                        onChange={this.inputChange}
                                        type="text"
                                        placeholder="Last Name"
                                        name="last_name"
                                        value={this.state.last_name}
                                    // value={values.city}
                                    // onChange={handleChange}
                                    // isInvalid={!!errors.city}
                                    />
                                </Form.Group>

                                <Form.Group

                                    as={Col} md="6" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control
                                        required
                                        onChange={this.inputChange}
                                        value={this.state.address1}
                                        as="textarea" rows="3"
                                        name="address1" />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="field-2" class="control-label">Country <font color="red">*</font></label>
                                        <select class="select2 form-control" onChange={this.getnewstate}
                                            name="country" required="" aria-required="true"
                                            data-placeholder="Choose a Country...">
                                            <option value="">--Select--</option>
                                            {this.state.countrys !== null
                                                ? this.state.countrys.map(country => (

                                                    <option key={country.country_id} value={country.country_id} selected={country.country_id == this.state.country} >{country.country_name}</option>
                                                ))
                                                :
                                                null
                                            }

                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="field-2" class="control-label">State <font color="red">*</font></label>
                                        <select class="select2 form-control"
                                            name="state_province" required="" onLoad={this.getonloadstate} aria-required="true" value={this.props.state_province} onChange={this.getnewcity}
                                            data-placeholder="Choose a State...">
                                            <option value=" " >--Select--</option>
                                            {this.state.states !== null
                                                ? this.state.states.map(state => (
                                                    <option value={state.state_id} selected={state.state_id == this.state.state_province}>{state.state_name}</option>
                                                ))
                                                :
                                                null
                                            }


                                        </select>
                                    </div>
                                </div>

                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="field-2" class="control-label">City <font color="red">*</font></label>
                                        <select class="select2 form-control"
                                            name="city_distt" onLoad={this.getonloadcity} id="city_distt" aria-required="true" onChange={this.getcity_id}
                                            data-placeholder="Choose a City...">
                                            <option>--Select--</option>
                                            {this.state.citys !== null
                                                ? this.state.citys.map(cit => (
                                                    <option value={cit.city_id} selected={cit.city_id == this.state.city_distt}>{cit.city_name}</option>
                                                ))
                                                :
                                                null
                                            }


                                        </select>
                                    </div>
                                </div>
                                <Form.Group
                                    as={Col} md="3" controlId="validationFormik03">
                                    <Form.Label>Pin Code</Form.Label>
                                    <Form.Control
                                        required
                                        value={this.state.pincode}
                                        onChange={this.inputChange}
                                        type="text"
                                        placeholder="Pincode."
                                        name="pincode"
                                    // value={values.city}
                                    // onChange={handleChange}
                                    // isInvalid={!!errors.city}
                                    />

                                </Form.Group>

                            </Form.Row>
                            <Form.Row>
                                <Form.Group

                                    as={Col} md="3" controlId="validationFormik03">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control

                                        onChange={this.inputChange}
                                        value={this.state.email}
                                        type="email"
                                        placeholder="abc@gmail.com"
                                        name="email"

                                    />
                                </Form.Group>

                                <Form.Group

                                    as={Col} md="3" controlId="validationFormik03">
                                    <Form.Label>Fax</Form.Label>
                                    <Form.Control

                                        onChange={this.inputChange}
                                        value={this.state.fax}
                                        type="text"
                                        placeholder="Fax No."
                                        name="fax"

                                    />
                                </Form.Group>

                                <Form.Group

                                    as={Col} md="3" controlId="validationFormik03">
                                    <Form.Label>Headquarter</Form.Label>
                                    <Form.Control
                                        type="text"

                                        onChange={this.inputChange}
                                        value={this.state.head_quater}
                                        placeholder="Headquarter"
                                        name="head_quater"
                                    // value={values.city}
                                    // onChange={handleChange}
                                    // isInvalid={!!errors.city}
                                    />
                                </Form.Group>

                                <Form.Group

                                    as={Col} md="3" controlId="validationFormik03">
                                    <Form.Label>Website</Form.Label>
                                    <Form.Control
                                        type="text"

                                        onChange={this.inputChange}
                                        placeholder="www.google.com"
                                        value={this.state.website}
                                        name="website"
                                    // value={values.city}
                                    // onChange={handleChange}
                                    // isInvalid={!!errors.city}
                                    />
                                </Form.Group>


                            </Form.Row>
                            <Form.Row>
                                <Form.Group

                                    as={Col} md="3" controlId="validationFormik03">
                                    <Form.Label>Mobile Number</Form.Label>
                                    <Form.Control

                                        onChange={this.inputChange}
                                        value={this.state.mobile}
                                        type="text"
                                        placeholder="Mobile Number"
                                        name="mobile"
                                    // value={values.city}
                                    // onChange={handleChange}
                                    // isInvalid={!!errors.city}
                                    />
                                </Form.Group>

                                <Form.Group

                                    as={Col} md="3" controlId="validationFormik03">
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control

                                        onChange={this.inputChange}
                                        value={this.state.phone}
                                        type="text"
                                        placeholder="Phone No."
                                        name="phone"
                                    // value={values.city}
                                    // onChange={handleChange}
                                    // isInvalid={!!errors.city}
                                    />
                                </Form.Group>

                                <Form.Group

                                    as={Col} md="3" controlId="validationFormik03">
                                    <Form.Label>Appearance in country</Form.Label>
                                    <Form.Control
                                        type="text"

                                        onChange={this.inputChange}
                                        placeholder="Appearance in country"
                                        name="apperance_in_country"
                                        value={this.state.apperance_in_country}
                                    // value={values.city}
                                    // onChange={handleChange}
                                    // isInvalid={!!errors.city}
                                    />
                                </Form.Group>

                                <Form.Group

                                    as={Col} md="3" controlId="validationFormik03">
                                    <Form.Label>Company</Form.Label>
                                    <Form.Control

                                        onChange={this.inputChange}
                                        type="text"
                                        placeholder="Company Name"
                                        value={this.state.company_name}
                                        name="company_name"
                                    // value={values.city}
                                    // onChange={handleChange}
                                    // isInvalid={!!errors.city}
                                    />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group

                                    as={Col} md="3" controlId="validationFormik03">
                                    <Form.Label>No. of Employee</Form.Label>
                                    <Form.Control

                                        onChange={this.inputChange}
                                        value={this.state.n_o_employee}
                                        type="number"
                                        placeholder="NOP"
                                        name="n_o_employee"
                                    // value={values.city}
                                    // onChange={handleChange}
                                    // isInvalid={!!errors.city}
                                    />
                                </Form.Group>

                                <Form.Group

                                    as={Col} md="3" controlId="validationFormik03">
                                    <Form.Label>Lead Source</Form.Label>
                                    <Form.Control required
                                        onChange={this.inputChange}
                                        value={this.state.lead_source} name="lead_source" as="select" custom>
                                        <option value="">Choose</option>
                                        <option value="LinkedIn Id">LinkedIn Id</option>
                                        <option value="Google adword">Google adword</option>
                                        <option value="Partner">Partner</option>
                                        <option value="Employee">Employee</option>
                                        <option value="other">other</option>
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group

                                    as={Col} md="6" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        onChange={this.inputChange}
                                        value={this.state.description} name="description" as="textarea" rows="3" placeholder="Description" />
                                </Form.Group>

                            </Form.Row>
                            <Form.Row>
                                <Form.Group

                                    as={Col} md="3" controlId="validationFormik03">
                                    <Form.Label>Industry / Domain</Form.Label>
                                    <Form.Control
                                        onChange={this.inputChange}
                                        value={this.state.industry} name="industry" as="select" custom>
                                        <option value="">Choose</option>
                                        <option value="LinkedIn Id">Education</option>
                                        <option value="Google adword">Retail</option>
                                        <option value="Partner">Health care</option>
                                        <option value="Employee">Banking</option>
                                        <option value="other">Steel</option>
                                        <option value="other">Finance</option>
                                        <option value="other">Telecommunication</option>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group

                                    as={Col} md="3" controlId="validationFormik03">
                                    <Form.Label>Annual Revenue</Form.Label>
                                    <Form.Control

                                        onChange={this.inputChange}
                                        value={this.state.annual_revenue}
                                        type="text"
                                        placeholder="abc@gmail.com"
                                        name="annual_revenue"
                                    // value={values.city}
                                    // onChange={handleChange}
                                    // isInvalid={!!errors.city}
                                    />
                                </Form.Group>




                                <div className="col-md-6">

                                    <label>Rating</label><br />
                                    {this.state.rating == "Cold" ?
                                        <label className="radio-inline" style={{ marginRight: "10px" }}>
                                            <input type="radio"
                                                style={{ marginRight: "10px" }}

                                                onChange={this.inputChange}
                                                value={this.state.rating}
                                                type="radio"
                                                name="rating"
                                                value="Cold" checked />Cold
                             </label>
                                        :
                                        <label className="radio-inline" style={{ marginRight: "10px" }}>
                                            <input type="radio"
                                                style={{ marginRight: "10px" }}

                                                onChange={this.inputChange}
                                                value={this.state.rating}
                                                type="radio"
                                                name="rating"
                                                value="Cold" />Cold
                             </label>
                                    }
                                    {this.state.rating == "Hot" ?
                                        <label className="radio-inline" style={{ marginRight: "10px" }}>
                                            <input type="radio"
                                                style={{ marginRight: "10px" }}

                                                onChange={this.inputChange}
                                                value={this.state.rating}
                                                type="radio"
                                                name="rating"
                                                value="Hot" checked />Hot
                               </label>
                                        :
                                        <label className="radio-inline" style={{ marginRight: "10px" }}>
                                            <input type="radio"
                                                style={{ marginRight: "10px" }}

                                                onChange={this.inputChange}
                                                value={this.state.rating}
                                                type="radio"
                                                name="rating"
                                                value="Hot" />Hot
                          </label>
                                    }
                                    {this.state.rating == "warm" ?
                                        <label className="radio-inline" style={{ marginRight: "10px" }}>
                                            <input type="radio"
                                                style={{ marginRight: "10px" }}

                                                value={this.state.rating}
                                                onChange={this.inputChange}
                                                type="radio"

                                                name="rating"
                                                value="warm" checked />Warm
                             </label>
                                        :
                                        <label className="radio-inline" style={{ marginRight: "10px" }}>
                                            <input type="radio"
                                                style={{ marginRight: "10px" }}

                                                value={this.state.rating}
                                                onChange={this.inputChange}
                                                type="radio"

                                                name="rating"
                                                value="warm" />Warm
                             </label>
                                    }
                                </div>
                            </Form.Row>
                            <hr />
                            <Form.Row>
                                <Col md="11" >

                                </Col>
                                <Col md="1">
                                    <Button variant="primary"
                                        type="submit">
                                        Update
                                    </Button>
                                </Col>
                                <Col md="1">

                                </Col>
                            </Form.Row>

                        </Form>


                    </Card.Body>
                </Card>

            </div>
        )
    }
}
export default withRouter(Leadsedit);
