import React from 'react';
import { Link } from "react-router-dom";

class Error404 extends React.PureComponent {
    componentDidMount() {
        document.title = "404 :: Skill360";
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content-wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="page-title-box"></div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 offset-md-3">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="ex-page-content text-center">
                                            <h1>404!</h1>
                                            <h3>Sorry, page not found</h3><br></br>
                                            <Link  className="btn btn-primary mb-5 waves-effect waves-light" to="/">Back</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </React.Fragment>
        );
    }
};

export default Error404;