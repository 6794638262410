import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../../../../helpers/Constants';
import SuccessAlert from '../SuccessAlert';
import ErrorAlert from '../ErrorAlert';

class lead_status_setting extends Component {
    constructor(props) {
        super(props)
        const headers = {
            headers: {
            'Content-Type': 'application/json',
            'Authorization':"Bearer "+this.props.auth_user.user_data.original.access_token
            }
            }
        const user_id= this.props.auth_user.user_data.original.user.id;
        console.log(user_id);
        this.state = {
       status:'',
     
        leads_statuses:[],
        headers:headers,
          lead_status:'',
          user_id:this.props.auth_user.user_data.original.user.id,
       

         }
        this.inputChange = this.inputChange.bind(this);
        this.handelFormSubmit = this.handelFormSubmit.bind(this);
    }
    inputChange(e) {
        console.log("input type working");
               this.setState({
          [e.target.name]: e.target.value,
         
        });
    }
    handelFormSubmit(event) {
        
         event.preventDefault();
         axios.post(BASE_URL + '/api/LeadStatus/addLeadStatus', {
          lead_status:this.state.lead_status,
          user_id:this.props.auth_user.user_data.original.user.id,
          message: this.state.message,
          status: this.state.status,  

            },this.state.headers).then(response => {
                console.log(response.data);
                   this.setState({
                    response_status: response.data.status,
                      response_message: response.data.message
                })
                if(response.data.status==true)
                {
                    window.location.reload();
                  this.props.history.push({pathname :'/add-lead-status', state :{msg:response.data.message,status:response.data.status}});
                  
                }
               }).catch(err => console.log(err));
          }

          componentDidMount() {
              
            axios.get(BASE_URL + '/api/LeadStatus/listAllLeadStatus',this.state.headers)
                .then(response => {
                    this.setState({
                        leads_statuses: response.data.data,
                        
                    })
                }).catch(err => console.log(err));
    
            }
            
           

    render() {
        return (
          
            <div>
            
            {this.props.location.state!=undefined ?
                [(this.props.location.state.status == true
                 ? <SuccessAlert message= {this.props.location.state.msg} /> 
                 : <p> else </p>
               ),
               <p>working</p>
            ]
              
                :
             //    <p> not working</p>
             null
             }
             <div class="row">
             <div class="col-sm-12" id="breadcrum" style={{marginTop:"-23px",marginBottom:"0px",borderBottom:"1px dashed #b9b9b9 "}}>
            <h4 class="pull-left page-title"><i class="mdi mdi-phone" aria-hidden="true"></i>&nbsp;&nbsp; Leads Status</h4>
    
            <button type="button" class="btn btn-primary pull-right" style={{marginTop:"18px"}} data-toggle="modal" data-target="#lead_status">
            ADD LEAD STATUS
           </button>
            </div>
            </div> 
            
                <div class="card">
                    <table style={{ width: "100%" }} id="example" class="table table-hover table-striped table-bordered">
                        <thead>
                            <tr>
                                <th style={{width:"79px",textAlign:"right"}}>Sl no.</th>
                                <th>Lead Status</th>
                                <th style={{ width: "72px" }}></th>
                            </tr>
                        </thead>
                        <tbody>
                           
                            {this.state.leads_statuses !== null
                                ? this.state.leads_statuses.map((data,index) => (
            
                                  <tr key={data.id}>
                                    <td style={{textAlign:"right"}}>{++index}.</td>
                                    <td>{data.lead_status}</td>
                                    <td>
                                      
                                            <Link to={`/status-edit/${data.id}/edit`} style={{ color: "#007bff", fontSize: "15px", scrollMarginBottom: "10px" }}><i className=" mdi mdi-pencil"></i></Link>
                                           &nbsp;&nbsp; <Link to="" style={{ color: "red", fontSize: "15px", scrollMarginBottom: "10px" }}><i className=" mdi mdi-delete"></i></Link>
                                           
                                       
                                        

                                        
                      </td>
                                   
                                  </tr>
            
                                ))
                                :
                                null
                              }
    
                        </tbody>
                    </table>
                </div>

                <div class="modal" id="lead_status">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Lead Status : Add </h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form  onSubmit={this.handelFormSubmit} >
                                <input type="hidden" class="form-control" id="exampleInputEmail1"  value={this.state.user_id} placeholder="Enter Lead Status" />
                                <input type="hidden" class="form-control" id="exampleInputEmail1"  value={this.state.user_id} placeholder="Enter Lead Status" />


                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Lead Status</label>
                                        <input type="text" name="lead_status" onChange={this.inputChange}
                                        value={this.state.lead_status} class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Lead Status" />
                                    </div>

                                    <div class="modal-footer">
                                    {this.state.response_status == true ?
                                        <SuccessAlert message= {this.state.response_message} /> : null  }
                                        
                                    {this.state.response_status == false ?
                                        <ErrorAlert message= {this.state.response_message} />:null}
                                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                        <button type="submit" class="btn btn-primary">Save</button>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>



            </div>
        );
    }
}

export default withRouter (lead_status_setting);