import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../../../../../helpers/Constants';
import SuccessAlert from '../../SuccessAlert';
import ErrorAlert from '../../ErrorAlert';

class designation  extends Component {
    constructor(props) {
        super(props)
        const headers = {
            headers: {
            'Content-Type': 'application/json',
            'Authorization':"Bearer "+this.props.auth_user.user_data.original.access_token
            }
            }
        const user_id= this.props.auth_user.user_data.original.user.id;
        console.log(user_id);
        this.state = {
       status:'1',
       headers:headers,
       designations:[],
       designation:'',
       user_id:this.props.auth_user.user_data.original.user.id,
       org_id :this.props.auth_user.user_data.original.user.org_id,
       

         }
        this.inputChange = this.inputChange.bind(this);
        this.handelFormSubmit = this.handelFormSubmit.bind(this);
    }
    inputChange(e) {
        console.log("input type working");
               this.setState({
          [e.target.name]: e.target.value,
          
        });
    }
    handelFormSubmit(event) {
        
         event.preventDefault();
         axios.post(BASE_URL + '/api/designation/addDesignation', {
          designation:this.state.designation,
          user_id:this.props.auth_user.user_data.original.user.id,
          message: this.state.message,
          status: this.state.status,  
          org_id:this.state.org_id,

            },this.state.headers).then(response => {
                console.log(response.data);
                   this.setState({
                    response_status: response.data.status,
                      response_message: response.data.message
                })
                if(response.data.status==true)
                {
                    window.location.reload();
                  this.props.history.push({pathname :'/designation', state :{msg:response.data.message,status:response.data.status}});
                  
                }
               }).catch(err => console.log(err));
          }

          componentDidMount() {
              
            axios.post(BASE_URL + '/api/designation/listDesignation',{org_id:this.state.org_id},this.state.headers)
                .then(response => {
                    this.setState({
                        designations: response.data.data,
                        
                    })
                }).catch(err => console.log(err));
    
            }
            
           

    render() {
        return (
          
            <div>
            
            {this.props.location.state!=undefined ?
                [(this.props.location.state.status == true
                 ? <SuccessAlert message= {this.props.location.state.msg} /> 
                 : <p style={{color:"white"}}> else </p>
               ),
               <p style={{color:"white"}}>working</p>
            ]
                     
                 
                :
             //    <p> not working</p>
             null
             }
             <div class="row">
             <div class="col-sm-12" id="breadcrum" style={{marginTop:"-23px",marginBottom:"0px",borderBottom:"1px dashed #b9b9b9 "}}>
              <h4 class="pull-left page-title"><i class="mdi mdi-phone" aria-hidden="true"></i>&nbsp;&nbsp;   Add Designation</h4>
                
                <button type="button" class="btn btn-primary pull-right" data-toggle="modal" style={{marginTop:"18px"}} data-target="#lead_status">
                    Add Designation
                </button>
                </div>
             </div> 
                
           
             
                <div class="card">
                    <table style={{ width: "100%" }} id="example" class="table table-hover table-striped table-bordered">
                        <thead>
                            <tr>
                            <th style={{width:"54px",textAlign:"right"}} >Sl no.</th>
                                <th>Designation</th>
                                <th style={{ width: "72px" }}></th>
                            </tr>
                        </thead>
                        <tbody>
                           
                            {this.state.designations !== null
                                ? this.state.designations.map((data,index) => (
            
                                  <tr key={data.id}>
                                    <td style={{width:"54px",textAlign:"right"}}>{++index}.</td>
                                    <td>{data.designation}</td>
                                    <td>
                                        <div className="row">
                                        <div className="col-md-2">
                                            <Link to={`/designation/${data.id}/edit`} style={{ color: "#007bff", fontSize: "15px", scrollMarginBottom: "10px" }}><i className=" mdi mdi-pencil"></i></Link>
                                        </div>
                                       
                                        <div className="col-md-2">
                                        <Link to="/designation" style={{ color: "red", fontSize: "15px", scrollMarginBottom: "10px" }}><i className=" mdi mdi-delete"></i></Link>
                                        </div>

                                        </div>
                      </td>
                                   
                                  </tr>
            
                                ))
                                :
                                null
                              }
    
                        </tbody>
                    </table>
                </div>

                <div class="modal" id="lead_status">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Add Designation</h5>
                                {this.state.response_status == false ?
                                    <ErrorAlert  message= {this.state.response_message} />:null}
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form  onSubmit={this.handelFormSubmit} >
                                

                                    <div class="form-group">
                                        <label>Designation</label>
                                        <input type="text"  name="designation" onChange={this.inputChange}
                                        value={this.state.designation} class="form-control"  placeholder="Enter Call Satge" required />
                                    </div>
                                  
                                    <div class="modal-footer">
                                   {/*} {this.state.response_status == true ?
                                        <SuccessAlert message= {this.state.response_message} /> : null  }
                                        */}
                                   
                                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                        <button type="submit" class="btn btn-primary">Save</button>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>



            </div>
        );
    }
}

export default withRouter (designation);