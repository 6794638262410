/* eslint-disable*/
import React, { Component } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link, withRouter } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import { FaEdit } from 'react-icons/fa';
import axios from 'axios';
import SuccessAlert from './SuccessAlert';
import { BASE_URL } from '../../../helpers/Constants';
import './page.css';


class view_organisation extends Component {


  constructor(props) {
    super(props)
    const headers = {
      headers: {
      'Content-Type': 'application/json',
      'Authorization':"Bearer "+this.props.auth_user.user_data.original.access_token
      }
      }
    this.state = {

      id: '',
      first_name: '', last_name: '', email: '', mobile: '',  phone: '', website: '',
      company_name: '', n_o_employee: '', fax: '', lead_status: '', lead_owner: '', annual_revenue: '', rating: '', lead_source: '',
      industry: '', linkedIn_id: '', linkedIn_url: '', address1: '', city_distt: '', head_quater: '', apperance_in_country: '',
      state_province: '', pincode: '', country: '', description: '',headers:headers,
      // leads: []


    }
  }

  componentDidMount() {
    const org_id = this.props.match.params.id
    console.log(org_id);

    console.log('organisation view');
  
    axios.get(BASE_URL + `/api/fetch_org_data/${org_id}`,this.state.headers)
      .then(response => {
        this.setState({
          id: response.data.id,
          org_name: response.data.org_name,
          contact_no: response.data.contact_no,
          email: response.data.email,
          contact_person: response.data.contact_person,
          address: response.data.address,
          created_at: response.data.created_at,
          

        })
      }).catch(err => console.log(err));

  
  }

  render() {

    return (
      <div>
        {this.state.alert_message == "success" ? <SuccessAlert message={"Lead data Updated Successfully"} /> : null}
        <Card>
            <Card.Header style={{padding:"5px",borderBottom:"0px solid rgba(0,0,0,.125)"}}>
            <Card.Subtitle className="mb-2 text-muted"><Row>
              <Col md={10}> <h5 style={{margin:"1px 0 1px"}}><strong>Organisation : Information</strong></h5></Col>
              <Col md={2}> <Link to="/Organization" style={{ backgroundColor: '#317eeb', color: 'white',marginTop:"-1em" }} className="btn btn-primary pull-right">Back</Link></Col>
            </Row></Card.Subtitle>
            </Card.Header>
            <Card.Body>
            
            <Row>
            <Table  style={{marginTop:"-1em"}}>

              <tbody>

      

                  <td style={{fontSize:"18px",fontWeight:"500",color:"#317eeb"}}>{this.state.org_name} </td>
                 
                  <td style={{fontSize:"15px"}}> <i className=" mdi mdi-email" ></i>&nbsp;&nbsp;{this.state.email} </td>
                  <td style={{fontSize:"15px"}}> <i className=" mdi mdi-cellphone">&nbsp;&nbsp;</i>{this.state.contact_no}</td>
                  <td style={{fontSize:"15px"}}> Status:<span style={{color:"#317eeb"}}>{" "} Active</span></td>
                  <td>  <Link to={`/Organization/${this.state.id}/edit`}><span style={{ color: "#317eeb" }}><FaEdit /></span></Link></td>
                
         
                
              </tbody>

            </Table>
            <hr className="new1"/>
            <Card.Subtitle className="mb-2 text-muted"><h5> <i className=" mdi mdi-account-multiple-outline" style={{fontSize:"30px"}}></i>&nbsp;Organization Details</h5>
            </Card.Subtitle>
            <Table striped bordered hover size="sm" style={{marginTop:"-1em"}}>

            <tbody>

              <tr>

                <th>Company Name : {this.state.org_name}</th>
                <th>Phone : {this.state.contact_no}  </th>

              </tr>
              <tr>
                <th>Email : {this.state.email}</th>
                <th>Mobile : {this.state.contact_no}</th>
              </tr>
              <tr>
                <th>Website :{this.state.website}</th>
                <th>Contact Person Name : {this.state.contact_person}  </th>
              </tr>
              <tr>
                <th>Address :{this.state.address}</th>
                <th>Created Date: {this.state.date_created} </th>
               
              </tr>
              
            </tbody>

          </Table>
        


        
            
          </Row>

          </Card.Body>
        </Card>
        
        <br />
        
      </div>
    )
  }
}

export default withRouter(view_organisation);
