import { takeEvery, fork, put, all } from 'redux-saga/effects';
import { FORGOT_PASSWORD_EMAIL } from './actionTypes';
import { forgotPasswordEmailSuccess, forgotPasswordEmailApiError  } from './actions';
import { BASE_URL } from '../../../helpers/Constants';

function* forgotPasswordEmail(data) {
    try 
    {
        const response = yield fetch(BASE_URL+"/api/forgot-password", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify({
                email: data.payload.email
            })
        })
        .then(res => res.json())
        .catch(error => ({ 
            status: "error", 
            errors: { message: "Server not responding" } 
        }));
        
        if(response.status === "success")
        {
            yield put(forgotPasswordEmailSuccess(response.data));
            data.payload.history.push('/');
        }
        else if(response.status === "error")
        {
            yield put(forgotPasswordEmailApiError(response.errors.message));
        }
    } 
    catch (error) 
    {
        yield put(forgotPasswordEmailApiError(error));
    }
}

export function* watchForgotPasswordEmail() {
    yield takeEvery(FORGOT_PASSWORD_EMAIL, forgotPasswordEmail)
}

function* forgotPasswordEmailSaga() {
    yield all([
        fork(watchForgotPasswordEmail)
    ]);
}

export default forgotPasswordEmailSaga;