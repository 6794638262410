/* eslint-disable*/      
 import React, { Component } from 'react';
import { withRouter,Link } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../../../helpers/Constants';
import SuccessAlert from './SuccessAlert';     
import ErrorAlert from './ErrorAlert';

class add_team_user extends Component {
  
        constructor(props) {
            super(props)
            const user_id = this.props;
            console.log(user_id);
            this.state = {
                countrys:[],
                citys:[],states:[],
                name: '',
                profile_img: '',
                designation: '',
                email: '',
                gender: '', phone: '', address: '', country:'', address2: '', city: '', state: '', pincode: '',
                username: '', password: '',
                designations:[],teamheads:[],
                created_by: this.props.auth_user.user_data.original.user.id,
                team_head_id:''

            }
            this.inputChange = this.inputChange.bind(this);
            this.handelFormSubmit = this.handelFormSubmit.bind(this);
            this.getnewstate = this.getnewstate.bind(this);
            this.getnewcity = this.getnewcity.bind(this);
            this.getcity_id = this.getcity_id.bind(this);
            this.imageChange = this.imageChange.bind(this);
           
        }

        inputChange(e) {
            console.log("input type working");

            this.setState({
                [e.target.name]: e.target.value,


            });
            console.log("country" + this.setState.country);

        }

        handelFormSubmit(event) {
            const headers = {
                headers: {
                'Content-Type': 'application/json',
                'Authorization':"Bearer "+this.props.auth_user.user_data.original.access_token
                }
                }
                var formData = new FormData();
              var imgFile = document.querySelector('#testFile');
              formData.append("profile_img", imgFile.files[0]);
              formData.append("tema_head_id", this.state.designation);
               formData.append("users_role", this.state.designation);
               formData.append("created_by", this.props.auth_user.user_data.original.user.id);
               formData.append("name", this.state.name);
               formData.append("designation", this.state.designation);
               formData.append("email", this.state.email);
               formData.append("gender", this.state.gender);
               formData.append("phone", this.state.phone);
               formData.append("address", this.state.address);
               formData.append("address2", this.state.address2);
               formData.append("city", this.state.city);
               formData.append("state", this.state.state);

               formData.append("pincode", this.state.pincode);
               formData.append("username", this.state.username);
               formData.append("password", this.state.password);
               formData.append("country", this.state.country);
               formData.append("org_id", this.props.auth_user.user_data.original.user.org_id);
                console.log(this.state.user_id)
                event.preventDefault();
                axios.post(BASE_URL + '/api/sales_people/add',
                    formData,headers).then(response => {
                    this.setState({
                        response_status: response.data.status,
                        response_message: response.data.message

                    })
                    if(response.data.status==true)
                {

                    this.props.history.push({pathname :'/team', state :{msg:response.data.message,status:response.data.status}});
                }

            }).catch(err => console.log(err));

        }

        imageChange(e) {
            e.preventDefault();
    
            let reader = new FileReader();
            let file = e.target.files[0];
           
    
            reader.onloadend = () => {
                this.setState({
                  
                    file: file,
                    imagePreviewUrl: reader.result,
                   
                });
            }
    
            reader.readAsDataURL(file)
           }

        componentDidMount() {
            console.log(this.props.auth_user.user_data.original.user.id);
            const headers = {
                headers: {
                'Content-Type': 'application/json',
                'Authorization':"Bearer "+this.props.auth_user.user_data.original.access_token
                }
                }
                axios.get(BASE_URL + '/api/list_countries',headers)
                .then(response => {
                    this.setState({
                        countrys: response.data,
    
                    })
                }).catch(err => console.log(err));

                axios.post(BASE_URL + '/api/designation/listDesignation', {org_id:this.props.auth_user.user_data.original.user.org_id},headers)
                .then(response => {
                    this.setState({
                        designations: response.data.data,
                        org_id:this.props.auth_user.user_data.original.user.org_id,
                    })
                }).catch(err => console.log(err));

                axios.post(BASE_URL + '/api/users/fetch_team_head', {org_id:this.props.auth_user.user_data.original.user.org_id},headers)
                .then(response => {
                    this.setState({
                        teamheads: response.data.data,
                        org_id:this.props.auth_user.user_data.original.user.org_id,
                    })
                }).catch(err => console.log(err));
        }
        
        selectCountry(val) {
            this.setState({ country: val });
        }
    
        getnewstate(e) {
            const headers = {
                headers: {
                'Content-Type': 'application/json',
                'Authorization':"Bearer "+this.props.auth_user.user_data.original.access_token
                }
                }
            this.setState({
                country: e.target.value
    
            })
            const country = e.target.value;
            console.log(country);
            axios.get(BASE_URL + `/api/list_state/${country}`,headers)
                .then(response => {
                    this.setState({
                        states: response.data,
                        state:response.data,
    
    
                    })
                }).catch(err => console.log(err));
    
        }
        getnewcity(e) {
            const headers = {
                headers: {
                'Content-Type': 'application/json',
                'Authorization':"Bearer "+this.props.auth_user.user_data.original.access_token
                }
                }
            this.setState({
                state: e.target.value,
    
            })
            const state_province = e.target.value;
            console.log("state" + state_province);
            axios.get(BASE_URL + `/api/list_cities/${state_province}`,headers)
                .then(response => {
                    this.setState({
                        citys: response.data,
    
                    })
                }).catch(err => console.log(err));
    
        }
        getcity_id(e) {
            this.setState({
    
                city: e.target.value
    
            })
        }
    
    
  

    render() {
        let { imagePreviewUrl } = this.state;
        let $imagePreview = null;
        if (imagePreviewUrl) {
            $imagePreview = (<img style={{ width: "120px", height: "80px", marginLeft: "38px",border:"dashed 2px" }}  src={imagePreviewUrl} />);
        } else {
            $imagePreview = (<div class="form-group col-md-2 mb-3">
            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTEtEfdDJMcKiYSPLaKH4uB7EAZzK7QZZzJ9g&usqp=CAU" style={{ width: "80px", height: "80px", marginLeft: "38px" }} />
        </div>);
        }
        return (
            <div>

            {this.state.response_status == false ?
                <ErrorAlert message= {this.state.response_message} />:null}
                

                <div class="row">
                    <div class="col-sm-12" id="breadcrum" style={{marginTop:"-23px",marginBottom:"0px",borderBottom:"1px dashed #b9b9b9 "}}>
                <h4 class="pull-left page-title" style={{lineHeight:"48px"}}><i class="fa fa-users" aria-hidden="true"></i>&nbsp;&nbsp;Sales People : Add</h4>
                <ol class="breadcrumb pull-right">
                    
                <li><Link to={"/team"} style={{ color: "#007bff", fontSize: "15px", scrollMarginBottom: "10px" }} className="pull-right active" >Sales People </Link></li>
                <li><Link to={"/team"} style={{  color: "#007bff",fontSize: "15px", scrollMarginBottom: "10px" }} className="pull-right">Add</Link></li>
                <li><Link to={"/team"} style={{ fontSize: "15px", scrollMarginBottom: "10px" }} className="pull-right">Home</Link></li>
    
                </ol>
            </div>
        </div>
        <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">

                            <div class="dsfdsf"></div>
                            <form onSubmit={this.handelFormSubmit}>
                        <input type="hidden" name="org_id" value={this.state.org_id}/>
                        <input type="hidden" name="users_role" value={this.state.designation}/>
                                <div class="form-row">
                                    <div class="col-md-6 mb-3">
                                        <label for="validationServer013">Name <span style={{color:"red"}}>*</span></label>
                                        <input required type="text" class="form-control eml" id="name" 
                                        value={this.state.name}
                                        onChange={this.inputChange} name="name" required pattern="[a-zA-Z\s]+"   maxlength="30" placeholder="Name" />
                                    </div>


                                                <div class="form-group col-md-2 mb-3">
                                                    {$imagePreview}
                                                </div>

                                                <div class="form-group col-md-4 mb-3">
                                                    <label for="validationServer013"> Upload Profile image</label>

                                                    <input id="testFile" type="file" class="form-control"
                                                        onChange={this.imageChange} required accept="image/*" name="profile_img" placeholder="Name" />

                                                    <p id="profile_error_msg" style={{ color: "red" }}> </p>
                                                </div>


            {/*   {!this.state.profile_img == " " ?
                                    <div class="form-group col-md-2 mb-3">
                                        <img src={this.state.profile_img}  style={{ width: "120px", height: "80px", marginLeft: "100px" }} id="photo" />
                                    </div>
                                    :
                                    <div class="form-group col-md-2 mb-3">
                                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQwrheqyGFt5E7EEPUc3L2lcIrb9Xb_Vw624Q&usqp=CAU" style={{ width: "80px", height: "60px", marginLeft: "100px" }} />
                                    </div>
                                }

                                <div class="form-group col-md-4 mb-3">
                                    <label for="validationServer013"> Update Profile image <span style={{color:"red"}}>*</span></label>

                                    <input type="file" class="form-control" id="logo" required
                                        onChange={this.inputChange} name="profile_img" placeholder="Name" />

                                    <p id="profile_error_msg" style={{ color: "red" }}> </p>
                            </div>*/}
                                    
                                
                                    <div class="col-md-6 mb-3">
                                        <label for="validationServer013">Designation<span style={{color:"red"}}>*</span></label>
                                        <select class="select2 form-control " name="designation" value={this.state.designation}
                                        onChange={this.inputChange} required  id="designation" title="Designation can't be empty" required=""  aria-required="true">
                                            <option >-- Select Designation -- </option>
                                            <option value={3}>Team Head</option>
                                           {/*} {this.state.designations !==null ?
                                                this.state.designations.map(desig=>(

                                                    <option value={desig.id}>{desig.designation}</option>
                                                ))
                                                :
                                                null
                                            }
                                        */}
                                        </select>
                                    </div>
                                   
                                    {this.state.designation == "4" || this.state.designation == "5" ?
                               
                                    <div class="col-md-6 mb-3">
                                    <label for="validationServer013">Team Head<span style={{color:"red"}}>*</span></label>
                                    <select class="select2 form-control " name="team_head_id" value={this.state.team_head_id}
                                    onChange={this.inputChange} required  id="designation" title="Designation can't be empty" required=""  aria-required="true">
                                        <option>-- Select Team Head -- </option>
                                        
                                        {this.state.teamheads !==null ?
                                            this.state.teamheads.map(head=>(

                                                <option value={head.id}>{head.name}</option>
                                            ))
                                            :
                                            null
                                        }
                                        
                                    </select>
                                </div>
                                :null
                            }

                                    <div class="col-md-6 mb-3">
                                        <label for="validationServer013">Email <span style={{color:"red"}}>*</span></label>
                                        <input required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" title="Invalid Email" type="text" class="form-control eml" value={this.state.email}
                                        onChange={this.inputChange} required  placeholder="abc@gmail.com" name="email" id="email"  required="" required-area="true" />
                                    </div>

                                    <div class="col-md-6 mb-3">
                                        <label for="validationServer013">Gender <span style={{color:"red"}}>*</span></label>
                                        <div class="form-group inline" style={{ marginLeft: "10px" }}>

                                            <div class="radio radio-info form-check-inline">
                                                <input required value={this.state.gender}
                                                onChange={this.inputChange} type="radio" id="male" value="male" name="gender" />
                                                <label for="inlineRadio1">Male</label>
                                            </div>
                                            <div class="radio radio-info form-check-inline">
                                                <input required  value={this.state.gender}
                                                onChange={this.inputChange} type="radio" id="female" value="female" name="gender" />
                                                <label for="inlineRadio1">Female</label>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="col-md-6 mb-3">
                                        <label for="validationServer013">Phone <span style={{color:"red"}}>*</span></label>
                                        <input maxlength="15" pattern="[0-9]+\d*" title="Please enter number only" required="required" type="text"  value={this.state.phone}
                                        onChange={this.inputChange} class="form-control" name="phone" id="phone" placeholder="+91" maxlength="10" />
                                    </div>

                                    <div class="col-md-6 mb-3">
                                        <label for="validationServer013">Address Line 1 <span style={{color:"red"}}>*</span></label>
                                        <input required type="text" value={this.state.address}
                                        onChange={this.inputChange} class="form-control eml" name="address" id="address" required="" required-area="true" placeholder="" maxlength="100" />
                                    </div>

                                    <div class="col-md-6 mb-3">
                                        <label for="validationServer013">Address Line 2</label>
                                        <input type="text" value={this.state.address2}
                                        onChange={this.inputChange} class="form-control eml" id="address2" name="address2" placeholder=""  maxlength="100" />
                                    </div>
                                    <div class="col-md-6 mb-3">
                                    <div class="form-group">
                                        <label for="field-2" class="control-label">Country <font color="red">*</font></label>
                                        <select class="select2 form-control" onChange={this.getnewstate} 
                                        name="country" required="" aria-required="true" 
                                            data-placeholder="Choose a Country...">
                                            <option value="">--Select--</option>
                                            {this.state.countrys !== null
                                                ? this.state.countrys.map(country => (
                                                
                                                    <option key={country.country_id} value={country.country_id}>{country.country_name}</option>
                                                ))
                                                :
                                                null
                                            }

                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-6 mb-3">
                                <div class="form-group">
                                    <label for="field-2" class="control-label">State <font color="red">*</font></label>
                                    <select class="select2 form-control"
                                    name="state"  value={this.state.state} onChange={this.getnewcity}
                                        data-placeholder="Choose a State...">
                                        <option value="">--Select--</option>
                                        {this.state.states !== null
                                            ? this.state.states.map(state => (
                                                <option value={state.state_id}>{state.state_name}</option>
                                            ))
                                            :
                                            null
                                        }


                                    </select>
                                </div>
                            </div>

                            <div class="col-md-6 mb-3">
                            <div class="form-group">
                                <label for="field-2" class="control-label">City <font color="red">*</font></label>
                            <select class="select2 form-control" 
                            name="city" value={this.state.city}  aria-required="true" onChange={this.getcity_id}
                                    data-placeholder="Choose a City...">
                                    <option>--Select--</option>
                                    {this.state.citys !== null
                                        ? this.state.citys.map(cit => (
                                            <option value={cit.city_id}>{cit.city_name}</option>
                                        ))
                                        :
                                        null
                                    }


                                </select>
                            </div>
                        </div>
                            <div class="col-md-6 mb-3">
                            <label for="field-2" class="control-label">Pincode  <font color="red">*</font></label>
                                    <input required type="text" name="pincode" value={this.state.pincode}
                                        onChange={this.inputChange} id="pincode_check" class="form-control eml"  placeholder="Pincode" maxlength="10" />
                                        <p id="pincode_error_msg" style={{ color: "red" }}> </p>
                                    </div>

                                    <div class="col-md-6 mb-3">
                                        <label for="validationServer013">User Name <span style={{color:"red"}}>*</span> </label>
                                        <div class="form-group">
                                            <label class="sr-only" for="exampleInputPassword2">User Name</label>
                                            <input required type="text"  value={this.state.username}
                                            onChange={this.inputChange}  class="form-control" id="username" name="username"  required="" required-area="true" placeholder="Username" />
                                        </div>
                                    </div>

                                    <div class="col-md-6 mb-3">
                                        <label for="validationServer013">Password <span style={{color:"red"}}>*</span></label>
                                        <div class="form-group">
                                            <label class="sr-only" for="exampleInputPassword2">Password</label>
                                            <input required type="password"  value={this.state.password}
                                            onChange={this.inputChange} class="form-control eml" name="password" id="password" placeholder="Password" required="" required-area="true" />
                                            <input required type="hidden" class="form-control eml" name="oldpassword" id="oldpassword"  placeholder="Password" />
                                        </div>
                                    </div>

                                </div>

<hr/>
                                <div class="col-md-12" style={{ textAlign: "right" }}>
                                    <button type="submit" id="btnsubmit" class="btn btn-success btn-lg">Submit</button>
                                </div>
                                <br />

                            </form>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

);
}
}

export default withRouter(add_team_user);       

