import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from "react-router-dom";
import { forgotPasswordEmail } from '../../../store/rootAction';

class ForgotPassword extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            errors: {}
        };
    }

    componentDidMount() {
        document.title = "Forgot Password :: Skill360";
    }

    handleUserInput = event => {
        this.setState({[event.target.name]: event.target.value});
    };

    handleForgotPasswordForm = event => {
        event.preventDefault();

        let errors = {};

        if(this.state.email === '')
            errors.email = "Email can't be empty";

        this.setState({errors});

        if(Object.keys(errors).length === 0)
            this.props.dispatchForgotPasswordEmail({
                email: this.state.email,
                history: this.props.history
            });
    };

    render() {
        return (
            <React.Fragment>
                <div className="accountbg"></div>
                <div className="wrapper-page">
                    <div className="card">
                        <div className="card-body">
                            <h3 className="text-center m-0">
                                <Link to="/" className="logo logo-admin"><img src="assets/images/logo-black.svg" height="40" alt="logo" /></Link>
                            </h3>
                            <div className="p-3">
                                <h4 className="font-18 m-b-5 text-center">Forgot Password</h4>
                                <p className="text-muted text-center">Enter your Email and instructions will be sent to you!</p>
                                { (this.props.getError !== null) &&
                                    <div class="alert alert-danger" role="alert">
                                        <strong>Error!</strong> {this.props.getError}
                                    </div>
                                }
                                <form className="form-horizontal m-t-30" onSubmit={this.handleForgotPasswordForm}>
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input type="email" className={(this.state.errors.email) ? "form-control is-invalid" : "form-control"} id="email" name="email" placeholder="Enter email" onChange={this.handleUserInput} />
                                        {this.state.errors.email &&
                                            <div className="invalid-feedback">{this.state.errors.email}</div> 
                                        }
                                    </div>
                                    <div className="form-group row m-t-20">
                                        <div className="col-12 text-right">
                                            <button className="btn btn-primary w-md waves-effect waves-light" type="submit">Reset</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="m-t-40 text-center">
                        <p className="text-white">Remember It ? <Link to="/login" className="font-500 font-14 text-white font-secondary"> Sign In Here </Link> </p>
                        <p className="text-white">© {new Date().getFullYear()-1} -  {new Date().getFullYear()} Skill360. Crafted with <i className="mdi mdi-heart text-danger"></i> by Asterro</p>
                    </div>
                </div>
            </React.Fragment>
        );
    }
};

const mapStateToProps = state => {
    return {
        getError: state.forgotPasswordEmailReducer.error
    };
};

const mapDispatchToProps = dispatch => {
    return {
        dispatchForgotPasswordEmail: data => dispatch(forgotPasswordEmail(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ForgotPassword));