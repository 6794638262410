import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../../../../../helpers/Constants';
import SuccessAlert from '../../SuccessAlert';
import ErrorAlert from '../../../admin/ErrorAlert';

class add_call_response extends Component {
    constructor(props) {
        super(props)
        const headers = {
            headers: {
            'Content-Type': 'application/json',
            'Authorization':"Bearer "+this.props.auth_user.user_data.original.access_token
            }
            }
        const user_id= this.props.auth_user.user_data.original.user.id;
        console.log(user_id);
        this.state = {
            calls_responses:[],
            response_name:'',
          user_id:this.props.auth_user.user_data.original.user.id,
          headers:headers,
          org_id :this.props.auth_user.user_data.original.user.org_id,

         }
        this.inputChange = this.inputChange.bind(this);
        this.handelFormSubmit = this.handelFormSubmit.bind(this);
    }
    inputChange(e) {
        console.log("input type working");
               this.setState({
          [e.target.name]: e.target.value,
         
        });
    }
    handelFormSubmit(event) {
        console.log(this.props);
         event.preventDefault();
         axios.post(BASE_URL + '/api/call_response/add_call_response', {
            response_name:this.state.response_name,
          user_id:this.props.auth_user.user_data.original.user.id,
          message: this.state.message,
          status: this.state.status,  
          org_id:this.state.org_id,

            },this.state.headers).then(response => {
                console.log(response.data);
                   this.setState({
                    response_status: response.data.status,
                      response_message: response.data.message
                })
                if(response.data.status==true)
                {
                    window.location.reload();
                  this.props.history.push({pathname :'/add-call-response', state :{msg:response.data.message,status:response.data.status}});
                  
                }
               }).catch(err => console.log(err));
          }

          componentDidMount() {
              
            axios.get(BASE_URL + '/api/call_response/list_call_response',this.state.headers)
                .then(response => {
                    this.setState({
                        calls_responses: response.data.data,
                        
                    })
                }).catch(err => console.log(err));
    
            }
            
  

    render() {
        return (
          
            <div>
            {this.props.location.state!=undefined ?
                [(this.props.location.state.status == true
                 ? <SuccessAlert message= {this.props.location.state.msg} /> 
                 : <p style={{color:"white"}}> else </p>
               ),
               <p style={{color:"white"}}>working</p>
            ]
                     
                 
                :
             //    <p> not working</p>
             null
             }
             <div class="row">
             <div class="col-sm-12" id="breadcrum" style={{marginTop:"-23px",marginBottom:"0px",borderBottom:"1px dashed #b9b9b9 "}}>
            <h4 class="pull-left page-title"><i class="mdi mdi-phone" aria-hidden="true"></i>&nbsp;&nbsp; Call Response</h4>
    
                   <button type="button" class="btn btn-primary pull-right" data-toggle="modal"  style={{marginTop:"18px"}} data-target="#lead_status">
                      ADD RESPONSE
                   </button>
            </div>
            </div> 
                  <div class="card" style={{
                        padding: "1em", borderTop:
                        "2px solid #3366c6"
                             }}>
                    <table style={{ width: "100%" }} id="example" class="table table-hover table-striped table-bordered">
                        <thead>
                            <tr>
                            <th style={{width:"79px",textAlign:"right"}}>Sl no.</th>
                                <th>Response Name</th>
                                <th style={{ width: "72px" }}></th>
                            </tr>
                        </thead>
                        <tbody>
                           
                            {this.state.calls_responses !== null
                                ? this.state.calls_responses.map((call,index) => (
            
                                  <tr key={call.id}>
                                  <td style={{textAlign:"right"}}>{++index}.</td>
                                    <td>{call.response_name}</td>
                                    <td>
                                        <div className="row">
                                        <div className="col-md-2">
                                            <Link to={`/edit-response/${call.id}/edit`} style={{ color: "#007bff", fontSize: "15px", scrollMarginBottom: "10px" }}><i className=" mdi mdi-pencil"></i></Link>
                                        </div>
                                       
                                        <div className="col-md-2">
                                        <Link to="" style={{ color: "red", fontSize: "15px", scrollMarginBottom: "10px" }}><i className=" mdi mdi-delete"></i></Link>
                                        </div>

                                        </div>
                      </td>
                                   
                                  </tr>
            
                                ))
                                :
                                null
                              }
    
                        </tbody>
                    </table>
                </div>

                <div class="modal" id="lead_status">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                            {/*{this.state.response_status == true ?
                                <SuccessAlert message= {this.state.response_message} /> : null  }
                                
                            {this.state.response_status == false ?
                            <ErrorAlert message= {this.state.response_message} />:null}*/}
                                
                                <h5 class="modal-title" id="exampleModalLabel">Add Response Type</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form  onSubmit={this.handelFormSubmit} >
                                <input type="hidden" class="form-control" id="exampleInputEmail1"  value={this.state.user_id} placeholder="Enter Lead Status" />
                               


                                    <div class="form-group">
                                        <label>Response Detail</label>
                                        <input type="text" name="response_name" onChange={this.inputChange}
                                        value={this.state.response_name} class="form-control"  required placeholder="Enter Call Response Details" />
                                    </div>

                                    <div class="modal-footer">
                                   
                                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                        <button type="submit" class="btn btn-primary">Submit</button>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>



            </div>
        );
    }
}

export default withRouter (add_call_response);