/* eslint-disable*/
import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../../../helpers/Constants';
import SuccessAlert from './SuccessAlert';
import ErrorAlert from './ErrorAlert';

class edit_org extends Component {
    constructor(props) {
        super(props)
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + this.props.auth_user.user_data.original.access_token
            }
        }
        const user_id = this.props;
        console.log(user_id);
        this.state = {
            org_id: '',
            orgs: [], org_name: '', address: '', photo: '', countrys: [], city_id: [], state_id: [], pincode: '',
            website: '', contact_no: '', email: '', company_status: '', username: '', password: '', login_status: '', country_id: '', citys: [], states: [], countrys: [],
            headers: headers,


        }
        this.inputChange = this.inputChange.bind(this);
        this.handelFormSubmit = this.handelFormSubmit.bind(this);
        this.getnewstate = this.getnewstate.bind(this);
        this.getnewcity = this.getnewcity.bind(this);
        this.getcity_id = this.getcity_id.bind(this);
        this.getonloadstate = this.getonloadstate.bind(this);
        this.getonloadcity = this.getonloadcity.bind(this);
    }
    inputChange(e) {
        console.log("input type working");

        this.setState({
            [e.target.name]: e.target.value,



        });
        console.log("country" + this.setState.country);

    }

    handelFormSubmit(event) {
        console.log(this.state.user_id)
        event.preventDefault();
        axios.post(BASE_URL + '/api/org/store', {
            created_by: this.props.auth_user.id,
            org_name: this.state.org_name,
            address: this.state.address,
            photo: this.state.photo,
            country_id: this.state.country_id,
            city_id: this.state.city_id,
            state_id: this.state.state_id,
            pincode: this.state.pincode,
            website: this.state.website,
            contact_no: this.state.contact_no,
            email: this.state.email,
            company_status: this.state.company_status,
            username: this.state.username,
            password: this.state.password,
            login_status: this.state.login_status,
            org_code: this.state.org_id,
            org_id: this.props.match.params.id,
            message: this.state.message,
            status: this.state.status,
        }, this.state.headers).then(response => {
            this.setState({
                response_status: response.data.status,
                response_message: response.data.message

            })
            if (response.data.status == true) {

                this.props.history.push({ pathname: '/Organization', state: { msg: response.data.message, status: response.data.status } });
            }

        }).catch(err => console.log(err));


    }
    getonloadstate(e) {
        console.log("hello" + this.state.country_id)
        const getcountry_id = this.state.country_id;
        axios.get(BASE_URL + `/api/list_state/${getcountry_id}`, this.state.headers)
            .then(response => {
                this.setState({
                    states: response.data,


                })
            }).catch(err => console.log(err));


    }
    getonloadcity(e) {
        console.log("hello city " + this.state.state_id)
        const getstate_id = this.state.state_id;
        axios.get(BASE_URL + `/api/list_cities/${getstate_id}`, this.state.headers)
            .then(response => {
                this.setState({
                    citys: response.data,

                })
            }).catch(err => console.log(err));

    }
    componentDidMount() {
        const id = this.props.match.params.id
        console.log(id);
        console.log('edit page working');
        axios.get(BASE_URL + '/api/fetch_org_data/' + `${id}`, this.state.headers).then(response => {
            this.setState({
                first_name: response.data.first_name,
                org_name: response.data.org_name,
                address: response.data.address,
                photo: response.data.photo,
                country_id: response.data.country_id,
                city_id: response.data.city_id,
                state_id: response.data.state_id,
                pincode: response.data.pincode,
                website: response.data.website,
                contact_no: response.data.contact_no,
                email: response.data.email,
                company_status: response.data.company_status,
                username: response.data.username,
                org_code: response.data.org_code,
                login_status: response.data.login_status,

            })
            this.getonloadstate();
            this.getonloadcity();
        }).catch(err => console.log(err));



        axios.get(BASE_URL + '/api/list_countries', this.state.headers)
            .then(response => {
                this.setState({
                    countrys: response.data
                })
            }).catch(err => console.log(err));


    }
    getnewstate(e) {
        this.setState({
            country: e.target.value

        })
        const country = e.target.value;
        console.log(country);
        axios.get(BASE_URL + `/api/list_state/${country}`, this.state.headers)
            .then(response => {
                this.setState({
                    states: response.data,


                })
            }).catch(err => console.log(err));

    }
    getnewcity(e) {
        this.setState({
            state_id: e.target.value,

        })
        const state_id = e.target.value;
        console.log("state" + state_id);
        axios.get(BASE_URL + `/api/list_cities/${state_id}`, this.state.headers)
            .then(response => {
                this.setState({
                    citys: response.data,

                })
            }).catch(err => console.log(err));

    }
    getcity_id(e) {
        this.setState({

            city_id: e.target.value

        })
    }

    render() {
        return (
            <div>
                {this.state.response_status == true ?
                    <SuccessAlert message={this.state.response_message} /> : null}
                {this.state.response_status == false ?
                    <ErrorAlert message={this.state.response_message} /> : null}
                <div class="row">
                    <div class="col-sm-12" id="breadcrum" style={{ marginTop: "-23px", marginBottom: "0px", borderBottom: "1px dashed #b9b9b9 " }}>
                        <p class="pull-left" style={{ fontSize: "25px", color: "#000", marginTop: "14px" }}><i class="mdi mdi-home-modern" aria-hidden="true"></i>&nbsp;&nbsp; Organization : Edit </p>
                        <ol class="breadcrumb pull-right">

                            <li><Link to={"/Organization"} style={{ color: "#007bff", fontSize: "15px", scrollMarginBottom: "10px" }} className="pull-right active" > Organization Info</Link></li>

                            <li><Link to={"/Organization"} style={{ color: "#007bff", fontSize: "15px", scrollMarginBottom: "10px" }} className="pull-right active" >Edit Organization</Link></li>
                            <li><Link to={"/"} style={{ fontSize: "15px", scrollMarginBottom: "10px" }} className="pull-right active" >Home</Link></li>
                        </ol>
                    </div>
                </div>
                <div class="card">

                    <div class="card-body">
                        <form onSubmit={this.handelFormSubmit}>
                            <input type="hidden" name="org_id" value={this.state.org_id} />                           <div class="row">

                                <div class="col-md-12">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-4 control-label">Name of the company</label>
                                        <div class="col-sm-7">
                                            <input type="text" class="form-control" name="org_name" onChange={this.inputChange}
                                                value={this.state.org_name} id="org_name" required="" placeholder="Name of the company" required />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-4 control-label">Organization Code</label>
                                        <div class="col-sm-7">
                                            <input type="text" class="form-control" name="org_code" onChange={this.inputChange}
                                                value={this.state.org_code} id="org_code" required="" placeholder="Name of the company" required />
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="form-group row">
                                        <label class="col-md-4 control-label" for="example-textarea-input">Company Address</label>
                                        <div class="col-md-7">
                                            <textarea class="form-control" rows="2" required="" name="address" onChange={this.inputChange}
                                                value={this.state.address} id="address" required ></textarea>
                                        </div>
                                    </div>
                                </div>

                               {/*} {!this.state.photo == " " ?
                                    <div class="form-group col-md-1 mb-3">
                                        <img src={this.state.photo} style={{ width: "120px", height: "80px", }} id="photo" />
                                    </div>
                                    :
                                    <div class="form-group col-md-1 mb-3">
                                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcS1wWNbNBqfxNHoLqFuP7nNzc9aLeJkSObARA&usqp=CAU" style={{ width: "80px", height: "60px", }} />
                                    </div>
                                }
                                <div class="form-group col-md-2 mb-3">
                                    <label for="validationServer013">Profile image</label>

                                    <input type="file" class="form-control" id="logo"
                                        onChange={this.inputChange} name="photo" placeholder="Name" required />

                                    <p id="profile_error_msg" style={{ color: "red" }}> </p>
                            </div>*/}


                            </div>

                            <div class="row">

                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="field-2" class="control-label">Country <font color="red">*</font></label>
                                        <select class="select2 form-control" id="country_id" onChange={this.getnewstate}
                                            value={this.state.country_id} name="country" required aria-required="true"
                                            data-placeholder="Choose a Country...">
                                            <option value=" " >--Select--</option>
                                            {this.state.countrys !== null
                                                ? this.state.countrys.map(country => (
                                                    <option value={country.country_id} selected={country.country_id == this.state.country_id}>{country.country_name}</option>
                                                ))
                                                :
                                                null
                                            }

                                        </select>
                                    </div>
                                </div>

                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="field-2" class="control-label">State <font color="red">*</font></label>
                                        <select class="select2 form-control"
                                            name="state_id" required="" aria-required="true" onLoad={this.getonloadstate}
                                            value={this.state.state_id} onChange={this.getnewcity}
                                            data-placeholder="Choose a State..." required>
                                            <option value=" " >--Select--</option>
                                            {this.state.states !== null
                                                ? this.state.states.map(state => (
                                                    <option value={state.state_id} selected={state.state_id == this.state.state_id}>{state.state_name}</option>
                                                ))
                                                :
                                                null
                                            }


                                        </select>
                                    </div>
                                </div>


                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="field-2" class="control-label">City <font color="red">*</font></label>
                                        <select class="select2 form-control"
                                            name="city_id" id="city_id" value={this.state.city_id} aria-required="true"
                                            onLoad={this.getonloadcity} onChange={this.getcity_id}
                                            data-placeholder="Choose a City..." required>
                                            <option>--Select--</option>
                                            {this.state.citys !== null
                                                ? this.state.citys.map(cit => (
                                                    <option value={cit.city_id} selected={cit.city_id == this.state.city_id}>{cit.city_name}</option>
                                                ))
                                                :
                                                null
                                            }


                                        </select>
                                    </div>
                                </div>


                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="field-2" class="control-label">Pincode <font color="red">*</font></label>
                                        <input type="text" onChange={this.inputChange}
                                            value={this.state.pincode} name="pincode" class="form-control" id="pincode" required=""
                                            aria-required="true" placeholder="pincode" required />
                                    </div>
                                </div>
                            </div>
                            <hr />


                            <h5 class="modal-title mt-0"><u>Other Details</u></h5>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="field-2" class="control-label">Website <font color="red">*</font></label>
                                        <input type="text" onChange={this.inputChange}
                                            value={this.state.website} name="website" class="form-control" id="website" aria-required="true"
                                            placeholder="Website" required />
                                    </div>
                                </div>


                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="field-2" class="control-label">Phone No. <font color="red">*</font></label>
                                        <input type="text" onChange={this.inputChange}
                                            value={this.state.contact_no} id="contact_no" name="contact_no" class="form-control" required=""
                                            aria-required="true" placeholder="Phone No." required />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="field-2" class="control-label">Email <font color="red">*</font></label>
                                        <input type="email" onChange={this.inputChange}
                                            value={this.state.email} name="email" class="form-control" id="email" required="" aria-required="true"
                                            placeholder="Email" required />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <p class="control-label"><b>Company Status</b>
                                            <font color="red">*</font>
                                        </p>
                                        {this.state.company_status == "1" ?
                                            <div class="radio radio-info form-check-inline">
                                                <input type="radio" value={this.state.company_status} value="1"
                                                    onChange={this.inputChange} name="company_status"
                                                    style={{ marginRight: "10px", marginRight: "10px" }} checked />Active

                                  </div>
                                            :
                                            <div class="radio radio-info form-check-inline">
                                                <input type="radio" value={this.state.company_status} value="1"
                                                    onChange={this.inputChange} name="company_status"
                                                    style={{ marginRight: "10px", marginRight: "10px" }} />Active

                                  </div>
                                        }
                                        {this.state.company_status == "0" ?
                                            <div class="radio radio-info form-check-inline">
                                                <input type="radio" id="inactive" value="0"
                                                    onChange={this.inputChange} name="company_status" style={{ marginRight: "10px", marginRight: "10px" }} checked />Inactive

                                  </div>
                                            :
                                            <div class="radio radio-info form-check-inline">
                                                <input type="radio" id="inactive" value="0"
                                                    onChange={this.inputChange} name="company_status" style={{ marginRight: "10px", marginRight: "10px" }} />Inactive

                                  </div>
                                        }
                                    </div>
                                </div>

                            </div>
                            <hr />
                            <h5 class="modal-title mt-0"><u>User Login Details</u></h5>
                            <div class="row">
                                <div class="col-md-4" id="uname">
                                    <div class="form-group">
                                        <label for="field-2" class="control-label">User Name <font color="red">*</font></label>
                                        <input type="text" name="username" onChange={this.inputChange}
                                            value={this.state.username} class="form-control" id="username" required="" aria-required="true"
                                            placeholder="User Name" required />
                                    </div>
                                </div>


                                <div class="col-md-4">
                                    <div class="form-group">
                                        <p class="control-label"><b>Login Status</b>
                                            <font color="red">*</font>
                                        </p>
                                        {this.state.login_status == "1" ?
                                            <div class="radio radio-info form-check-inline">
                                                <input type="radio" value={this.state.login_status} value="1"
                                                    onChange={this.inputChange} name="login_status"
                                                    style={{ marginRight: "10px", marginRight: "10px" }} checked />Active

                                 </div>
                                            :
                                            <div class="radio radio-info form-check-inline">
                                                <input type="radio" value={this.state.login_status} value="1"
                                                    onChange={this.inputChange} name="login_status"
                                                    style={{ marginRight: "10px", marginRight: "10px" }} />Active

                                 </div>

                                        }
                                        {this.state.login_status == "0" ?
                                            <div class="radio radio-info form-check-inline">
                                                <input type="radio" value={this.state.login_status} value="1"
                                                    onChange={this.inputChange} name="login_status"
                                                    style={{ marginRight: "10px", marginRight: "10px" }} checked />Inactive

                                 </div>
                                            :
                                            <div class="radio radio-info form-check-inline">
                                                <input type="radio" value={this.state.login_status} value="0"
                                                    onChange={this.inputChange} name="login_status"
                                                    style={{ marginRight: "10px", marginRight: "10px" }} />Inactive

                                 </div>

                                        }
                                    </div>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-primary">Submit</button>



                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(edit_org);