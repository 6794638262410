import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../../../../../helpers/Constants';
import SuccessAlert from '../../SuccessAlert';
import ErrorAlert from '../../ErrorAlert';

class edit_call_response extends Component {
    constructor(props) {
        super(props)
        const headers = {
            headers: {
            'Content-Type': 'application/json',
            'Authorization':"Bearer "+this.props.auth_user.user_data.original.access_token
            }
            }
       const user_id= this.props.auth_user.user_data.original.user.id;
        console.log(user_id);
        this.state = {
        status:'',
       call_stage:'',
       user_id:this.props.auth_user.user_data.original.user.id,
       Call_Stage_id:this.props.match.params.id,headers:headers

         }
        this.inputChange = this.inputChange.bind(this);
        this.handelFormSubmit = this.handelFormSubmit.bind(this);
    }

    inputChange(e) {
        console.log("input type working");
               this.setState({
          [e.target.name]: e.target.value,
          
        });
    }
    
    handelFormSubmit(event) {
        
         event.preventDefault();
         axios.post(BASE_URL + '/api/call_Stage/addCallStage', {
          call_stage:this.state.call_stage,
          user_id:this.props.auth_user.user_data.original.user.id,
          message: this.state.message,
          status: this.state.status,  
          Call_Stage_id:this.props.match.params.id

            },this.state.headers).then(response => {
                console.log(response.data);
                   this.setState({
                    response_status: response.data.status,
                      response_message: response.data.message
                })
                if(response.data.status==true)
                {
                    
                  this.props.history.push({pathname :'/call-stage', state :{msg:response.data.message,status:response.data.status}});
                  
                }
               }).catch(err => console.log(err));
          }

          componentDidMount() {
            const id = this.props.match.params.id
            console.log(id);
            console.log('edit page working');
             axios.get(BASE_URL + `/api/call_Stage/fetchCallStage/${id}`,this.state.headers)
                .then(response => {
                    this.setState({
                        call_stage: response.data.call_stage,
                        
                    })
                }).catch(err => console.log(err));
    
            }
            /* componentWillMount() {
            //     if (this.props.location.state.status && this.props.location.state.msg) {
            //         this.message = this.props.location.state.msg;
            //         this.props.history.replace({
            //             pathname: this.props.location.pathname,
            //             state: {}
            //         });
            //     }
            // }*/

    render() {
        return (
            <div>
    
                
            {this.state.response_status == false ?
            <ErrorAlert message= {this.state.response_message} />:null}

              

                <div class="card">
                    
                        <div class="card-content">
                            <div class="card-header">
                                <h5 class="card-title" id="examplecardLabel"> Call Stage :Edit <a class="btn btn-primary pull-right" href="/call-stage">Back</a></h5>
                             
                            </div>
                            <div class="card-body">
                                <form  onSubmit={this.handelFormSubmit} >
                               
                                <input type="hidden" name="Call_Stage_id" class="form-control" id="exampleInputEmail1"  value={this.state.Call_Stage_id}  />
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Call Stage</label>
                                        <input type="text" name="call_stage" onChange={this.inputChange}
                                        value={this.state.call_stage} class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Call Stage" />
                                    </div>

                                    <div class="card-footer">
                                    {/*{this.state.response_status == true ?
                                        <SuccessAlert message= {this.state.response_message} /> : null  }
                                        
                                    {this.state.response_status == false ?
                                    <ErrorAlert message= {this.state.response_message} />:null}*/}
                                       
                                        <button type="submit" class="btn btn-primary">Update</button>
                                    </div>
                                </form>
                            </div>

                        </div>
                    
                </div>



            </div>
        );
    }
}

export default withRouter (edit_call_response);