/* eslint-disable*/
import React, { Component } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../../helpers/Constants';

class Add_attachment extends Component {
    constructor(props) {
        super(props)
        this.state = {
            title: '', attachment: '', created_at: '',cust_id:'',
        }
        this.inputChange = this.inputChange.bind(this);
        this.handelFormSubmit = this.handelFormSubmit.bind(this);
    }
    inputChange(e) {
        console.log("input type working");
        this.setState({
            [e.target.name]: e.target.value
        });

    }
    handelFormSubmit(event) {
        event.preventDefault();
        console.log(this.props.cust_id);
        axios.post(BASE_URL+'/api/attachments', {
            title: this.state.title, attachment: this.state.attachment, created_at: this.state.created_at,
            cust_id: this.props.cust_id,
        }).then(response => {
            this.setState({
                title: '', attachment: '', created_at: '',cust_id: '',
            })
            
            const cust_id=this.props.cust_id;
            this.props.history.push(`/leads/${cust_id}/view`);
        }).catch(err => console.log(err));


    }

    render() {
        return (
            <div>
                <div className="container">

                    <div className="modal" id="attachment">
                        <div className="modal-dialog">
                            <div className="modal-content">


                                <div className="modal-header" style={{backgroundColor:"#abc7e4", color: "black" }}>
                                    <h4 className="modal-title">Attachments / Files</h4>
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>


                                <div className="modal-body">
                                    <form onSubmit={this.handelFormSubmit}>
                                    <div className="form-group">
                     
                                    <input type="text"
                                      className="form-control" id="email" 
                                      name="cust_id"
                                      value={this.props.cust_id}
                                      onChange={this.inputChange} />
                                  </div>

                                        <div className="form-group">
                                            <label>Title<span style={{ color: "red" }}>*</span></label>
                                            <input type="text"
                                                value={this.state.title}
                                                onChange={this.inputChange} name="title" className="form-control" id="email" placeholder="Title" />
                                        </div>
                                        <div className="form-group">
                                            <label>Files<span style={{ color: "red" }}>*</span></label><br />
                                            <input type="file" value={this.state.attachment}
                                                 name="attachment" className="form-control" onChange={this.inputChange} encType="multipart" />

                                        </div>
                                        <div className="form-group">
                                            <label>Create Date<span style={{ color: "red" }}>*</span></label>
                                            <input type="date" value={this.state.created_at}
                                                onChange={this.inputChange} name="created_at" className="form-control" id="email" />
                                        </div>

                                        <div className="modal-footer">
                                            <button type="submit" className="btn btn-primary">Submit</button>
                                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                                        </div>


                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default Add_attachment;