/* eslint-disable*/
import React, { Component } from 'react';
import { withRouter,Link  } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../../../helpers/Constants';
import SuccessAlert from './SuccessAlert';
import ErrorAlert from './ErrorAlert';
class add_org extends Component {
    constructor(props) {
        super(props)
        const user_id= this.props;
        console.log(user_id);
        this.state = {
          country_id:'',
          countrys:[],
          city_id:'',
          state_id:'',
         orgs: [],org_name:'',address:'',photo:'',citys:[],states:[],pincode:'',
         website:'',contact_no:'',email:'',status:'',username:'',password:'',login_status:'',org_code:'',

            
        }
        this.inputChange = this.inputChange.bind(this);
        this.handelFormSubmit = this.handelFormSubmit.bind(this);
        this. getnewstate= this.getnewstate.bind(this);
        this.getnewcity= this.getnewcity.bind(this);
        this.getcity_id = this.getcity_id.bind(this);
    }
    inputChange(e) {
        console.log("input type working");
       
        this.setState({
          [e.target.name]: e.target.value,
         
      
         
        });
        console.log("country" + this.setState.country);
       
      }
      
      handelFormSubmit(event) {
        const headers = {
          headers: {
          'Content-Type': 'application/json',
          'Authorization':"Bearer "+this.props.auth_user.user_data.original.access_token
          }
          }
     console.log(this.state.user_id)
      event.preventDefault();
         axios.post(BASE_URL + '/api/org/store', {
             org_code:this.state.org_code,
             created_by: this.props.auth_user.id,
             org_name:this.state.org_name,
             address:this.state.address,
             photo:this.state.photo,
             country_id:this.state.country_id,
             city_id:this.state.city_id,
             state_id:this.state.state_id,
             pincode:this.state.pincode,
             website:this.state.website,
             contact_no:this.state.contact_no,
             email:this.state.email,
             status:this.state.status,
             username:this.state.username,
             password:this.state.password,
             login_status:this.state.login_status,
             message: this.state.message,
             status: this.state.status,
           
         },headers).then(response => {
          console.log(response.data);
             this.setState({
          
              response_status: response.data.status,
                response_message: response.data.message
          })
          if(response.data.status==true)
          {
            this.props.history.push({pathname :'/Organization', state :{msg:response.data.message,status:response.data.status}});
            
          }
         }).catch(err => console.log(err));
 
     }
     

    
        componentDidMount() {
          const headers = {
            headers: {
            'Content-Type': 'application/json',
            'Authorization':"Bearer "+this.props.auth_user.user_data.original.access_token
            }
            }
        axios.get(BASE_URL + '/api/list_countries',headers)
            .then(response => {
                this.setState({
                    countrys: response.data,
                    
                })
            }).catch(err => console.log(err));

        }

        getnewstate(e){
          const headers = {
            headers: {
            'Content-Type': 'application/json',
            'Authorization':"Bearer "+this.props.auth_user.user_data.original.access_token
            }
            }
          this.setState({
            country_id:e.target.value
                       
        })
          const country_id= e.target.value;
          console.log(country_id);
          axios.get(BASE_URL + `/api/list_state/${country_id}`,headers)
            .then(response => {
                this.setState({
                    states: response.data,
                    
                   
                })
            }).catch(err => console.log(err));
           
        }
        getnewcity(e){
          const headers = {
            headers: {
            'Content-Type': 'application/json',
            'Authorization':"Bearer "+this.props.auth_user.user_data.original.access_token
            }
            }
          this.setState({
            state_id:e.target.value,
          
                       
        })
          const state_id =e.target.value;
          console.log("state" + state_id);
          axios.get(BASE_URL +`/api/list_cities/${state_id}`,headers)
          .then(response => {
                this.setState({
                  citys: response.data,
                  
              })
            }).catch(err => console.log(err));
          
       }
       getcity_id(e){
        this.setState({
       
          city_id:e.target.value
                     
      })
       }
      
        
    render() {
      console.log(this.state.props)
      // const country_id=this.state.country_id;
      // console.log("id of"+country_id);
      // const city_id =this.state.city_id;

     
     
        return (
            <div>
            {this.state.response_status == true ?
              <SuccessAlert message= {this.state.response_message} /> : null}
          {this.state.response_status == false ?
              <ErrorAlert message= {this.state.response_message} />:null}
              <div class="row">
                <div class="col-sm-12" id="breadcrum" style={{marginTop:"-23px",marginBottom:"0px",borderBottom:"1px dashed #b9b9b9 "}}>
                    <p class="pull-left" style={{fontSize:"25px", color:"#000",marginTop:"14px"}}><i class="mdi mdi-home-modern" aria-hidden="true"></i>&nbsp;&nbsp;Add Organization </p>
                    <ol class="breadcrumb pull-right">
                        
                    <li><Link to={"/Organization"} style={{ color: "#007bff", fontSize: "15px", scrollMarginBottom: "10px" }} className="pull-right active" > Organization Info</Link></li>

                    <li><Link to={"/Organization"} style={{ color: "#007bff", fontSize: "15px", scrollMarginBottom: "10px" }} className="pull-right active" >Add Organization</Link></li>
                    <li><Link to={"/"} style={{ fontSize: "15px", scrollMarginBottom: "10px" }} className="pull-right active" >Home</Link></li>
                    </ol>
            </div>
            </div> 
                <div class="card">
                   
                    <div class="card-body">
                        <form  onSubmit={this.handelFormSubmit}>
                            <div class="row">

                                <div class="col-md-12">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-4 control-label">Name of the company</label>
                                        <div class="col-sm-7">
                                            <input required type="text" class="form-control" name="org_name" onChange={this.inputChange}
                                            value={this.state.org_name} id="org_name" required="" placeholder="Name of the company" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="inputEmail3" class="col-sm-4 control-label">Company Code</label>
                                    <div class="col-sm-7">
                                        <input required type="text" class="form-control" name="org_code" onChange={this.inputChange}
                                        value={this.state.org_code} id="org_code" required="" placeholder="Short name of the company" />
                                    </div>
                                </div>
                            </div>

                                <div class="col-md-12">
                                    <div class="form-group row">
                                        <label class="col-md-4 control-label" for="example-textarea-input">Company Address</label>
                                        <div class="col-md-7">
                                            <textarea class="form-control" rows="2" required="" name="address" onChange={this.inputChange}
                                            value={this.state.address} id="address" ></textarea>
                                        </div>
                                    </div>
                                </div>
                               {/* <div class="col-md-3" style={{marginTop:"-8em"}}>
                                    <div class="container">
                                        <div class="picture-container">
                                            <div class="picture">
                                                <img src="login2/img/user.jpg" class="picture-src" style={{ width: "100px" }}
                                                    id="e_logo" title="" />
                                                <input required type="file" name="photo" id="wizard-picture" onChange={this.inputChange}
                                                value={this.state.photo} class="" />
                                                <input required type="hidden" id="logo" name="new_logo" />
                                            </div>
                                            <h6 class="">Choose Picture</h6>

                                        </div>
                                    </div>

          </div>*/}

                            </div>
                            <div class="row">

                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="field-2" class="control-label">Country <font color="red">*</font></label>
                                        <select class="select2 form-control" onChange={this.getnewstate} 
                                       name="country_id" required="" aria-required="true" 
                                            data-placeholder="Choose a Country...">
                                            <option value="">--Select--</option>
                                            {this.state.countrys !== null
                                                ? this.state.countrys.map(country => (
                                                
                                                    <option key={country.country_id} value={country.country_id}>{country.country_name}</option>
                                                ))
                                                :
                                                null
                                            }

                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                <div class="form-group">
                                    <label for="field-2" class="control-label">State <font color="red">*</font></label>
                                    <select class="select2 form-control"
                                   name="state_id" required="" aria-required="true" value={this.props.state_id}onChange={this.getnewcity}
                                         data-placeholder="Choose a State...">
                                         <option value=" " >--Select--</option>
                                         {this.state.states !== null
                                             ? this.state.states.map(state => (
                                                 <option value={state.state_id}>{state.state_name}</option>
                                             ))
                                             :
                                             null
                                         }


                                    </select>
                                </div>
                            </div>


                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="field-2" class="control-label">City <font color="red">*</font></label>
                                    <select class="select2 form-control" 
                                   name="city_id" id="city_id" aria-required="true" onChange={this.getcity_id}
                                            data-placeholder="Choose a City...">
                                            <option>--Select--</option>
                                            {this.state.citys !== null
                                                ? this.state.citys.map(cit => (
                                                    <option value={cit.city_id}>{cit.city_name}</option>
                                                ))
                                                :
                                                null
                                            }


                                        </select>
                                    </div>
                                </div>

                            

                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="field-2" class="control-label">Pincode <font color="red">*</font></label>
                                        <input required type="text" onChange={this.inputChange}
                                        value={this.state.pincode} name="pincode" class="form-control" id="pincode" required=""
                                            aria-required="true" placeholder="pincode" />
                                    </div>
                                </div>
                            </div>
                            <hr />


                            <h5 class="modal-title mt-0"><u>Other Details</u></h5>
                            <div class="row">
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label for="field-2" class="control-label">Website <font color="red">*</font></label>
                                  <input required type="text"  onChange={this.inputChange}
                                  value={this.state.website}  name="website" class="form-control" id="website" aria-required="true"
                                  placeholder="Website"/>
                                </div>
                              </div>
                      
                      
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label for="field-2" class="control-label">Phone No. <font color="red">*</font></label>
                                  <input required type="text"  onChange={this.inputChange}
                                  value={this.state.contact_no}  id="contact_no" name="contact_no" class="form-control"  required=""
                                  aria-required="true" placeholder="Phone No."/>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label for="field-2" class="control-label">Email <font color="red">*</font></label>
                                  <input required type="email" onChange={this.inputChange}
                                  value={this.state.email}  name="email" class="form-control" id="email" required="" aria-required="true"
                                  placeholder="Email"/>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <p class="control-label"><b>Company Status</b>
                                    <font color="red">*</font>
                                  </p>
                                  <div class="radio radio-info form-check-inline">
                                  <input required type="radio" value={this.state.status} value="1"
                                 onChange={this.inputChange} name="status"
                                 style={{ marginRight: "10px", marginRight: "10px" }} />Active
                                    
                                  </div>
                                  <div class="radio radio-info form-check-inline">
                                    <input required type="radio" id="inactive" value="0" 
                                    onChange={this.inputChange} name="status" style={{ marginRight: "10px", marginRight: "10px" }} />Inactive
                                   
                                  </div>
                                </div>
                              </div>
                      
                            </div>
                           <hr/>
                           <h5 class="modal-title mt-0"><u>User Login Details</u></h5>
                           <div class="row">
                             <div class="col-md-4" id="uname">
                               <div class="form-group">
                                 <label for="field-2" class="control-label">User Name <font color="red">*</font></label>
                                 <input required type="text" name="username" onChange={this.inputChange}
                                 value={this.state.username} class="form-control" id="username" required="" aria-required="true"
                                 placeholder="User Name"/>
                               </div>
                             </div>
                     
                             <div class="col-md-4" id="pass">
                               <div class="form-group">
                                 <label for="field-2" class="control-label">Password <font color="red">*</font></label>
                                 <input required type="password" onChange={this.inputChange}
                                 value={this.state.password} name="password" class="form-control" required="" aria-required="true"
                                 placeholder="Password"/>
                               </div>
                             </div>
                     
                             <div class="col-md-4" id="repass">
                               <div class="form-group">
                                 <label for="field-2" class="control-label">Confirm Password <font color="red">*</font></label>
                                 <input required type="password" onChange={this.inputChange}
                                 value={this.state.confirm_password} name="confirm_password" class="form-control" required="" aria-required="true"
                                 placeholder="Confirm Password"/>
                               </div>
                             </div>
                             <div class="col-md-4">
                               <div class="form-group">
                                 <p class="control-label"><b>Login Status</b>
                                   <font color="red">*</font>
                                 </p>
                                 <div class="radio radio-info form-check-inline">
                                 <input required type="radio" value={this.state.login_status} value="1"
                                        onChange={this.inputChange} name="login_status"
                                        style={{ marginRight: "10px", marginRight: "10px" }} />Active
                                   
                                 </div>
                                 <div class="radio radio-info form-check-inline">
                                 <input required type="radio" value={this.state.login_status} value="0"
                                 onChange={this.inputChange} name="login_status"
                                 style={{ marginRight: "10px", marginRight: "10px" }} />Inactive
                                   
                                 </div>
                               </div>
                             </div>
                           </div>
                           <button type="submit" class="btn btn-primary">Submit</button>
                           


                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(add_org);