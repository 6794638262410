import { FORGOT_PASSWORD_EMAIL, FORGOT_PASSWORD_EMAIL_SUCCESS, FORGOT_PASSWORD_EMAIL_API_ERROR } from './actionTypes';

const initialState = {
    error: null,
    loading: false
}

const forgotPasswordEmailReducer = (state = initialState, action) => {
    switch (action.type) {
        case FORGOT_PASSWORD_EMAIL:
            state = {
                loading: true,
                error: null
            };
            break;
        case FORGOT_PASSWORD_EMAIL_SUCCESS:
            state = {
                loading: false,
                error: null
            };
            break;
        case FORGOT_PASSWORD_EMAIL_API_ERROR:
            state = {
                error: action.payload,
                loading: false
            };
            break;
        default:
            state = {
                ...state
            };
            break;
    }
    return state;
}

export default forgotPasswordEmailReducer;