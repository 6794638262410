
/* eslint-disable*/
import React, { Component } from 'react'

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import { Link, withRouter } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button'
import ModalHeader from 'react-bootstrap/ModalHeader'
import ModalTitle from 'react-bootstrap/ModalTitle'
import ModalFooter from 'react-bootstrap/ModalFooter'
import Modal from 'react-bootstrap/Modal'
import ModalExample from './ModalExample';
import { FaBeer, FaEye, FaEdit } from 'react-icons/fa';
import Note_modal from './Note_modal';
import Attachment_modal from './Attachment_modal';
import New_leadtask from './New_leadtask';
import New_leadEvent from './New_leadEvent';
import Lead_email from './Lead_email';
import Add_leadReminder from './Add_leadReminder';
import Add_Notes from './Add_Notes';
import Add_attachment from './Add_attachment';
import axios from 'axios';
import SuccessAlert from './SuccessAlert';
import { BASE_URL } from '../../../helpers/Constants';
import './page.css';


class View_customers extends Component {


  constructor(props) {
    super(props)
    const headers = {
      headers: {
      'Content-Type': 'application/json',
      'Authorization':"Bearer "+this.props.auth_user.user_data.original.access_token
      }
      }
    this.state = {

      call: true,
      task: true,
      event: true,
      email: true,
      reminder: true,
      callsdata: [],
      tasksdata: [],
      eventdata: [],
      emaildata: [],
      reminderdata: [],
      attachmentdata: [],
      notedata: [],

      id: '',
      first_name: '', last_name: '', email: '', mobile: '', lead_status: '', phone: '', website: '',
      company_name: '', n_o_employee: '', fax: '', lead_status: '', lead_owner: '', annual_revenue: '', rating: '', lead_source: '',
      industry: '', linkedIn_id: '', linkedIn_url: '', address1: '', city_distt: '', head_quater: '', apperance_in_country: '',
      state_province: '', pincode: '', country: '', description: '',headers:headers,
      // leads: []


    }
  }

  componentDidMount() {
    const id = this.props.match.params.id
    console.log(id);





    console.log('callled  view leads ');

    axios.get(BASE_URL + `/api/leads/${id}`,this.state.headers)
      .then(response => {
        this.setState({
          id: response.data.id,
          first_name: response.data.first_name,
          last_name: response.data.last_name,
          email: response.data.email,
          mobile: response.data.mobile,
          lead_status: response.data.lead_status,
          phone: response.data.phone,
          website: response.data.website,
          company_name: response.data.company_name,
          n_o_employee: response.data.n_o_employee,
          fax: response.data.fax,
          lead_status: response.data.lead_status,
          lead_owner: response.data.lead_owner,
          annual_revenue: response.data.annual_revenue,
          rating: response.data.rating,
          lead_source: response.data.lead_source,
          industry: response.data.industry,
          linkedIn_id: response.data.linkedIn_id,
          linkedIn_url: response.data.linkedIn_url,
          address1: response.data.address1,
          city_distt: response.data.city_distt,
          head_quater: response.data.head_quater,
          apperance_in_country: response.data.apperance_in_country,
          state_province: response.data.state_province,
          pincode: response.data.pincode,
          country: response.data.country,
          description: response.data.description,
          leads: response.data

        })
      }).catch(err => console.log(err));

    axios.get(BASE_URL + `/api/calls/${id}`,this.state.headers)
      .then(response => {
        this.setState({
          callsdata: response.data
        })
      }).catch(err => console.log(err));

    axios.get(BASE_URL + `/api/tasks/${id}`,this.state.headers)
      .then(response => {
        this.setState({
          tasksdata: response.data
        })
      }).catch(err => console.log(err));

    axios.get(BASE_URL + `/api/events/${id}`,this.state.headers)
      .then(response => {
        this.setState({
          eventdata: response.data
        })
      }).catch(err => console.log(err));

    axios.get(BASE_URL + `/api/email/${id}`,this.state.headers)
      .then(response => {
        this.setState({
          emaildata: response.data
        })
      }).catch(err => console.log(err));

    axios.get(BASE_URL + `/api/reminders/${id}`,this.state.headers)
      .then(response => {
        this.setState({
          reminderdata: response.data
        })
      }).catch(err => console.log(err));

    axios.get(BASE_URL + `/api/attachments/${id}`,this.state.headers)
      .then(response => {
        this.setState({
          attachmentdata: response.data
        })
      }).catch(err => console.log(err));

    axios.get(BASE_URL + `/api/notes/${id}`,this.state.headers)
      .then(response => {
        this.setState({
          notedata: response.data
        })
      }).catch(err => console.log(err));

  }

  render() {

    return (
      <div>
        {this.state.alert_message == "success" ? <SuccessAlert message={"Lead data Updated Successfully"} /> : null}
   
   
        <Card>
        <Card.Header style={{borderBottom:"0px solid rgba(0,0,0,.125)"}}>
            <Card.Subtitle className="text-muted"><Row>
            <Col md={10}> <h5 style={{marginBottom:"0px",marginTop:"0px"}}><strong>Customer : Information</strong></h5></Col>
            <Col md={2}> <Link to="/customers" style={{ backgroundColor: '#317eeb', color: 'white',marginTop:"-1em" }} className="btn btn-primary pull-right">Back</Link></Col>
           
            </Row></Card.Subtitle>
            </Card.Header>
         
          <Card.Body>
          <Row>
          <Table  style={{marginTop:"-1em"}}>

            <tbody>

    

                <td style={{fontSize:"18px",fontWeight:"500",color:"#317eeb"}}>{this.state.first_name} {" "} {this.state.last_name}</td>
               
                <td style={{fontSize:"16px"}}> <i className=" mdi mdi-email" ></i>&nbsp;&nbsp;{this.state.email} _ _ _ _ _ _ _ </td>
                <td style={{fontSize:"16px"}}> <i className=" mdi mdi-cellphone">&nbsp;&nbsp;</i>{this.state.mobile}</td>
                <td style={{fontSize:"16px"}} >   Status:<span style={{color:"#317eeb"}}>{" "} {this.state.lead_status}</span></td>
                <td>  <Link to={`/customers/${this.state.id}/edit`}><span style={{ color: "#317eeb" }}><FaEdit /></span></Link></td>
              
       
              
            </tbody>

            </Table>
            <hr className="new1"/>
            <Card.Subtitle className="mb-2 text-muted"><h5> <i className=" mdi mdi-account-multiple-outline" style={{fontSize:"30px"}}></i>&nbsp;Customer Details</h5>
            </Card.Subtitle>
            <Table striped bordered hover size="sm" style={{marginTop:"-1em"}}>

            <tbody>

              <tr>

                <th>Name : {this.state.first_name}</th>
                <th>Phone : {this.state.phone}  </th>

              </tr>
              <tr>
                <th>Email : {this.state.email}</th>
                <th>Mobile : {this.state.mobile}</th>
              </tr>
              <tr>
                <th>Website :{this.state.website}</th>
                <th>Company : {this.state.company_name}  </th>
              </tr>
              <tr>
                <th>Fax :{this.state.fax}</th>
                <th>No. Of Employees :{this.state.n_o_employee} </th>
              </tr>
              <tr>
                <th>Lead Owner :{this.state.lead_owner}</th>
                <th>Lead Status : {this.state.lead_status}  </th>
              </tr>
              <tr>
                <th>Rating : {this.state.rating} </th>
                <th>Annual Revenue :{this.state.annual_revenue} </th>
              </tr>
              <tr>
                <th>Lead Source :{this.state.lead_source}</th>
                <th>Industry: {this.state.industry} </th>
              </tr>
              <tr>
                <th>LinkedIn Id :{this.state.linkedIn_id} </th>
                <th>LinkedIn Url :{this.state.linkedIn_url} </th>

              </tr>


            </tbody>

          </Table>
          <Card.Subtitle className="mb-2 text-muted"> <h5> <i className=" mdi mdi-home-map-marker" style={{fontSize:"30px"}}></i>&nbsp;Address Details</h5>
          </Card.Subtitle><br />
          <Table striped bordered hover size="sm" style={{marginTop:"-1em"}} >

          <tbody>
            <tr>
              <th style={{width:"48%"}}>Address : {this.state.address1}</th>
              <th>City : {this.state.city_distt} </th>

            </tr>
            <tr>
              <th>Head Quater :{this.state.head_quater}</th>
              <th>Apperance in country :{this.state.apperance_in_country}  </th>
            </tr>
            <tr>
              <th>State :{this.state.state_province}</th>
              <th>Zip/Postal Code : {this.state.pincode}  </th>
            </tr>
            <tr>
              <th>Country :{this.state.country}</th>
              <th>Description :{this.state.description}   </th>
            </tr>


          </tbody>
        </Table>
            
          </Row>
          </Card.Body>
        </Card>
        <br />
       




      </div>
    )
  }
}

export default withRouter(View_customers);
