import React from 'react';
import { connect } from 'react-redux';//link krta hai store ko 
import { Switch, BrowserRouter, Route } from "react-router-dom";//routing 
import { authProtectedRoutes, publicRoutes } from "./routes";//access
import BabaSalesRoute from "./routes/route";
import { BASE_URL } from "./helpers/Constants";
import Error404 from './components/shared/404';
import Error500 from './components/shared/500';

const ScrollToTop = () => {
	window.scrollTo(0, 0);
	return null;
};

class BabaSales extends React.PureComponent {
    render() {
        return (
            <BrowserRouter>
                <Route component={ScrollToTop} />
                <Switch>
                    { publicRoutes.map((route, idx) => (
                        <BabaSalesRoute
                            base_url={BASE_URL}
                            props={this.props}
                            path={route.path}
                            component={route.component}
                            exact={route.exact}
                            key={idx}
                            isAuthProtected={false}
                        />
                    ))}
                    { authProtectedRoutes.map((route, idx) => (
                        <BabaSalesRoute
                            base_url={BASE_URL}
                            props={this.props}
                            path={route.path}
                            component={route.component}
                            exact={route.exact}
                            key={idx}
                            isAuthProtected={true}
                        />
                    ))}
                    <Route path="/500" component={Error500} />
                    <Route path="*" component={Error404} />
                </Switch>
            </BrowserRouter>
        );
    }
};

const mapStateToProps = state => {
    return {
        auth_user: state.authReducer.auth_user,
        authToken: (state.authReducer.auth_user) ? state.authReducer.auth_user.token : null,
        apiUrl: (state.authReducer.auth_user) ? state.authReducer.auth_user.role_name : null
    }
}

export default connect(mapStateToProps, null)(BabaSales);