import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../../../helpers/Constants';
import SuccessAlert from '../admin/SuccessAlert';
import ErrorAlert from '../admin/ErrorAlert';
import Pagination from "react-js-pagination";

class View_schedule_reminder extends Component {
    constructor(props) {
        super(props)
        const headers = {
            headers: {
            'Content-Type': 'application/json',
            'Authorization':"Bearer "+this.props.auth_user.user_data.original.access_token
            }
            }
        const user_id= this.props.auth_user.user_data.original.user.id;
        console.log(user_id);
        this.state = {
        activities:[],
         response_name:'',
         activePage: 1,
         itemsCountPerPage: 1,
         totalItemsCount: 1,
         pageRangeDisplayed: 3,
        user_id:this.props.auth_user.user_data.original.user.id,
        headers:headers
       

         }
         this.Onload = this.Onload.bind(this);
      
    }
    handlePageChange(pageNumber) {
        console.log(`active page is ${pageNumber}`);
        this.setState({ activePage: pageNumber });

        axios.get(BASE_URL + '/api/leads/FetechLeadActivitY?page=' + pageNumber,this.state.headers)
            .then(response => {
                this.setState({
                    activities: response.data.data.data,
                    itemsCountPerPage: response.data.per_page,
                    totalItemsCount: response.data.total,
                    activePage: response.data.current_page
                })
            });
    }
    Onload(){
        console.log('fetch reminder is working');
        const lead_id = this.props.match.params.id
        axios.post(BASE_URL + '/api/leads/FetechLeadActivity',
         {'lead_id': this.props.match.params.id},this.state.headers).then(response => {
                this.setState({
                    activities: response.data.data.data,
                    itemsCountPerPage: response.data.per_page,
                    totalItemsCount: response.data.total,
                    activePage: response.data.current_page

                    
                })
            }).catch(err => console.log(err));

    }
  
    
          componentDidMount() {

          this.Onload();
            }
            
  

    render() {
        return (
          
            <div>
            {/*{this.props.location.state!=undefined ?
                [(this.props.location.state.status == true
                 ? <SuccessAlert message= {this.props.location.state.msg} /> 
                 : <p> else </p>
               ),
               <p>working</p>
            ]
                     
                 
                :
             //    <p> not working</p>
             null
             }*/}
             <div class="row">
             <div class="col-sm-12" id="breadcrum" style={{marginTop:"-23px",marginBottom:"0px",borderBottom:"1px dashed #b9b9b9 "}}>
            <h4 class="pull-left page-title"><i class="mdi mdi-chart-histogram" aria-hidden="true"></i>&nbsp;&nbsp;   Schedule : View </h4>
            
                   <Link to={`/telecalling`} class="btn btn-primary pull-right"   style={{marginTop:"18px"}} >Back</Link>
                  
                  
            </div>
            </div> 
                  <div class="card" style={{
                        padding: "1em", borderTop:
                        "2px solid #3366c6"
                             }}>
                    <table style={{ width: "100%" }} id="example" class="table table-hover table-striped table-bordered">
                        <thead>
                            <tr>
                                <th>Sl no.</th>
                                <th>Lead Name</th>
                                <th>Schedule Date</th>
                                <th>Schedule Time</th>
                                <th>Reminder Date & Time</th>
                                <th>Comment</th>
                               
                                
                            </tr>
                        </thead>
                        <tbody onLoad={this.Onload}>
                           
                            {this.state.activities !== null
                                ? this.state.activities.map((activity,index) => (
            
                                  <tr key={activity.id}>
                                    <td>{ ++index}</td>
                                    <td>{activity.activity_name}</td>
                                    <td>{activity.activity_type}</td>
                                    <td>{activity.call_stage_id}</td>
                                    <td>{activity.response_id}</td>
                                    <td>{activity.comment}</td>
                                   
                                      {/*  <div className="row">
                                        
                                        <div className="col-md-2">
                                        <Link to="" style={{ color: "red", fontSize: "15px", scrollMarginBottom: "10px" }}><i className=" mdi mdi-delete"></i></Link>
                                        </div>

                                </div>*/}
                                   
                                   
                                  </tr>
            
                                ))
                                :
                               (<tr>
                                <td colspan="6">No Data Found.</td>
                                 </tr>)
                              }
                              
    
                        </tbody>
                    </table>
                    <div className="pagination justify-content-left">
                    <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.itemsCountPerPage}
                        totalItemsCount={this.state.totalItemsCount}
                        pageRangeDisplayed={this.state.pageRangeDisplayed}
                        onChange={this.handlePageChange}
                        itemClass='page-item'
                        linkClass='page-link'
                        
                    />
                </div>
                </div>



            </div>
        );
    }
}

export default withRouter (View_schedule_reminder);