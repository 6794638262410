import { LOGIN, LOGIN_SUCCESS, LOGOUT, LOGOUT_SUCCESS, AUTH_API_ERROR } from './actionTypes';

const initialState = {
    loading: false,
    error: null,
    auth_user: null
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN:
            state = {
                loading: true,
                error: null,
                auth_user: null
            };
            break;
        case LOGIN_SUCCESS:
            state = {
                loading: false,
                error: null,
                auth_user: action.payload
            };
            break;
        case LOGOUT:
            state = { 
                ...state,
                loading: true,
                error: null
            };
            break;
        case LOGOUT_SUCCESS:
            state = {
                loading: false,
                error: null,
                auth_user: null
            };
            break;
        case AUTH_API_ERROR:
            state = { 
                ...state,
                error: action.payload,
                loading: false
            };
            break;
        default:
            state = {
                ...state
            };
            break;
    }
    return state;
}

export default authReducer;